import { passwordApi } from './password.api'

export const forgot = async (email: any): Promise<any> => {
	return passwordApi.post(email).catch(async (e) => {
		return Promise.reject(e)
	})
}

export const passwordService = {
	forgot
}
