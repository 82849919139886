import { setAxiosAuth } from '../../../modules/http/api'
import { SessionData } from '../../../modules/login/Session'
import { User } from '../../../modules/login/User'
import { ACCESS_MANAGER_PLATFORM_TAG } from '../../../enums'
import { sessionUser } from '../../../modules/login/sessionSlice'
import writeOfficeLocalStorage from '../../../shared/functions/writeOfficeInLocalStorage'
import { SetDataInTheSessionLocalStorage, GetPlatformsAndOffices, SetOfficeInLocalStorage } from './interfaces'
import LocalStorageManager from '../../../helpers/localStorage'
import { Platform } from '../../../modules/login/Platform'

export const setDataInTheSessionLocalStorage: SetDataInTheSessionLocalStorage = (params) => {
	const userStore = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
	const userSession = {
		...userStore,
		user: {
			...userStore?.user,
			access: userStore?.user?.access?.map((item, index) => {
				return {
					...item,
					default_platform_id: item?.platforms?.some(p => p.id === params.defaultPlatformId) ? params.defaultPlatformId : undefined,
					default_office_id: item?.platforms?.some(p => p.offices.some(o => o.id === params.defaultOfficeId)) ? params.defaultOfficeId : undefined
				}
			})
		}
	}
	params.dispatch(sessionUser(userSession))
	LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', userSession)
	setAxiosAuth(userSession.token)
}

export const getPlatformsAndOffices: GetPlatformsAndOffices = (userSession) => {
	const user: User | null = userSession?.user ?? null
	const filteredPlatforms: Platform[] = []
	user?.allPlatforms?.filter(platform => platform.tag === ACCESS_MANAGER_PLATFORM_TAG).map(platform => {
		if (!filteredPlatforms.find(p => p.id === platform.id)) {
			filteredPlatforms.push(platform)
		}
	})

	const getPlatformsOrOffice = filteredPlatforms?.length
		? filteredPlatforms.filter((platform) => !platform.access_only_to_offices).map((platform) => (
			{
				id: platform.id,
				name: platform.description.split('Cockpit')[1] ? platform.description.split('Cockpit')[1].trim() : platform.description,
				isOffice: false,
				checked: false
			}
		)) : []

	filteredPlatforms?.filter((platformExistsOffices) => (platformExistsOffices.offices.length > 0))
		.forEach((platform) =>
			getPlatformsOrOffice.push(
				...platform.offices.map((office) => (
					{
						id: office.id,
						platformId: platform.id,
						name: office.name,
						isOffice: true,
						checked: false
					}
				))
			)
		)

	return getPlatformsOrOffice
}

export const setOfficeInLocalStorage: SetOfficeInLocalStorage = (userSession, platformOrOfficeChecked) => {
	const user: User | null = userSession?.user ?? null
	const filteredPlatforms = user?.allPlatforms?.filter(platform => platform.tag === ACCESS_MANAGER_PLATFORM_TAG)

	filteredPlatforms?.find(platform => platform.id === platformOrOfficeChecked?.platformId)?.offices.find(
		office => {
			if (office.id === platformOrOfficeChecked.id) {
				writeOfficeLocalStorage({
					platformId: platformOrOfficeChecked.platformId,
					internal: office.internal,
					id: office.id,
					name: office.name,
					address: office.address
				})
			}
		}
	)
}
