import { createBrowserHistory } from 'history'
import React, { ReactElement } from 'react'
import MicroFrontend from '../../components/Microfront/Microfront'
import { SessionData } from '../../modules/login/Session'
import { useSelector } from 'react-redux'
import { selectUserSession } from '../../modules/login/sessionSlice'
import ENV from '../../modules/env'

const defaultHistory = createBrowserHistory()
const Financial: React.FC<any> = ({ history }): ReactElement => {
  const userSession: SessionData | null = useSelector(selectUserSession)
  return (
    <MicroFrontend
      name={'Financial'}
      history={history || defaultHistory}
      host={ENV.REACT_APP_FINANCIAL_HOST}
      user={userSession}
    ></MicroFrontend>
  )
}

export default Financial
