import { Button, Input } from '@cockpit/zera'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Icons from '../../assets/icons'
import { Container, Title, InputBox, InputField, PasswordChecks, RoundedCheck, SubTitle, PasswordViewer } from './styles'
import { UpdateUser } from '../../modules/updateUser/updateUser.service'
import { useSelector } from 'react-redux'
import {
  selectUserSession
} from '../../modules/login/sessionSlice'

interface Inputs {
  currentPassword: string;
  password: string
  confirmPassword: string
}

export const UserUpdatePasswordContainer: React.FC = () => {
  const sessionData = useSelector(selectUserSession)
  const { register, handleSubmit, watch } = useForm<Inputs>({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [minLengthValidation, setMinLengthValidation] = useState<boolean>(false)
  const [upperCaseValidation, setUpperCaseValidation] = useState<boolean>(false)
  const [lowerCaseValidation, setLowerCaseValidation] = useState<boolean>(false)
  const [numberValidation, setNumberValidation] = useState<boolean>(false)
  const [specialCharacterValidation, setSpecialCharacterValidation] = useState<boolean>(false)
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState<boolean>(false)
  const [updatePasswordError, setUpdatePasswordError] = useState<boolean>(false)
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [isCurrentPasswordEmpty, setIsCurrentPasswordEmpty] = useState<boolean>(true)

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false)
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState<boolean>(false)

  const watchCurrentPassword = watch('currentPassword')

  useEffect(() => {
    setMinLengthValidation(password.length >= 8)
    setUpperCaseValidation(password.toLocaleLowerCase() !== password)
    setLowerCaseValidation(password.toLocaleUpperCase() !== password)
    setNumberValidation(!!password.match(/\d+/g))
    setSpecialCharacterValidation(!!password.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/))
  }, [password])

  useEffect(() => {
    setConfirmPasswordValidation(password === confirmPassword)
  }, [password, confirmPassword])

  useEffect(() => {
    setIsButtonDisabled(!!(!minLengthValidation || !upperCaseValidation || !lowerCaseValidation || !numberValidation || !specialCharacterValidation || !confirmPasswordValidation || isCurrentPasswordEmpty))
  }, [minLengthValidation, upperCaseValidation, lowerCaseValidation, numberValidation, specialCharacterValidation, confirmPasswordValidation, isCurrentPasswordEmpty])

  useEffect(() => {
    setIsCurrentPasswordEmpty(!(watchCurrentPassword.length >= 8))
  }, [watchCurrentPassword])
  const onSubmit = async (data: Inputs) => {
    try {
      setIsLoading(true)
      const requestData = {
        old_password: data.currentPassword,
        password: password
      }
      sessionData?.user.id && await UpdateUser(sessionData?.user.id, requestData, sessionData.token)
      setTimeout(() => {
        setIsLoading(false)
        setUpdatePasswordError(false)
        setUpdatePasswordSuccess(true)
      }, 800)
    } catch (e: any) {
      setIsLoading(false)
      setUpdatePasswordError(true)
      setUpdatePasswordSuccess(false)
    }
  }
  return (
    <Container>
      <Title>
        <h1>Dados de segurança</h1>
      </Title>
      <SubTitle>
        <h3>ALTERAR SENHA</h3>
      </SubTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox>
          <label htmlFor="currentPassword">SENHA ATUAL</label>
          <InputField>
            <Input
              onPaste={(e: any) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e: any) => {
                e.preventDefault()
                return false
              }}
              {...register('currentPassword', {
                required: true
              })}
              id="currentPassword"
              type={isCurrentPasswordVisible ? 'text' : 'password'}
              margin={'5px 0 10px 0'}
            />
            <PasswordViewer onClick={() => setIsCurrentPasswordVisible(!isCurrentPasswordVisible)}>
              { isCurrentPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
            </PasswordViewer>
          </InputField>
        </InputBox>

        <span>Sua senha deve conter:</span>
        <PasswordChecks>
          <label>
            <RoundedCheck isChecked={minLengthValidation}>
              <Icons.Check />
            </RoundedCheck>
            Pelo menos 8 caracteres
          </label>
          <label>
            <RoundedCheck isChecked={upperCaseValidation}>
              <Icons.Check />
            </RoundedCheck>
            Letra maiúscula (A-Z)
          </label>
          <label>
            <RoundedCheck isChecked={lowerCaseValidation}>
              <Icons.Check />
            </RoundedCheck>
            Letra minúscula (a-z)
          </label>
          <label>
            <RoundedCheck isChecked={numberValidation}>
              <Icons.Check />
            </RoundedCheck>
            Número (0-9)
          </label>
          <label>
            <RoundedCheck isChecked={specialCharacterValidation}>
              <Icons.Check />
            </RoundedCheck>
            Caractere especial (Ex.: @#$&*)
          </label>
        </PasswordChecks>

        <InputBox>
          <label htmlFor="password">NOVA SENHA</label>
          <InputField>
            <Input
              onPaste={(e: any) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e: any) => {
                e.preventDefault()
                return false
              }}
              {...register('password')}
              type={isPasswordVisible ? 'text' : 'password'}
              onChange={(e: any) => setPassword(e.target.value)}
              margin={'5px 0 10px 0'}
              id="password"
            />
            <PasswordViewer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              { isPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
            </PasswordViewer>
          </InputField>
          <label htmlFor="password">CONFIRMAR NOVA SENHA</label>
          <InputField>
            <Input
              onPaste={(e: any) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e: any) => {
                e.preventDefault()
                return false
              }}
              {...register('confirmPassword')}
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              margin={'5px 0 10px 0'}
              id="confirmPassword"
            />
            <PasswordViewer onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}>
              { isConfirmPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
            </PasswordViewer>
          </InputField>
          {!confirmPasswordValidation && (<p className="errorMessage">As senhas digitadas devem ser iguais</p>)}
          {updatePasswordError && (<p className="errorMessage">Erro ao atualizar a senha, tente novamente. Caso persista, contate o suporte.</p>)}
          {updatePasswordSuccess && (<p className="successMessage">Senha atualizada com sucesso!</p>)}
        </InputBox>
        <Button type="submit" isLoading={isLoading} disabled={!!isButtonDisabled}>
          SALVAR
        </Button>
      </form>
    </Container>
  )
}
