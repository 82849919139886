import axios, { AxiosResponse } from 'axios'

import { AxiosHttpClientError } from './axiosClientErrors'

export interface HttpRequest {
	url: string
	method: HttpMethod
	body?: any
	headers?: any
	responseType?: any
	timeout?: number
}

export interface HttpClient {
	request: <ResponseBody>(
		data: HttpRequest
	) => Promise<HttpResponse<ResponseBody> | HttpResponse<HttpResponseError>>
}

export type HttpMethod = 'post' | 'get' | 'put' | 'delete' | 'patch'

export enum HttpStatusCode {
	success = 200,
	successfullyCreated = 201,
	noContent = 204,
	badRequest = 400,
	unauthorized = 401,
	forbidden = 403,
	notFound = 404,
	unprocessableEntity = 422,
	serverError = 500,
	gatewayTimeout = 504
}

export interface HttpResponse<ResponseBody> {
	statusCode: HttpStatusCode
	body?: ResponseBody
}

export interface ErrorData {
	errors: [string, string]
}

export interface HttpResponseError {
	statusCode: HttpStatusCode
	body?: ErrorData
}

export class AxiosHttpClient implements HttpClient {
	async request<ResponseBody>(
		data: HttpRequest
	): Promise<HttpResponse<ResponseBody> | HttpResponse<HttpResponseError>> {
		let axiosResponse: AxiosResponse

		try {
			axiosResponse = await axios.request({
				url: data.url,
				method: data.method,
				data: data.body,
				headers: data.headers,
				responseType: data.responseType,
				timeout: data.timeout ?? undefined
			})
		} catch (error: any) {
			/* eslint-disable no-new */
			new AxiosHttpClientError({ data, e: error })
			axiosResponse = error.response
			if (axiosResponse && !axiosResponse?.status) {
				axiosResponse.status = 500
			}
			if (!axiosResponse) {
				axiosResponse = {
					status: 500,
					data: {
						errors: ['no data returned', data.url]
					},
					error: {
						errors: ['no data returned', data.url]
					}
				} as any
			}
		}
		return {
			statusCode: axiosResponse?.status,
			body: axiosResponse?.data
		}
	}
}
