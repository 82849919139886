import React, { ReactElement, useMemo } from 'react'
import { UserAvatar } from '@cockpit/zera'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Icons from '../../assets/icons'
import { logoutThunk } from '../../modules/login/sessionSlice'
import { User } from '../../modules/login/User'
import { UserSidebarActions, UserSidebarContainer, UserSidebarHeader, IconContainer, UserSidebarAvatar } from './style'
import { useEditUserModal } from '../EditUserModal/context'
import removePrefixDoctor from '../../helpers/removePrefixDoctor'
import { healthProfessionalHasModule } from '../../plugins/access'
import { EModulesIds } from '../../enums/access'
import { ROUTES } from '../../enums/modules'
import { getUserProfileName } from '../EditUserModal/utils'
import { getOfficeRoute, getPlatformRoute } from '../../models/platformRoute'
import ENV from '../../modules/env'
import { ETenantId } from './types'

export const UserSidebar: React.FC<{
  user: User
}> = ({ user }): ReactElement => {
  const dispatch = useDispatch()
  const location = useLocation()
  const clinicalStaffRoute = getPlatformRoute(ENV.CLINICAL_STAFF_PLATFORM_ID)

  const { REACT_APP_SECRETARY_PROFILE_ID: SECRETARY_PROFILE_ID } = process.env
  const { REACT_APP_MANAGER_PROFILE_ID: MANAGER_PROFILE_ID } = process.env
  const { REACT_APP_TENANT_ID: TENANT_ID } = process.env

  const { getOpenEditModal } = useEditUserModal()

  const logout = (): void => {
    dispatch(logoutThunk())
  }

  const getSpeciality = (): string => {
    if (user.health_professional_data?.specialties.length) {
      return user.health_professional_data.specialties[0].description
    }
    return getUserProfileName(user)
  }

  const isSecretaryAccess = (): boolean | undefined => {
    const validate = user.access
      ?.map(access => access.profile?.id)
      .some(profileId => profileId === Number(SECRETARY_PROFILE_ID))

    return validate
  }

  const isManagerAccess = (): boolean | undefined => {
    const validate = user.access
      ?.map(access => access.profile?.id)
      .some(profileId => profileId === Number(MANAGER_PROFILE_ID))

    return validate
  }

  const isClinicalStaff: boolean = useMemo(() => {
    return location.pathname.includes(clinicalStaffRoute)
  }, [clinicalStaffRoute, location])

  const isClinicalStaffOffice: boolean = useMemo(() => {
    return location.pathname.includes(getOfficeRoute)
  }, [clinicalStaffRoute, getOfficeRoute, location])

  const isConfig: boolean = useMemo(() => {
    const hasAccessToConfiguration =
      !!healthProfessionalHasModule(EModulesIds.CONFIGURATION_MODULE_ID) ||
      !!healthProfessionalHasModule(EModulesIds.CALENDAR_CONFIG_MODULE_ID)

    const isClinical = isClinicalStaff || isClinicalStaffOffice
    const isSecretary = isSecretaryAccess() ?? false
    const isManager = isManagerAccess() ?? false

    if (TENANT_ID === ETenantId.CAREPLUS && (isSecretary || isManager) && hasAccessToConfiguration && isClinical) {
      return true
    }

    if (TENANT_ID !== ETenantId.CAREPLUS && hasAccessToConfiguration && isClinical) {
      return true
    }

    return false
  }, [healthProfessionalHasModule, EModulesIds, isClinicalStaffOffice, isClinicalStaff])

  return (
    <UserSidebarContainer>
      <UserSidebarHeader>
        <UserSidebarAvatar>
          <UserAvatar name={removePrefixDoctor(user?.name || '')} />
        </UserSidebarAvatar>
        <h4>{user.name}</h4>
        <span>{getSpeciality()}</span>
      </UserSidebarHeader>

      <UserSidebarActions>
        {!user.ssoSession && (
          <li>
            <a onClick={() => getOpenEditModal(true)}>
              <IconContainer>
                <span id="icon-edit">
                  <Icons.Edit />
                </span>
              </IconContainer>
              <span>Editar perfil</span>
            </a>
          </li>
        )}

        {isConfig && (
          <li>
            <a
              onClick={() => {
                const originRoute = isClinicalStaff ? clinicalStaffRoute : getOfficeRoute
                const route = `${originRoute}/${ROUTES.SETTINGS}`
                window.location.href = `${window.location.origin}${route}`
              }}
            >
              <IconContainer>
                <span id="icon-settings">
                  <Icons.Settings />
                </span>
              </IconContainer>
              <span>Configurações</span>
            </a>
          </li>
        )}

        {healthProfessionalHasModule(EModulesIds.MIGRATION_MODULE_ID) && (
          <li>
            <a
              onClick={() => {
                const route = `${clinicalStaffRoute}/${ROUTES.MIGRATION}`
                window.location.href = `${window.location.origin}${route}`
              }}
            >
              <IconContainer>
                <span id="icon-import">
                  <Icons.Import />
                </span>
              </IconContainer>
              <span>Importar dados</span>
            </a>
          </li>
        )}
        <li>
          <Link to="/" onClick={() => logout()}>
            <IconContainer>
              <span id="logout">
                <Icons.Logout stroke="currentColor" fill="currentColor" />
              </span>
            </IconContainer>
            <span>Sair</span>
          </Link>
        </li>
      </UserSidebarActions>
    </UserSidebarContainer>
  )
}
