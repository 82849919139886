import { AxiosError, AxiosRequestConfig } from 'axios'

export interface AxiosHttpClientErrorParams {
	data: AxiosRequestConfig
	e: unknown
}

export class AxiosHttpClientError extends Error {
	readonly data: any
	constructor({ data, e }: AxiosHttpClientErrorParams) {
		super()
		this.data = data
		const { message, cause } = e as Error
		this.message = message
		this.cause = cause
		this.log(message, cause, data, this.getReponseError(e as AxiosError))
	}

	log = (message: any, cause: any, data: any, response: any) => {
		console.log(
			`Ocorreu um erro inesperado ao tentar executar um ${data.method} na rota ${
				data.url
			}: ${JSON.stringify({
				message,
				cause,
				response
			})}`
		)
	}

	getReponseError = (error: AxiosError) => {
		if (error.isAxiosError && error.response?.data) return error.response?.data
	}
}
