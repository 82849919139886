import React, { useEffect, useState, ReactElement } from 'react'
import {
  InputBox,
  FormContainer,
  LoginForm,
  LoginLogo,
  LoginDiv,
  LoginSection,
  Mask,
  StyledForm,
  Box,
  PasswordViewer
} from './styles'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  login,
  selectSessionErrors,
  selectUserSession,
  selectLoading,
  selectCertification,
  selectAuthentication,
  setCertification,
  selectContextSSO,
  setLoginWithoutBirdId,
  sessionError,
  selectAuthenticationLoading
} from '../../modules/login/sessionSlice'
import { PreloadingScreen } from '../../components/LoaderScreen/PreloadingScreen'
import { Button, IconExporter, Input, Tooltip } from '@cockpit/zera'
import { AuthenticatorModal } from '../../components/Authenticator/'
import { ErrorMessage } from '../../components/ErrorMessage'
import { DefaultPlatformModal } from '../../components/LoginModais/DefaultPlatformModal/'
import { CongratsLinked } from '../../components/LoginModais/CongratsLinked/'
import { LoginActiveDirectory } from '../../components/LoginModais/LoginActiveDirectory/'
import { PasswordModal } from '../../components/ChangePassword/PasswordModal'
import { IncorrectPasswordModal } from '../../components/LoginModais/IncorrectPasswordModal'
import { BlockedAccessModal } from '../../components/LoginModais/blockedAccessModal'

import { errorMessages } from './constants'
import Icons from '../../assets/icons'
import { SessionData, SessionError } from '../../modules/login/Session'
import { ACCESS_MANAGER_PLATFORM_TAG, inputLoginId } from '../../enums'
import Footer from '../../components/Footer'
import useResize from '../../components/_layouts/useResize'
import { useSession, useContextSSO } from './hooks'
import { getSessionErrorKey, dynamicErrorMessage, userHasMoreThanOneAccess } from './functions'
import { withoutTheRouteParameter } from '../../shared/functions/sessionValidation'
import LocalStorageManager from '../../helpers/localStorage'
import { PermissionsData } from '../../modules/login/PermissionsResponse'
import { needCertificate } from '../../utils/needCertificate'
import ENV from '../../modules/env'
import { ErrorModal } from '../../components/LoginModais/ErrorModal'
import { CredentialExpiredModal } from '../../components/LoginModais/CredentialExpiredModal'
import ModalCertification from '../../components/LoginModais/ModalCertification'
import { healthProfessionalHasModule } from '../../plugins/access'
import { EModulesIds } from '../../enums/access'
import { ModalUnifiedLoginWarning } from '../../components/modal-unified-login-warning/modal-unified-login-warning'

interface Inputs {
  user: string
  password: string
}

const { REACT_APP_CLINICAL_STAFF_PLATFORM_ID: CLINICAL_STAFF_ID } = process.env

export const Login: React.FC = (): ReactElement => {
  const { register, handleSubmit } = useForm<Inputs>()
  const dispatch = useDispatch()
  const sessionErrors: SessionError = useSelector(selectSessionErrors)
  const sessionData: SessionData | null = useSelector(selectUserSession)
  const loadingLogin = useSelector(selectLoading)
  const isContextSSO = useSelector(selectContextSSO)
  const sessionCert = useSelector(selectCertification)
  const authenticationData = useSelector(selectAuthentication)
  const isAuthenticationLoading = useSelector(selectAuthenticationLoading)

  const isAuthenticationSuccess = isAuthenticationLoading === 'success'

  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [redirect, setRedirect] = useState<any>(null)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const [withoutBirdId, setWithoutBirdId] = useState(false)
  const [showAuthenticator, setShowAuthenticator] = useState(false)
  const [showLoginActiveDirectory, setShowLoginActiveDirectory] = useState(false)
  const [showCongratsLinked, setCongratsLinked] = useState(false)
  const [showDefaultPlatform, setShowDefaultPlatform] = useState(false)

  const [skipActiveDirectory, setSkipActiveDirectory] = useState(false)
  const [isCongratsClosed, setIsCongratsClosed] = useState(false)

  const [showCertModal, setShowCertModal] = useState(false)
  const [isErrorPlatforms, setIsErrorPlatforms] = useState(false)
  const [shouldRenderUnifiedLoginWarningModal, setShouldRenderUnifiedLoginWarningModal] = useState(false)

  function handleAcceptUnifiedLoginWarningModal(): void {
    const acceptedCockpitNewsModalDate = new Date().toString()
    localStorage.setItem('newCockpitLoginInfoAccept', acceptedCockpitNewsModalDate)
    setShouldRenderUnifiedLoginWarningModal(false)
  }

  function shouldRenderNewsModal(): void {
    const acceptedCockpitNewsModalDateString = localStorage.getItem('newCockpitLoginInfoAccept')

    if (acceptedCockpitNewsModalDateString === null) {
      setShouldRenderUnifiedLoginWarningModal(true)
      return
    }

    const acceptedCockpitNewsModalDate = new Date(acceptedCockpitNewsModalDateString)

    const today = new Date()
    if (acceptedCockpitNewsModalDate.getDate() !== today.getDate()) {
      setShouldRenderUnifiedLoginWarningModal(true)
      return
    }

    setShouldRenderUnifiedLoginWarningModal(false)
  }

  useEffect(() => {
    shouldRenderNewsModal()
  }, [])

  const sessionPlatforms = sessionData
    ? sessionData?.user.allPlatforms.filter(platform => platform.tag === ACCESS_MANAGER_PLATFORM_TAG)
    : []

  const isOnlyCCL =
    sessionPlatforms.length === 1 && !!sessionPlatforms.find(item => String(item.id) === String(CLINICAL_STAFF_ID))

  const windowSize = useResize()

  const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
  const { isError, isServerError, blockedAccess, incorrectPassword, isLoading, resetState } = useSession(
    loadingLogin,
    sessionErrors
  )

  const [isCredentialExpired, setIsCredentialExpired] = useState(false)

  useEffect(() => {
    setIsCredentialExpired(!!sessionErrors?.error?.includes('As credencias do usuário estão expiradas.'))
  }, [sessionErrors])

  const [isLoginSSO, queryParamsSSO] = useContextSSO()

  const {
    moreThanOnePlatformOrOffice,
    defaultOfficeId,
    defaultPlatformId,
    isDirectorySuggestion,
    successDirectorySuggestion
  } = userHasMoreThanOneAccess(sessionData)

  const isMobile = windowSize < 500

  const handleRedirectUserToPlatform = (route?: string) => {
    setShowDefaultPlatform(false)

    if (((sessionCert && authenticationData?.access_token) ?? isLoginSSO) && !route) {
      return setRedirect(<Redirect to={withoutTheRouteParameter(sessionData, queryParamsSSO)} />)
    }

    if (((sessionCert && authenticationData?.access_token) ?? isLoginSSO) && route) {
      return setRedirect(<Redirect to={route} />)
    }

    if (sessionData && withoutBirdId && !route) {
      return setRedirect(<Redirect to={withoutTheRouteParameter(sessionData)} />)
    }
  }

  const onSubmit = (data: Inputs) => {
    resetState(['isError', 'isServerError'])
    setOldPassword(data.password)
    dispatch(login(data.user, data.password))
  }

  useEffect(() => {
    const localStoragePermissions = LocalStorageManager.ReadEncryptedData<PermissionsData>(
      'permissions-encrypted'
    ) as PermissionsData
    const checkNeedCertificate = needCertificate(localStoragePermissions)

    const hasVidaas = !!healthProfessionalHasModule(EModulesIds.CERTIFICATION_VIDAAS, sessionData)

    if (sessionData?.user?.first_access) {
      setShowPasswordModal(true)
    }

    if (isLoginSSO) {
      setShowAuthenticator(false)
    } else {
      // TO-DO, refatorar lógica de login e diminuir complexidade

      const isShowVidaas = isOnlyCCL && hasVidaas

      if (
        !isShowVidaas &&
        checkNeedCertificate &&
        sessionData?.user &&
        !authenticationData?.access_token &&
        !sessionData.user.first_access &&
        !showAuthenticator &&
        !showLoginActiveDirectory &&
        !showCongratsLinked &&
        !showDefaultPlatform
      ) {
        setShowAuthenticator(true)
      } else {
        if (
          sessionData?.user &&
          showAuthenticator &&
          authenticationData?.access_token &&
          (sessionData.user.first_access || showLoginActiveDirectory || showCongratsLinked || showDefaultPlatform)
        ) {
          setShowAuthenticator(false)
        }

        if (isShowVidaas && checkNeedCertificate && isAuthenticationSuccess) {
          setWithoutBirdId(true)
          dispatch(setLoginWithoutBirdId(sessionData))
          return
        }
      }

      if (sessionData?.user && !checkNeedCertificate && !sessionData.user.first_access && !isShowVidaas) {
        setWithoutBirdId(true)
        dispatch(setLoginWithoutBirdId(sessionData))
      }

      if (authenticationData && !isShowVidaas) {
        dispatch(setCertification(authenticationData.access_token))
      }
    }
  }, [
    isOnlyCCL,
    isAuthenticationSuccess,
    sessionData,
    showAuthenticator,
    showLoginActiveDirectory,
    showCongratsLinked,
    showDefaultPlatform,
    authenticationData,
    isLoginSSO,
    dispatch
  ])

  useEffect(() => {
    const localStoragePermissions = LocalStorageManager.ReadEncryptedData<PermissionsData>(
      'permissions-encrypted'
    ) as PermissionsData

    const checkNeedCertificate = needCertificate(localStoragePermissions)

    const hasVidaas = !!healthProfessionalHasModule(EModulesIds.CERTIFICATION_VIDAAS, sessionData)

    if (loadingLogin === 'success') {
      const isAuthentication = !!(sessionCert && authenticationData?.access_token) || isLoginSSO || withoutBirdId

      if (isLoginSSO) {
        handleRedirectUserToPlatform()
      } else if (isOnlyCCL && hasVidaas && checkNeedCertificate && !isAuthenticationSuccess) {
        setShowCertModal(true)
        setIsErrorPlatforms(false)
      } else if (
        isAuthentication &&
        isDirectorySuggestion &&
        !sessionData?.user?.first_access &&
        !skipActiveDirectory
      ) {
        setShowLoginActiveDirectory(true)
      } else if (
        isAuthentication &&
        successDirectorySuggestion &&
        !sessionData?.user?.first_access &&
        !isCongratsClosed
      ) {
        setShowLoginActiveDirectory(false)
        setCongratsLinked(true)
      } else if (
        isAuthentication &&
        !defaultPlatformId &&
        !defaultOfficeId &&
        moreThanOnePlatformOrOffice &&
        !sessionData?.user?.first_access
      ) {
        setShowDefaultPlatform(true)
      } else if (sessionData) {
        handleRedirectUserToPlatform()
      }
    }
  }, [
    isOnlyCCL,
    isAuthenticationSuccess,
    withoutBirdId,
    sessionData,
    sessionCert,
    authenticationData,
    successDirectorySuggestion,
    isDirectorySuggestion,
    skipActiveDirectory,
    defaultPlatformId,
    isCongratsClosed,
    isLoginSSO,
    loadingLogin
  ])

  return (
    <LoginSection>
      <ModalUnifiedLoginWarning
        handleAcceptUnifiedLoginWarningModal={handleAcceptUnifiedLoginWarningModal}
        shouldRenderUnifiedLoginWarningModal={shouldRenderUnifiedLoginWarningModal}
      />
      <PreloadingScreen visible={isContextSSO} />
      <Mask />
      <ErrorModal
        show={isServerError}
        onClose={() => {
          resetState(['isServerError'])
          dispatch(sessionError(null))
        }}
      />
      {redirect}
      <AuthenticatorModal
        userName={sessionData ? sessionData.user?.name : ''}
        showModal={showAuthenticator}
        onClose={() => setShowAuthenticator(false)}
      />
      {showCertModal && (
        <ModalCertification
          onClose={() => setShowCertModal(false)}
          onErrorPlatforms={() => setIsErrorPlatforms(true)}
        />
      )}
      <LoginActiveDirectory
        showModal={showLoginActiveDirectory}
        onClose={() => {
          setShowLoginActiveDirectory(false)

          const isAuthentication = !!(sessionCert && authenticationData?.access_token) || isLoginSSO || withoutBirdId

          if (
            isAuthentication &&
            !defaultPlatformId &&
            !defaultOfficeId &&
            moreThanOnePlatformOrOffice &&
            !sessionData?.user?.first_access
          ) {
            setShowDefaultPlatform(true)
            setSkipActiveDirectory(true)
          } else {
            handleRedirectUserToPlatform()
          }
        }}
      />
      <CongratsLinked
        showModal={showCongratsLinked}
        onClose={() => {
          setCongratsLinked(false)
          setIsCongratsClosed(true)

          const isAuthentication = !!(sessionCert && authenticationData?.access_token) || isLoginSSO || withoutBirdId

          if (
            isAuthentication &&
            !defaultPlatformId &&
            !defaultOfficeId &&
            moreThanOnePlatformOrOffice &&
            !sessionData?.user?.first_access
          ) {
            setShowDefaultPlatform(true)
          } else {
            handleRedirectUserToPlatform()
          }
        }}
      />
      <IncorrectPasswordModal showModal={incorrectPassword} onClose={() => resetState(['incorrectPassword'])} />
      <BlockedAccessModal
        showModal={blockedAccess}
        onClose={() => resetState(['blockedAccess'])}
        emailMasked={!isCredentialExpired ? sessionErrors?.info?.email : undefined}
      />
      <CredentialExpiredModal showModal={isCredentialExpired} onClose={() => setIsCredentialExpired(false)} />
      <DefaultPlatformModal showModal={showDefaultPlatform} onClose={handleRedirectUserToPlatform} />
      <PasswordModal
        oldPassword={oldPassword}
        showPasswordModal={showPasswordModal}
        setShowPasswordModal={setShowPasswordModal}
      />
      <FormContainer>
        <LoginDiv>
          {ENV.TENANT ? (
            <img alt="logo SaaS" src={`${ENV.TENANT_LOGO_URL}`} />
          ) : (
            <LoginLogo>
              <img alt="logo hospital albert einstein" src={`${ENV.IMAGES_URL}/images/logo_ae_branco.svg`} />
            </LoginLogo>
          )}
          <LoginForm>
            {isErrorPlatforms ? (
              <ErrorMessage show showByProperty="visibility" margin="1rem 0" noCloseButton>
                {errorMessages.genericError}
              </ErrorMessage>
            ) : (
              !isCredentialExpired && (
                <ErrorMessage show={isError} showByProperty="visibility" margin="1rem 0" noCloseButton>
                  {dynamicErrorMessage(sessionErrors?.info?.missing_attempts, sessionErrors?.error) ??
                    errorMessages[getSessionErrorKey(sessionErrors)]}
                </ErrorMessage>
              )
            )}
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <InputBox>
                  <Input
                    {...register('user', { required: true })}
                    onFocus={() => setIsInputFocus(true)}
                    onBlur={() => setIsInputFocus(false)}
                    placeholder="Usuário"
                    type="text"
                    id="usuario"
                    data-testid="user-login"
                    data-cy={inputLoginId.INPUT_USER_LOGIN}
                  />
                  <label htmlFor="usuario">
                    <Tooltip
                      description="Para realizar o login, utilize o nome de usuário cadastrado. Não é possível realizar login com o e-mail."
                      position={window.innerWidth >= 768 ? 'right' : 'left'}
                      opacity={0.9}
                      textMaxWidth={'250px'}
                    >
                      <IconExporter name="info_icon" iconsize={20} />
                    </Tooltip>
                  </label>
                </InputBox>
                <InputBox>
                  <Input
                    onCopy={(e: any) => {
                      e.preventDefault()
                      return false
                    }}
                    onFocus={() => setIsInputFocus(true)}
                    {...register('password', { required: true, onBlur: () => setIsInputFocus(false) })}
                    placeholder="Senha"
                    type={isPasswordVisible ? 'text' : 'password'}
                    margin={'5px 0 10px 0'}
                    data-testid="password-login"
                    data-cy={inputLoginId.INPUT_PASSWORD_LOGIN}
                  />
                  <PasswordViewer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {isPasswordVisible ? <Icons.ViewActive /> : <Icons.View />}
                  </PasswordViewer>
                </InputBox>
              </Box>

              {/* TODO: resolver warning: index.js:1 Warning: Received `false` for a non-boolean attribute `loading`. */}
              <Button
                type="submit"
                isLoading={isLoading}
                data-testid="submit-login"
                data-cy={inputLoginId.INPUT_SUBMIT_LOGIN}
              >
                ENTRAR
              </Button>
            </StyledForm>
            <Link onClick={() => resetState(['all'])} to="/forgot-password">
              Esqueceu a senha?
            </Link>
          </LoginForm>
        </LoginDiv>
        {ENV.TENANT ? null : <Footer isFocused={isInputFocus && isMobile} />}
      </FormContainer>
    </LoginSection>
  )
}
