import React from 'react'
import { Button } from '@cockpit/zera'

import { ResponsiveModal } from '../../../shared/ResponsiveModal'
import {
  Container,
  Title,
  Options,
  Text,
  IconContainer
} from './styles'
import Warning from '../../../assets/icons/warning'

interface Props {
  showModal: boolean
  onClose: (route?: string) => void
}

export const CredentialExpiredModal: React.FC<Props> = ({
  showModal,
  onClose
}) => {
  const handleChangePassword = () => {
    window.open('https://esqueciminhasenha.einstein.br/Pages/NewRequest.aspx', '_blank')
  }

  return (
    <ResponsiveModal
      show={showModal}
      closeOnClickMask={false}
      onClose={onClose}
      padding={window.innerWidth > 500 ? '40px 48px' : '20px'}
    >
      <Container>
        <div>
          <IconContainer>
            <Warning />
          </IconContainer>
          <Title>Senha de rede expirada</Title>
          <Text>
            Altere sua senha e tente novamente. <br /><br />
            Após a alteração, a validação da senha pode levar alguns minutos
          </Text>
          </div>
        <Options>
          <Button type="button" variant='outlined' onClick={onClose}>
            Voltar
          </Button>
          <Button type="button" onClick={handleChangePassword}>
            Alterar senha
          </Button>
        </Options>
      </Container>
    </ResponsiveModal>
  )
}
