import React from 'react'
import { Routes } from './routes/Routes'
import store from './modules/store/store'
import { theme } from './components/Theme/Theme'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ChatContainer } from './components/_layouts/default/styles'

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <React.Fragment>
          <ThemeProvider theme={theme}>
            <ChatContainer>
              <Routes />
            </ChatContainer>
          </ThemeProvider>
        </React.Fragment>
      </BrowserRouter>
    </Provider>
  )
}

export default App
