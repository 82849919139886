import LocalStorageManager, { localStorageDataType } from '../../helpers/localStorage'

export const readLocalStorage = function <T>(
	key: localStorageDataType
): T | null {
	const storageData = JSON.parse(localStorage.getItem(key) ?? '{}')

	return Object.keys(storageData).length > 0 ? storageData : null
}

export const readLocalStorageString = function (
	key: localStorageDataType
): string | null {
	const storageData = localStorage.getItem(key)

	return storageData
}

export const removeVerificaFromStorage = function (
) {
	localStorage.removeItem('verifica_token')
	localStorage.removeItem('verifica_token_expiration_date')
	localStorage.removeItem('verifica_token_expires_in')
	localStorage.removeItem('access_token_expires_in')
	localStorage.removeItem('verifica_token-encrypted')
	localStorage.removeItem('verifica_token_expiration_date-encrypted')
	localStorage.removeItem('verifica_token_expires_in-encrypted')
	localStorage.removeItem('access_token_expires_in-encrypted')
}

export const deleteLocalStorage = function (key: localStorageDataType): void {
	localStorage.removeItem(key)
	// emit events!!!
}

/**
 *
 * TODO	essa função não era para estar aqui
 * esse service salva faz 'crud' dos dados na sessão
 * sem qualquer logica de negocio
 */
export const addCertificateLocalStorage = function <T>(
	key: localStorageDataType,
	value: T
) {
	const sessionObject = localStorage.getItem(key)
	const currentSessionStorage =
		sessionObject && JSON.parse(sessionObject || '{}')
	if (currentSessionStorage) {
		const updatedSessionStorage = {
			...currentSessionStorage,
			certificate: value
		}
		LocalStorageManager.WriteEncryptedData(key, updatedSessionStorage)
		localStorage.setItem(key, JSON.stringify(updatedSessionStorage))
	}
}
