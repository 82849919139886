import styled from 'styled-components'

interface IPasswordModalCheck {
  isChecked: boolean;
}

export const Container = styled.div`
  width: 380px;
  margin: 20px auto;
  height: 100%;
  
  h1{
    font-size: 20px;
    max-width: 250px;
  }
  form{
    display: flex;
    flex-direction: column;
    button{
      align-self: flex-end;
      min-width: 40%;

      :disabled{
        cursor: default;
      }
    }
  }

  @media(max-width: 414px){
    width: 90%;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 45px;
  margin: 7px 0;
  h3{
    font-size: 14px;
    color: #545E6C;
  }

  @media(max-width: 414px){
    height: 60px;
  }
`

export const PasswordChecks = styled.div`
  background-color: #F3F5F9;
  width: 100%;
  padding: 10px 24px;
  border-radius: 5px;
  label{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    font: normal 14px 'Inter';
    color: #6B7786;
  }
`
export const RoundedCheck = styled.div<IPasswordModalCheck>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${props => props.isChecked ? '#378FF7' : '#C1C4CC'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  svg{
    height: 8px;
    width: 8px;
  }
  transition: all .5s;
`

export const InputBox = styled.div`
  margin: 20px 0;

  label{
    font-size: 13px;
  }
  input{
    padding: 0 14% 0 10px;
  }
  .errorMessage{
    color: red;
    font-size: 13px;
  }

`

export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PasswordViewer = styled.div`
  position: relative;
  left: -12%;
  width: 42px;
  margin: 0 -100% 1% 0;
  transition: all .5s;
  @media(min-width: 1025px){
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 414px){
    left: -15%;
  }
  @media(max-width: 320px){
    left: -17%;
  }
`
