import React from 'react'

const Phone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.013" height="16.015" viewBox="0 0 16.013 16.015">
    <g id="Iconly_Light_Call" data-name="Iconly/Light/Call" transform="translate(0.603 0.601)">
      <g id="Call">
        <path id="Call-2" data-name="Call" d="M.382,1.849C.629,1.441,1.987-.044,2.956,0A1.275,1.275,0,0,1,3.71.4a13.143,13.143,0,0,1,1.923,2.6c.189.911-.9,1.436-.564,2.354A7.7,7.7,0,0,0,9.449,9.738c.917.332,1.442-.753,2.353-.563a13.167,13.167,0,0,1,2.6,1.923,1.277,1.277,0,0,1,.4.754c.036,1.021-1.541,2.4-1.848,2.574a2.7,2.7,0,0,1-2.825-.026C6.916,13.061,1.773,8.014.409,4.674A2.7,2.7,0,0,1,.382,1.849Z" transform="translate(0 0)" fill="none" stroke="#6b7786" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
      </g>
    </g>
  </svg>
)

export default Phone
