import React, { useEffect, useState } from 'react'
import { ResponsiveModal } from '../../../shared/ResponsiveModal'

import {
  Container,
  Title,
  SelectCertification,
  ItemCert,
  LeftCert,
  TitleCert,
  Description,
  ButtonClose
} from './styles'
import { IconExporter } from '@cockpit/zera'

import birdId from '../../../assets/icons/svg/bird-id.svg'
import vidaas from '../../../assets/icons/svg/vidaas.svg'

import BirdId from './components/BirdId'
import Vidaas from './components/Vidaas'
import useResize from '../../_layouts/useResize'
import { authenticationApi } from '../../../modules/login/authentication.api'
import { PlatformsCertification } from '../../../modules/login/AuthenticateResponse'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSession, setAuthenticationLoading } from '../../../modules/login/sessionSlice'
import { SessionData } from '../../../modules/login/Session'

interface Props {
  onClose: () => void
  onErrorPlatforms: () => void
}

const ModalCertification: React.FC<Props> = ({ onClose, onErrorPlatforms }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [loadingRequest, setLoadingRequest] = useState(false)

  const sessionData: SessionData | null = useSelector(selectUserSession)

  const [platforms, setPlatforms] = useState<PlatformsCertification[]>([])
  const [typeModal, setTypeModal] = useState<'birdId' | 'vidaas' | null>(null)

  const birdIdPlatform = platforms.find(item => item.id === 1)
  const vidaasPlatform = platforms.find(item => item.id === 2)

  const isMobile = useResize() <= 500

  const handleBack = () => {
    setTypeModal(null)
    dispatch(setAuthenticationLoading('idle'))
  }

  const setIsStandart = async (id: number | undefined, defaultValue: boolean) => {
    const oldPlatforms = platforms

    setPlatforms(
      platforms.map(item => ({
        ...item,
        default: item.id === id ? defaultValue : defaultValue ? false : item.default
      }))
    )

    if (sessionData) {
      const response = await authenticationApi.defaultCertificate(
        defaultValue ? (id ?? 0) : 0,
        sessionData
      )

      if (!response.success) {
        setPlatforms(oldPlatforms)
      }
    }
  }

  const onCloseModal = () => {
    if (typeModal) {
      setTypeModal(null)
      return
    }

    onClose()
  }

  useEffect(() => {
    const getData = async () => {
      if (sessionData && !platforms.length) {
        const response = await authenticationApi.getPlataformCertification(sessionData.token)

        if (response.length <= 0) {
          onErrorPlatforms()
          onClose()
          return
        }

        const defaultPlatform = response.find(item => item.default)

        if (defaultPlatform) {
          setTypeModal(defaultPlatform.id === 1 ? 'birdId' : 'vidaas')
        }

        setPlatforms(response)
        setLoading(false)
      }
    }

    getData()
  }, [sessionData, platforms, onErrorPlatforms])

  if (!loading) {
    return (
      <ResponsiveModal
        show
        hideCloseIcon={!isMobile || loadingRequest}
        closeOnClickMask={false}
        onClose={!loadingRequest ? onCloseModal : () => {}}
        width="470px"
        padding="16px 24px"
      >
        <>
          {(!isMobile && !loadingRequest) && (
            <ButtonClose type="button" onClick={onClose}>
              <IconExporter name="close" iconsize={16} />
            </ButtonClose>
          )}
          {!typeModal && (
            <Container>
              <Title>Certificado digital</Title>

              <SelectCertification>
                <Description>Selecione o certificado que deseja utilizar:</Description>
                {platforms.map(item => (
                  <ItemCert onClick={() => setTypeModal(item.id === 1 ? 'birdId' : 'vidaas')} key={String(item.id)}>
                    <LeftCert>
                      <img
                        src={item.logo_url ? item.logo_url : item.id === 1 ? birdId : vidaas}
                        alt="Platform"
                        width={40}
                        height={40}
                      />
                      <TitleCert>{item.description}</TitleCert>
                    </LeftCert>
                    <IconExporter name="arrow_right" iconsize={15} />
                  </ItemCert>
                ))}
              </SelectCertification>
            </Container>
          )}
          {typeModal === 'vidaas' && (
            <Vidaas
              platform={vidaasPlatform}
              setIsStandart={setIsStandart}
              onBack={handleBack}
              isMobile={isMobile}
              onClose={onClose}
              setLoadingRequest={setLoadingRequest}
            />
          )}
          {typeModal === 'birdId' && (
            <BirdId
              platform={birdIdPlatform}
              setIsStandart={setIsStandart}
              onBack={handleBack}
              isMobile={isMobile}
              onClose={onClose}
              setLoadingRequest={setLoadingRequest}
            />
          )}
        </>
      </ResponsiveModal>
    )
  }

  return <React.Fragment />
}

export default ModalCertification
