import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 2px);

  iframe {
    height: 100%;
    width: 100%;
    border: 0px solid;
    margin: 0px;
    padding: 0px;
  
  }
`
