import { theme } from '@cockpit/zera'
import styled from 'styled-components'

interface IPropsTitle {
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  textTransform?: string
  fontWeight?: string
  fontSize?: string
  color?: string
}

interface IPropsUserEditModalStyle extends IPropsTitle {
  margin?: string
  color?:string
  displayNoneMobile?: string
}

export const ContainerUserAvatarEditModal = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.medium};
  display: flex;
  align-items:center;

  @media(max-width: 768px){
    flex-direction: column;
  }
`
export const UserSidebarAvatar = styled.div`
	& div {
	  margin: 0px;
    width: 60px;
    height: 60px;
    font: normal 25px 'Inter';
	}
`
export const DisplayBoxUserNameWithIcon = styled.div`
  display: flex;
  align-items: flex-end;
`
export const ContainerSignature = styled.div`
  width: 100%;
  background: #2D3139;
`
export const TitleForm = styled.p<IPropsUserEditModalStyle>`
  font-size: 13px;
  font-weight: ${theme.fontWeight.semibold};
  color: #2d3139;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.small};
  text-transform: uppercase;
`
export const TitleName = styled.h4`
  font-size: 20px;
  font-weight: ${theme.fontWeight.bold};
  color: #2D3139;
`

export const ProfileDataUser = styled.div`
  width: 100%;
  margin-left: 2%;

  @media(max-width: 768px){
    margin-top: ${theme.spacing.medium};
    text-align: center;
    margin-left: 0;
  }
`
export const FormContainer = styled.form`
`
export const ButtonBoxSignature = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.small};
  display: flex;
  justify-content: flex-end;

 @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
  }
`
export const InputContentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
  }
`
export const InputBox = styled.div`
  width: 227px;

  label{
    font: normal 13px 'Inter';
  }
  input{
    padding: 0 15% 0 10px;
  }
  .errorMessage, .successMessage{
    font: normal 14px 'Inter';
  }
  .errorMessage{
    color: red;
  }
  .successMessage{
    color: ${theme.colors.green1};
  }

  @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
  }
`
export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  >svg{
    position: relative;
    left: -10%;
    width: 42px;
    margin: 0 -100% 1% 0;
    transition: all .5s;
    @media(min-width: 1025px){
      :hover{
        cursor: pointer;
      }
    }
    @media(max-width: 414px){
      left: -15%;
    }
    @media(max-width: 320px){
      left: -17%;
    }
  }
`

export const ButtonBox = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.small};
  display: flex;
  justify-content: space-between;

  @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
  }
`
export const ContainerMessages = styled.div`
  width: 70%;
  margin-bottom: 3%;

  @media(max-width: 768px){
      width: 100%;
  }
`
export const Inform = styled.div`
  @media(max-width: 768px){
    width: 100%;
  }
`
export const ErrorMessageRequeridField = styled.span`
  width: 100%;
  font: normal 14px 'Inter';
  color: red;

  @media(max-width: 768px){
    padding-bottom: 5%;
}
`
export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFE9E9;
  border-radius: 5px;
  padding: 2% 3%;
  line-height: 18px;
  text-align: center;

  @media(max-width: 768px){
    margin-bottom: 1%;
  }
`

export const SuccessMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cae8cb;
  border-radius: 5px;
  padding: 4% 3%;
  line-height: 18px;
  text-align: center;

  @media(max-width: 768px){
    margin-bottom: 1%;
  }
`
