import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, IconExporter } from '@cockpit/zera'

import { ResponsiveModal } from '../../../shared/ResponsiveModal'
import {
  Container,
  Title,
  Description,
  ListPlatforms,
  SelectPlatformOrOffice,
  Options
} from './styles'
import { SessionData } from '../../../modules/login/Session'
import { selectUserSession, setDefaultPlatformOfficeId } from '../../../modules/login/sessionSlice'
import { setDataInTheSessionLocalStorage, setOfficeInLocalStorage, getPlatformsAndOffices } from './functions'
import { getPlatformRoute, getOfficeRoute } from '../../../models/platformRoute'
import { message } from '../../../messages'
import { IPlatformsOrOffices } from './interfaces'

interface Props {
  showModal: boolean
  onClose: (route?: string) => void
}

export const DefaultPlatformModal: React.FC<Props> = ({
  showModal,
  onClose
}) => {
  const [platforms, setPlatformsOrOffices] = useState<IPlatformsOrOffices[]>([])
  const [checkedPlatformOfficeDefault] = useState(false)
  const userSession: SessionData | null = useSelector(selectUserSession)
  const dispatch = useDispatch()

  const handleChecked = (position: number) => {
    setPlatformsOrOffices(platforms.map((item, index) => ({
      ...item,
      checked: index === position
    })))
  }

  const disabledButton = !platforms.find(item => item.checked)?.checked

  const onSubmitDefault = () => {
    const getPlatformOrOfficeChecked = platforms.find(item => item.checked)

    if(checkedPlatformOfficeDefault && getPlatformOrOfficeChecked?.isOffice) {
      dispatch(setDefaultPlatformOfficeId({
        defaultOfficeId: getPlatformOrOfficeChecked?.id
      }))
    }

    if (checkedPlatformOfficeDefault && !getPlatformOrOfficeChecked?.isOffice) {
      dispatch(setDefaultPlatformOfficeId({
        defaultPlatformId: getPlatformOrOfficeChecked?.id
      }))
    }

    if (getPlatformOrOfficeChecked?.isOffice) {
      setDataInTheSessionLocalStorage({
        defaultOfficeId: getPlatformOrOfficeChecked?.id,
        dispatch
      })
      setOfficeInLocalStorage(userSession, getPlatformOrOfficeChecked)
    } else {
      setDataInTheSessionLocalStorage({
        defaultPlatformId: getPlatformOrOfficeChecked?.id,
        dispatch
      })
    }

    if (getPlatformOrOfficeChecked?.isOffice) {
      onClose(getOfficeRoute)
    } else {
      onClose(getPlatformRoute(getPlatformOrOfficeChecked?.id ?? null))
    }
  }

  useEffect(() => {
    setPlatformsOrOffices(getPlatformsAndOffices(userSession))
  }, [userSession])

  return (
    <ResponsiveModal
      show={showModal}
      closeOnClickMask={false}
      onClose={onClose}
      padding='0 42px 40px'
      hideCloseIcon
    >
      <Container>
        <Title>{message('defaultPlatform.hello')}</Title>
        <Description>
          {message('defaultPlatform.description')} <br />
          <strong>{message('defaultPlatform.select')}</strong>
        </Description>
        <ListPlatforms>
          {platforms.map((item, index) => (
            <SelectPlatformOrOffice key={String(index)} checked={item.checked} onClick={() => handleChecked(index)} data-testid={`select-platform-${index}`}>
              <IconExporter name='check' iconsize={10}/> {item.name}
            </SelectPlatformOrOffice>
          ))}
        </ListPlatforms>
        {/* <CheckboxDefault>
          <Checkbox
            label={message('defaultPlatform.define')}
            name='checkStandard'
            className='check-standard'
            onClick={() => setCheckedPlatformOrOfficeDefault(!checkedPlatformOfficeDefault)}
            checked={checkedPlatformOfficeDefault}
          />
        </CheckboxDefault> */}
        <Options>
          <Button type="button" disabled={disabledButton} onClick={onSubmitDefault} data-testid="confirm-default-platform">
            {message('defaultPlatform.confirm')}
          </Button>
        </Options>
      </Container>
    </ResponsiveModal>
  )
}
