import { useEffect, useState } from 'react'
import { debounce } from '../../helpers/debounce'

/**
 * Hook to get viewport width dinamically based on `resize` window event.
 * @returns A number with viewport width
 */
export const useResize = () => {
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

  useEffect(() => {
    // Avoid unecessary rerenders using a debounced function
    const getWindowWidth = debounce(() => setWindowSize(window.innerWidth), 100)

    window.addEventListener('resize', getWindowWidth)
    getWindowWidth()
    return () => window.removeEventListener('resize', getWindowWidth)
  }, [])

  return windowSize
}

export default useResize
