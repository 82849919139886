import React from 'react'

import { Container, Fill } from './styles'

interface IProgressBarProps {
    percentage?: number
    width?: string
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ percentage = 0, width }) => (
    <Container width={width}>
        <Fill percentage={Math.round(percentage)} />
    </Container>
)
