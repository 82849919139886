import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;
  @media(min-width: 600px) {
    width: 412px;
    padding-bottom: 0px;
  }
  * {
    font-family: Inter, sans-serif;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #545E6C;
  margin-bottom: 25px;
`

export const Text = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #4F5460;
  margin-bottom: 40px;
`

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-direction: column-reverse;
  @media(min-width: 600px) {
    flex-direction: row;
  }
  button {
    width: 100%;
  }
`
