import { useEffect, useState } from 'react'

interface UseCustomEvent<T> {
  subscribe: T | null
  publish: PublishEvent<T>
}

type PublishEvent<T = any> = (details: T) => void

/**
 * Cria comunicação entre microfront
 *
 * @param event - Event é o nome dado ao evento que deseja observar ou criar
 * @returns {UseCustomEvent}
 */
export const useCustomEvent = <T>(event: any): UseCustomEvent<T> => {
  const [subscribe, setSubscribe] = useState<T | null>(null)

  const publish: PublishEvent = (detail) => window.dispatchEvent(new CustomEvent(event, { detail }))

  const customEvent = (customEvent: any) => {
    if (customEvent.detail) {
      setSubscribe(() => customEvent.detail)
    }
  }

  useEffect(() => {
    window.addEventListener(event, customEvent)

    return () => {
      window.removeEventListener(event, customEvent)
    }
  }, [])

  return {
    subscribe,
    publish
  }
}
