import React, { useState, useEffect } from 'react'
import {
  AuthenticatorHeader,
  AuthenticatorContent,
  AuthenticatorFooter,
  AuthenticatorContainer,
  AuthenticatorSubTitle,
  Text
} from '../style'
import { AuthenticatorPropsInterface, clickOrigin } from '../interfaces'
import { Button } from '@cockpit/zera'
import { authenticate, selectAuthenticationLoading, selectUserSession } from '../../../modules/login/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SessionData } from '../../../modules/login/Session'
import VerificationInput from 'react-verification-input'
import { ErrorMessage } from '../../ErrorMessage'
import './styles.css'
import { Redirect } from 'react-router-dom'

export const Authenticator: React.FC<{
  authenticatorProps: AuthenticatorPropsInterface
  origin?: clickOrigin
}> = ({ authenticatorProps, origin }) => {
  const { title, subTitle, footer } = authenticatorProps
  const [authCode, setAuthCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invalidCode, setInvalidCode] = useState<boolean>(false)
  const [redirect, setRedirect] = useState<any>(null)
  const isAuthenticationLoading = useSelector(selectAuthenticationLoading)
  const sessionData: SessionData | null = useSelector(selectUserSession)
  const dispatch = useDispatch()

  const setCode = async (authCodeParam: string) => {
    setIsLoading(true)
    setInvalidCode(false)
    if (sessionData) {
      let { identifier } = sessionData.user.personal_document

      identifier = identifier.replace(/([.-])+/g, '')

      const response = await dispatch(authenticate(identifier, authCodeParam, sessionData))

      if (origin && !!response) {
        return setRedirect(<Redirect to={'/'} />)
      }
    }
  }

  const pressEnter = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      setCode(authCode)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', pressEnter)
    return () => {
      window.removeEventListener('keydown', pressEnter)
    }
  }, [authCode])

  useEffect(() => {
    if (isAuthenticationLoading === 'failing') {
      setIsLoading(false)
      setInvalidCode(true)
    }
  }, [isAuthenticationLoading])

  return (
    <AuthenticatorContainer id="AuthenticatorContainer">
      {redirect}
      <AuthenticatorHeader>{title}</AuthenticatorHeader>
      <AuthenticatorSubTitle>{subTitle}</AuthenticatorSubTitle>
      <AuthenticatorContent data-testid='authenticator-content'>
        <VerificationInput
          length={6}
          validChars="0-9"
          autoFocus
          onChange={setAuthCode}
          placeholder=""
          classNames={{
            container: 'container',
            character: 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected'
          }}
          inputProps={{
            inputMode: 'numeric',
            'data-testid': 'otp-code-input',
            autoComplete: 'one-time-code'
          }}
        />
        <ErrorMessage show={invalidCode} showByProperty="display" noCloseButton margin="8px 0" maxWidth="300px">
          Código inválido
        </ErrorMessage>
        <Text isLoading={isLoading} invalidCode={invalidCode}>
          Por favor, aguarde enquanto validamos seu acesso…
        </Text>
      </AuthenticatorContent>
      <AuthenticatorFooter>
        {/* TODO: resolver warning: index.js:1 Warning: Received `false` for a non-boolean attribute `loading`. */}
        <Button data-testid="confirm-button" onClick={async () => await setCode(authCode)} isLoading={isLoading}>
          {footer.button.text}{' '}
        </Button>
      </AuthenticatorFooter>
    </AuthenticatorContainer>
  )
}
