import styled from 'styled-components'

export const Container = styled.div<{show: boolean}>`
  background-color: #34373B;
  width: 100px;
  height: 40px;
  border-radius: 24px;
  color: #A7ABB2;
  font: 400 12px 'Inter';
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 32px;
  left: 50%;
  margin-left: -50px;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  opacity: ${props => props.show ? '100%' : '0%'};
  transition: all .7s;
`
