import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { ForgotPasswordModalBody } from './components/ForgotPasswordModalBody'
import { ResponsiveModal } from '../../shared/ResponsiveModal'
import useResize from '../_layouts/useResize'

export const ForgotPasswordModal: React.FC = () => {
  const [redirect, setRedirect] = useState<any>(null)
  const [show, setShow] = useState<boolean>(false)
  const windowSize = useResize()

  useEffect(() => {
    setShow(true)
  }, [])

  const onCloseModal = () => {
    setRedirect(<Redirect to="/" />)
  }

  const isMobile = windowSize < 500

  return (
    <ResponsiveModal
      show={show}
      closeOnClickMask={false}
      onClose={onCloseModal}
      padding='0'
      backgroundColor={isMobile ? '#F8F9FC' : '#FFF'}
    >
      {redirect}
      <ForgotPasswordModalBody
        setRedirect={setRedirect}
      />
    </ResponsiveModal>
  )
}
