import React from 'react'

const Warning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="Iconly_Bulk_Danger" data-name="Iconly/Bulk/Danger" xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56">
    <g id="Group">
      <path id="Fill_5" data-name="Fill 5" d="M8.714,56c-.042,0-.08,0-.125,0a9.327,9.327,0,0,1-2.938-.626A8.627,8.627,0,0,1,.666,44.006L24.093,4.468a8.847,8.847,0,0,1,3.52-3.379A9.25,9.25,0,0,1,39.92,4.5L63.194,43.731a8.588,8.588,0,0,1,.794,3.1,8.48,8.48,0,0,1-2.32,6.295A9.126,9.126,0,0,1,55.408,56L8.944,56Z" transform="translate(0 0)" fill="#b9c2ce" opacity="0.4"/>
      <path id="Combined_Shape" data-name="Combined Shape" d="M0,21.962a2.763,2.763,0,0,1,2.8-2.713,2.707,2.707,0,1,1,0,5.41A2.756,2.756,0,0,1,0,21.962ZM0,11.414V2.7A2.756,2.756,0,0,1,2.8,0,2.754,2.754,0,0,1,5.6,2.7v8.717a2.753,2.753,0,0,1-2.8,2.7A2.755,2.755,0,0,1,0,11.414Z" transform="translate(29.2 18.943)" fill="#b9c2ce"/>
    </g>
  </svg>
)

export default Warning
