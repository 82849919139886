import React from 'react'

import { HeaderEditModal } from '../EditUserModal/components/HeaderEditModal/HeaderEditModal'
import { SignatureModal } from '../EditUserModal/components/SignatureModal/SignatureModal'
import { useEditUserModal } from '../EditUserModal/context'

const ModalsEditUser: React.FC = () => {
  const {
    openUserEditModal,
    openSignatureModal,
    setOpenUserEditModal,
    setOpenSignatureModal,
    setIsOpenFromHeader
  } = useEditUserModal()

  return (
    <React.Fragment>
      <HeaderEditModal
        show={openUserEditModal}
        onClose={() => {
          setOpenUserEditModal(false)
          setIsOpenFromHeader(false)
        }}
      />
      <SignatureModal
        show={openSignatureModal}
        onClose={() => {
          setOpenSignatureModal(false)
          setIsOpenFromHeader(false)
        }}
      />
    </React.Fragment>
  )
}

export default ModalsEditUser
