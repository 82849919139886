import { theme } from '@cockpit/zera'
import styled, { css } from 'styled-components'

interface IPropsTitle {
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  textTransform?: string
  fontWeight?: string
  fontSize?: string
  color?: string
}

interface IPropsUserEditModalStyle extends IPropsTitle {
  margin?: string
  color?:string
  displayNoneMobile?: string
}

interface IPasswordModalCheck {
  isChecked: boolean;
}

interface IFormContainer {
  userInActiveDirectory: boolean;
}

export const Content = styled.div``

export const ContainerSecurityEditModal = styled.div`
  margin-top: ${theme.spacing.base};
  .select-options-wrapper {
    box-shadow: none;
  }
`
export const TitleForm = styled.p<IPropsUserEditModalStyle>`
  font-size: 13px;
  font-weight: ${theme.fontWeight.semibold};
  color: #2d3139;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.small};
  text-transform: uppercase;
`
export const TitleName = styled.h4`
  font-size: 20px;
  font-weight: ${theme.fontWeight.bold};
  color: #2D3139;
`
export const FormContainer = styled.form<IFormContainer>`
  ${({ userInActiveDirectory }) => userInActiveDirectory && css`
    height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(min-width: 415px) {
      height: 100%;
    }
  `}
`
export const InputContentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
  }
`
export const InputBox = styled.div`
  width: 227px;

  label{
    font: normal 13px 'Inter';
  }
  input{
    padding: 0 15% 0 10px;
  }
  .errorMessage, .successMessage{
    font: normal 14px 'Inter';
    color: red;
  }
  .errorMessage{
    color: red;
  }
  .successMessage{
    color: ${theme.colors.green1};
  }

  @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
}
`

export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  >svg{
    position: relative;
    left: -10%;
    width: 42px;
    margin: 0 -100% 1% 0;
    transition: all .5s;

    @media(min-width: 1025px){
      :hover{
        cursor: pointer;
      }
    }
    @media(max-width: 414px){
      left: -15%;
    }
    @media(max-width: 320px){
      left: -17%;
    }
  }
`

export const ButtonBox = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.small};
  display: flex;
  justify-content: space-between;

  @media(max-width: 768px){
    width: 100%;
    flex-direction: column;
  }
`
export const ContainerMessages = styled.div`
  width: 50%;
  margin-bottom: 3%;

  @media(max-width: 768px){
      width: 100%;
  }
`
export const PasswordViewer = styled.div`
  position: relative;
  left: -10%;
  width: 42px;
  margin: 0 -100% 1% 0;
  transition: all .5s;
  @media(min-width: 1025px){
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 414px){
    left: -15%;
  }
  @media(max-width: 320px){
    left: -17%;
  }
`
export const PasswordChecks = styled.div`
  background-color: #E9ECF3;
  width: 100%;
  padding: 5px 24px;
  border-radius: 5px;

  label{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    font: normal 14px 'Inter';
    color: #6B7786;
  }

  @media(max-width: 414px){
    padding: 5px 15px;
  }
`
export const RoundedCheck = styled.div<IPasswordModalCheck>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: ${props => props.isChecked ? '#378FF7' : '#C1C4CC'};

  svg{
    height: 8px;
    width: 8px;
    
    & path {
      stroke: #fff;      
    }
  }
  transition: all .5s;
`
export const Inform = styled.div`
  @media(max-width: 768px){
    width: 100%;
  }
`
export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFE9E9;
  border-radius: 5px;
  padding: 2% 3%;
  line-height: 18px;
  text-align: center;

  @media(max-width: 768px){
    margin-bottom: 1%;
  }
`

export const SuccessMessage = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cae8cb;
  border-radius: 5px;
  padding: 3% 2%;
  line-height: 18px;
  text-align: center;

  @media(max-width: 768px){
    margin-bottom: 1%;
  }
`
