import React from 'react'

const LogoEinstein = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.3211 9.4088C23.998 9.01502 23.5941 8.69517 23.1368 8.4709C22.6794 8.24663 22.1793 8.12314 21.6701 8.10878L21.4641 8.06978C22.4464 9.47955 23.3209 10.9614 24.0801 12.5028C24.1531 12.3168 24.2271 12.1698 24.2641 12.0578C24.4755 11.6492 24.5904 11.1976 24.6 10.7377C24.6095 10.2778 24.5134 9.82178 24.3191 9.4048" fill="#005597"/>
    <path d="M13.6201 21.1478C14.1021 22.4088 14.6041 23.0398 15.2201 23.1698C15.9811 23.3188 16.9791 22.7008 18.4651 21.1478C18.8083 20.7897 19.1306 20.4121 19.4301 20.0168C17.4016 20.0806 15.3776 20.2475 13.3661 20.5168C13.4211 20.6288 13.6251 21.1468 13.6251 21.1468" fill="#005597"/>
    <path d="M10.2291 14.2118C11.1999 15.7245 12.0364 17.3192 12.7291 18.9778C12.9511 18.9408 13.1721 18.9228 13.3961 18.8858C15.7796 18.5402 18.1888 18.4037 20.5961 18.4778C21.7608 16.7959 22.8192 15.0428 23.7651 13.2288C22.7923 11.3749 21.6362 9.62318 20.3141 7.99978C18.0352 7.81918 15.7444 7.84998 13.4711 8.09179C13.0003 9.22215 12.4548 10.3199 11.8381 11.3778C11.2295 12.4364 10.6931 13.3811 10.2291 14.2118Z" fill="#005597"/>
    <path d="M24.4821 4.21478C22.7179 2.44473 20.5215 1.16673 18.1109 0.507563C15.7003 -0.151603 13.1593 -0.169028 10.7399 0.457026C8.32045 1.08308 6.10674 2.33087 4.31842 4.07656C2.5301 5.82225 1.22933 8.00517 0.545105 10.4088C4.19083 8.75608 8.05824 7.64437 12.0251 7.10879C12.3251 6.16179 12.5451 5.27177 12.7251 4.49377C13.1131 2.86277 13.3921 1.6938 14.1521 1.4718C14.9741 1.2488 16.2641 2.13879 18.6221 4.54879L20.4221 6.66079C21.3373 6.7208 22.2478 6.8387 23.1481 7.01379L23.2421 7.03277C23.8205 7.0849 24.382 7.25482 24.8923 7.53204C25.4025 7.80926 25.8506 8.18792 26.2091 8.64477C26.3945 9.03629 26.4906 9.46408 26.4906 9.89727C26.4906 10.3305 26.3945 10.7583 26.2091 11.1498C25.8011 12.2988 25.3561 13.3188 24.8931 14.3168C25.2908 15.2963 25.6313 16.2979 25.9131 17.3168C25.9181 17.3866 25.9306 17.4556 25.9501 17.5228C25.9577 17.6104 25.9467 17.6985 25.9179 17.7816C25.8891 17.8646 25.8432 17.9407 25.7831 18.0048L25.7101 18.0968L25.6711 17.9848C25.3767 16.9495 25.0174 15.9338 24.5951 14.9438C23.9769 16.1931 23.2575 17.3898 22.4441 18.5218C24.2307 18.6331 26.0007 18.9313 27.7251 19.4118C28.3436 17.7822 28.6643 16.0548 28.6721 14.3118C28.6681 10.5227 27.1604 6.89011 24.4801 4.21179" fill="#005597"/>
    <path d="M8.83709 18.6068C9.17009 19.1068 10.1171 19.2558 11.8981 19.0758C11.3325 17.6128 10.6641 16.1916 9.89812 14.8228C8.95312 16.6228 8.41513 17.9578 8.84313 18.6068" fill="#005597"/>
    <path d="M19.2231 6.62681C17.5171 4.60581 16.5331 3.82679 15.8481 3.93879C15.1481 4.04879 14.6751 5.13881 13.9931 6.81281L13.9741 6.8678C15.7166 6.67263 17.47 6.59213 19.2231 6.62681" fill="#005597"/>
    <path d="M21.3541 20.0148C20.5211 21.0538 19.5541 22.1268 18.3321 23.3908C16.5521 25.2258 15.5681 26.0608 14.7711 25.8538C14.2141 25.7068 13.8061 25.0748 13.4541 23.7538C13.1381 22.6618 12.8241 21.6028 12.4721 20.6208L11.1721 20.8058C8.23813 21.2318 6.61011 20.9918 6.03511 20.1058C5.21311 18.8058 6.73516 16.2848 8.83516 13.1138C8.36616 12.4278 7.87213 11.7788 7.35213 11.1298C7.30301 11.0789 7.25943 11.0229 7.22212 10.9628L7.14815 10.8708L7.26015 10.8528C7.35028 10.8456 7.44095 10.8565 7.52681 10.8848C7.61267 10.9132 7.69199 10.9584 7.76015 11.0178C8.22681 11.5198 8.68416 12.0578 9.13216 12.6318L9.33211 12.3158L10.2771 10.8878C10.8044 10.0675 11.2531 9.19936 11.6171 8.29478C6.25714 9.11578 2.41715 10.7578 0.786148 12.8708C0.451352 13.2759 0.214099 13.7525 0.092728 14.2638C-0.0286427 14.7751 -0.0308966 15.3075 0.0861362 15.8198C0.44013 19.1132 1.90567 22.1878 4.24117 24.5368C5.56528 25.8661 7.14105 26.9183 8.87642 27.6317C10.6118 28.3451 12.4719 28.7055 14.3482 28.6918C16.4154 28.6915 18.4582 28.2445 20.3367 27.3815C22.2152 26.5186 23.8851 25.2599 25.2321 23.6918C25.8811 22.8198 26.0851 22.0778 25.8081 21.5038C25.3991 20.6318 23.8962 20.1318 21.3562 20.0198" fill="#005597"/>
  </svg>
)

export default LogoEinstein
