import styled from 'styled-components'

export const Container = styled.div`
  padding: calc(120px + 40px) 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  > div:nth-child(1) {
    border: 4px solid #257CE5;
    border-radius: 200px;
    padding: 20px;
    margin-bottom: 30px;
    svg {
      color: #257CE5;
    }
  }
  button {
    width: 100%;
    @media (min-width: 415px) {
      width: 150px;
    }
  }
  * {
    font-family: Inter, sans-serif;
  }
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  @media (min-width: 415px) {
    font-size: 25px;
  }
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  margin-bottom: 20px;
  strong {
    font-weight: 500;
  }
`
