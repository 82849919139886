import styled from 'styled-components'

export const Mask = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: transparent;
  height: 100vh;
  width: calc(100vw - 367px);
  display: none;
  @media (min-width: 415px) {
    display: block;
  }
  @media (min-width: 750px) {
    width: calc(100vw - 350px);
  }
`
