import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getPlatformRoute, getPlatformIdByRoute } from '../../models/platformRoute'
import { setPreloading, setProgress } from '../../modules/session/sessionSlice'
import { selectContextSSO } from '../../modules/login/sessionSlice'
import LocalStorageManager from '../../helpers/localStorage'
import { loadDynatraceReporter, unloadDynatraceReporter } from '../../modules/dynatrace'

function MicroFrontend({ name, host, history, user }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const isContextSSO =
    LocalStorageManager.ReadEncryptedPlainText('is_context_sso-encrypted') ?? useSelector(selectContextSSO)

  useEffect(() => {
    dispatch(setProgress(30))

    const scriptId = `micro-frontend-script-${name}`
    const removeTagScript = document.getElementById(scriptId)

    if (removeTagScript) {
      removeTagScript.remove()
    }

    const renderMicroFrontend = () => {
      window[`render${name}`](`${name}-container`, history, user)

      if (!isContextSSO) {
        dispatch(setPreloading(false))
      }
    }

    fetch(`${host}/asset-manifest.json?t=${new Date().getTime()}`)
      .then(res => res.json())
      .then(manifest => {
        const script = document.createElement('script')
        script.id = scriptId
        script.crossOrigin = ''
        script.src = `${host}${manifest.files['main.js']}?t=${new Date().getTime()}`
        script.onload = () => {
          renderMicroFrontend()
          loadDynatraceReporter(name)
        }

        if (!isContextSSO) {
          dispatch(setProgress(100))
        }

        document.head.appendChild(script)
      })
      .catch(() => {
        const pathname = location.pathname.split('/')[1]
        const session = LocalStorageManager.ReadEncryptedData('session-encrypted')
        const userPlatforms = session?.user?.allPlatforms?.filter(
          platform => platform.id !== getPlatformIdByRoute(pathname)
        )
        const randomId = Math.floor(Math.random() * userPlatforms.length)

        if (userPlatforms) {
          window.location.href = getPlatformRoute(userPlatforms[randomId]?.id)
        }

        dispatch(setProgress(100))

        setTimeout(() => {
          dispatch(setPreloading(false))
        }, 1000)
      })

    return () => {
      dispatch(setPreloading(true))
      dispatch(setProgress(60))
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`)
      unloadDynatraceReporter()
    }
  }, [])

  return <main id={`${name}-container`} />
}

MicroFrontend.defaultProps = {
  document,
  window
}

export default MicroFrontend
