import styled, { css } from 'styled-components'

interface isLoading {
  isLoading: boolean
  invalidCode: boolean
}

export const AuthenticatorHeader = styled.div`
  text-align: center;
  font: normal normal 600 16px/24px Inter;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
`

export const AuthenticatorSubTitle = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  margin-top:8px;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
`

export const AuthenticatorContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`

export const Text = styled.p<isLoading>`
  font-size: 14px;
  color: #6d7785;
  margin: 15px 0;
  line-height: 1.4;

  ${({ isLoading, invalidCode }) => {
    if (isLoading && !invalidCode) {
      return css`
        display: flex;
        visibility: visible;
      `
    }

    if (!isLoading && invalidCode) {
      return css`
        display: none;
      `
    }

    return css`
      visibility: hidden;
    `
  }}
`

export const AuthenticatorFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  text-align: center;
  width: 100%;

  & button {
    width: 100%;
    max-width: 300px;
  }
`

export const AuthenticatorText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

    & p {
      font-size: 14px;
      margin: 8px 0px 3px;
      font: normal normal Inter;
      color: #3988E7;
      cursor: pointer
    }
`

export const AuthenticatorContainer = styled.div`
  margin: 40px 73px;
  width: 365px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 500px) {
    width: 100%;
    margin: auto;
  }
`

export const AuthenticatorInput = styled.input`
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  width: 43px;
  height: 45px;
  text-align: center;
  font-size: 16px;
  color: #2e384d;
  transition: border 0.2s ease-in;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid #378ff7;
  }
`

export const AuthenticatorInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`
