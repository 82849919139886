export enum EModulesIds {
  SUPPORT_DECISION_MODULE_ID = 1,
  INTEGRATED_CARE_MODULE_ID = 2,
  VIDEO_CALL_MODULE_ID = 3,
  CALENDAR_MODULE_ID = 4,
  AUTO_FILL_MODULE_ID = 6,
  MYELOMA_MODULE_ID = 7,
  MIGRATION_MODULE_ID = 11,
  CONFIGURATION_MODULE_ID = 19,
  CALENDAR_CONFIG_MODULE_ID = 25,
  CHAT_CONFIG_MODULE_ID = 26,
  CERTIFICATION_VIDAAS = 35,
}
