import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #F3F5F9;
  min-height: calc(100vh - 80px);
  @media(max-width: 768px){
    flex-direction: column;
  }
`
