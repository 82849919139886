import React from 'react'

const Danger = ({ height, width }: React.SVGProps<SVGSVGElement>) => (
  <svg id="Icon_Danger" data-name="Danger" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 61 56">
    <g id="Group">
      <path id="Fill_5" data-name="Fill 5" d="M8.305,56c-.04,0-.076,0-.119,0a8.533,8.533,0,0,1-2.8-.626A8.765,8.765,0,0,1,.634,44.006L22.964,4.468A8.592,8.592,0,0,1,38.049,4.5L60.232,43.731a8.93,8.93,0,0,1,.756,3.1,8.726,8.726,0,0,1-2.211,6.295A8.529,8.529,0,0,1,52.811,56L8.525,56Z" transform="translate(0 0)" fill="#b9c2ce" opacity="0.4"/>
      <path id="Combined_Shape" data-name="Combined Shape" d="M0,21.962a2.7,2.7,0,0,1,2.668-2.713,2.705,2.705,0,0,1,0,5.41A2.689,2.689,0,0,1,0,21.962ZM0,11.414V2.7A2.689,2.689,0,0,1,2.668,0,2.687,2.687,0,0,1,5.336,2.7v8.717a2.686,2.686,0,0,1-2.668,2.7A2.688,2.688,0,0,1,0,11.414Z" transform="translate(27.831 18.943)" fill="#b9c2ce"/>
    </g>
  </svg>
)

export default Danger
