import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '../store/store'
import { getPlatformRoute, getOfficeRoute } from '../../models/platformRoute'

interface SelectedPlatform {
    id?: number
    name?: string
    miniLogo?: string
    isOffice?: boolean
    internal?: boolean
}

interface SelectPlatformInterface {
	currentPlatform: SelectedPlatform
	errors: any | null
	loading: 'failing' | 'success' | 'pending' | 'idle'
    preloading: boolean
    progress: number
}

export const selectPlatformSlice = createSlice<
    SelectPlatformInterface,
    SliceCaseReducers<SelectPlatformInterface>
>({
    name: 'currentPlatform',
    initialState: {
		loading: 'idle',
        preloading: true,
        progress: 0,
		errors: null,
        currentPlatform: {}
	},
    reducers: {
        currentPlatform: (state, action) => {
            state.currentPlatform = action.payload
            state.loading = 'success'
        },
        preloading: (state, action) => {
			state.preloading = action.payload
		},
        progress: (state, action) => {
            state.progress = action.payload
        }
    }
})

const {
    currentPlatform,
    preloading,
    progress
} = selectPlatformSlice.actions

export const setCurrentPlatform = (platform: SelectedPlatform) => (dispatch: Function) => {
    if (platform.isOffice) {
        return dispatch(currentPlatform({
            ...platform,
            route: getOfficeRoute
        }))
    }
    return dispatch(currentPlatform({
        ...platform,
        route: getPlatformRoute(platform.id ?? null)
    }))
}

export const setPreloading = (isActive: boolean) => (dispatch: Function) => dispatch(preloading(isActive))

export const setProgress = (currentProgress: number) => (dispatch: Function) => dispatch(progress(currentProgress))

export const getCurrentPlatform = (state: RootState): SelectedPlatform =>
	state.selectPlatform.currentPlatform

export const getPreloading = (state: RootState): boolean =>
	state.selectPlatform.preloading

export const getProgress = (state: RootState): number =>
	state.selectPlatform.progress
