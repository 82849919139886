import { theme } from '@cockpit/zera'
import styled from 'styled-components'

interface IPasswordModalCheck {
  isChecked: boolean;
}

export const Container = styled.div`
  margin: 65px 0 50px 406px;
  width: 468px;
  form{
    display: flex;
    flex-direction: column;
    span{
      font: normal 14px 'Inter';
      color: #545E6C;
      margin-bottom: 10px;
    }
    button{
      align-self: flex-end;
      min-width: 100px;

      :disabled{
        cursor: default;
      }
    }
  }
  @media(max-width: 1024px){
    margin: 65px 0 30px 336px;
  }
  @media(max-width: 768px){
    margin: 55px 0 30px 10%;
    width: 70%;
  }
  @media(max-width: 414px){
    width: 80%;
    margin: 25px auto 25px auto;
  }
`

export const Title = styled.div`
  margin: 0 0 30px 0;
  font: 600 22px 'Inter';
  color: #000000;
`

export const SubTitle = styled.div`
  margin: 0 0 15px 0;
  font: 700 14px 'Inter';
  color: #6B7786;
  height: 30px;
  border-bottom: 1px solid #E0E3E7;
`

export const PasswordChecks = styled.div`
  background-color: #E9ECF3;
  width: 100%;
  padding: 5px 24px;
  border-radius: 5px;
  label{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    font: normal 14px 'Inter';
    color: #6B7786;
  }

  @media(max-width: 414px){
    padding: 5px 15px;
  }
`
export const RoundedCheck = styled.div<IPasswordModalCheck>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${props => props.isChecked ? '#378FF7' : '#C1C4CC'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  svg{
    height: 8px;
    width: 8px;
  }
  transition: all .5s;
`

export const InputBox = styled.div`
  margin: 20px 0;

  label{
    font: normal 13px 'Inter';
  }
  input{
    padding: 0 15% 0 10px;
  }
  .errorMessage, .successMessage{
    font: normal 14px 'Inter';
  }
  .errorMessage{
    color: red;
  }
  .successMessage{
    color: ${theme.colors.green1};
  }
`

export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  >svg{
    position: relative;
    left: -10%;
    width: 42px;
    margin: 0 -100% 1% 0;
    transition: all .5s;
    @media(min-width: 1025px){
      :hover{
        cursor: pointer;
      }
    }
    @media(max-width: 414px){
      left: -15%;
    }
    @media(max-width: 320px){
      left: -17%;
    }
  }
`

export const PasswordViewer = styled.div`
  position: relative;
  left: -10%;
  width: 42px;
  margin: 0 -100% 1% 0;
  transition: all .5s;
  @media(min-width: 1025px){
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 414px){
    left: -15%;
  }
  @media(max-width: 320px){
    left: -17%;
  }
`
