/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/dot-notation */
import LocalStorageManager from '../../helpers/localStorage'

const unloadDynatraceReporter = (): void => {
  const scripts = document.getElementsByTagName('script')
  /* <-- Variaveis globais de referencia, utilizadas pelo Dynatrace -->
  delete window['dtrum' as keyof typeof window]
  delete window['dynatrace' as keyof typeof window]
  */
  // eslint-disable-next-line no-var
  for (var i = 0; i < scripts.length; i++) {
    const scriptId = scripts[i]?.id
    if (scriptId?.startsWith('dynatrace')) {
      scripts[i].remove()
    }
  }
}

const loadDynatraceReporter = (appName: string): void => {
  const dynatraceUrl = process.env[`REACT_APP_DYNATRACE_${appName.toUpperCase()}_URL`]

  if (dynatraceUrl && !(window as any).dtrum) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = dynatraceUrl
    script.id = `dynatrace-${appName}`
    script.crossOrigin = 'anonymous'
    document.head.appendChild(script)

    script.onload = () => {
      const session = LocalStorageManager.ReadEncryptedData('session-encrypted') as any
      if (session?.user) {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user: { name, personal_document, health_professional_data }
        } = session
        const professionalMetadata = health_professional_data?.class_codes?.[0]
        const userUid = `${
          personal_document?.classification === 'CPF' ? `${personal_document.identifier} - ` : ''
          }${name}${professionalMetadata ? ` - ${professionalMetadata.description}${professionalMetadata.code}` : ''}`
          ;(window as any).dtrum.identifyUser(userUid)
      }
    }
  }
}

export { loadDynatraceReporter, unloadDynatraceReporter }
