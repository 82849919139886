import { AxiosHttpClient } from '../../../src/utils/axiosClient'

export interface NewObject {
	account_id: number
	platforms: NewObjectPlatforms[]
}

interface NewObjectPlatforms { id: number; features: Module[] }

export interface Module {
	id: string
	permissions: number
	requireCertificate: boolean
}

export interface AccessToObjectParams {
  axiosClientHttp: AxiosHttpClient
	headers: {
		authorization: string
	}
	url: string
}

export interface axiosData {
  data: NewObject
}

export interface AccessResponseError {
	response: {
		statusCode: number
		message: string
		classError: string
	}
}

export class PermissionsObject {
  async getUserPermissions({ axiosClientHttp, headers, url }: AccessToObjectParams): Promise<any> {
    return axiosClientHttp
    .request<NewObject>({
      url,
      headers: headers,
      method: 'get'
    })
    .then((response) => {
      if (response.statusCode === 200) return response.body
      const responseError = (response.body as unknown as AccessResponseError).response
      if (response.statusCode === 404) {
        return {
          statusCode: responseError.statusCode,
          message: responseError.message
        }
      }
      return {
        statusCode: 500,
        message: 'Internal Server Error'
      }
    }).catch(e => {
      return e
    })
  }
}
