import React from 'react'
import ReactDOM from 'react-dom'
import { Modal } from '@cockpit/zera'
import { ModalProps } from '@cockpit/zera/dist/Modal/Modal'

export interface PortalModalProps extends ModalProps {
  children: React.ReactNode
}
const PortalModal: React.FC<PortalModalProps> = ({ children, ...props }: PortalModalProps): JSX.Element => {
  const root = document.getElementById('root')
  return root ? ReactDOM.createPortal(<Modal {...props}>{children}</Modal>, root) : <Modal {...props}>{children}</Modal>
}
export default PortalModal
