import LocalStorageManager from '../../helpers/localStorage'

interface IOffice {
    id: number
    name: string
    platformId?: number
    internal: boolean
    address?: any
}

const writeOfficeLocalStorage = (office: IOffice): void => {
    LocalStorageManager.WriteEncryptedData('office-encrypted', {
        isOffice: true,
        platformId: office?.platformId,
        isInternal: office.internal,
        id: office.id,
        name: office.name,
        address: office.address
    })
}

export default writeOfficeLocalStorage
