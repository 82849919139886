import styled, { css } from 'styled-components'

interface IsError {
  isError: boolean
  invalidEmail: boolean
}

export const Container = styled.div<Pick<IsError, 'isError'>>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 3.5rem 6.0625rem;

  ${({ isError }) => isError ? css`
	  margin-top: 2.5rem;

    h1 {
      margin-bottom: 1.5rem;
      transition: all 0.5s;
    }
  ` : css`
	  margin-top: 3.5rem;

    h1 {
      margin-bottom: 2rem;
      transition: all 0.5s;
    }
  `}

  @media (max-width: 500px) {
    height: 100%;
    margin: calc(3.5rem - 3rem) 0 0;
  }

  @media (max-width: 590px) and (min-width: 500px){
	  margin: 3.5rem 4.0625rem;
  }
`

export const ResendCodeButton = styled.button`
  color: #ccc;
  font-weight: 500;
  border: 0;
  background: transparent;
  margin-top: 8px;
  cursor:   'not-allowed';
`

export const ModalTitle = styled.h1`
	text-align: center;
	font-size: 1.25rem;
	font-weight: 500;
	color: #2D3139;

  @media (max-width: 500px) {
    font-size: calc(1.25rem + 1px);
  }
`

export const InputForm = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  width: 100%;

	& button {
		width: 18.75rem;
	}
`

export const InputBox = styled.div<IsError>`
	display: flex;
  flex-direction: column;
  margin: 0 0 0.8125rem;

	div {
    justify-content: center;
	  align-items: center;
		width: 18.75rem;
		margin: 0 auto;
		box-shadow: none;
	}
	
	& input {
		padding: 0 1rem;
		height: 2.8125rem;
		width: 100%;
		font-weight: normal;

		&::placeholder {
			color: #6D7785;
      font-weight: 100;
		}
	}

  ${({ isError }) => isError ? css`
      margin-bottom: 0.5rem;

      & input {
        border-color: #F82425;
      }

      & span {
        display: none;
      } 
    ` : css`
      margin-bottom: 0.8125rem;

      & input {
        border-color: none;
      }

      & span {
        display: flex;
      } 
  `}

  ${({ invalidEmail }) => invalidEmail ? css`
      & input {
        border-color: #F82425;
      }

      & span {
        visibility: visible;
      } 
    ` : css`
      & span {
        visibility: hidden;
      }

      & input {
        border-color: none;
      }
  `}
`

export const InvalidEmail = styled.span`
  color: #FF2424;
  font-size: 0.75rem;
  margin: 0.245rem 0;
  visibility: hidden;
`

export const ContainerConfirmed = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;
  text-align: center;
	padding: 4rem 3rem;
  /* margin: 3.5rem 3rem; */
  width: 494px;

  transition: all 0.8s;

  @media (max-width: 500px) {
    height: 100%;
    width: 100%;
    margin-top: calc(3.5rem - 3rem);
    padding-top: 0;
  }

  @media (min-width: 500px) and (max-width: 590px) {
    width: 100%;
	  padding: 4rem 0;

    & p {
      padding: 0 1rem;
    }
  }
`

export const ConfirmationTitle = styled.h1`
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #545E6C;

  margin: 1rem 0;

  @media (max-width: 500px) {
    color: #2D3139;
  }
`

export const Text = styled.p`
  font-size: 1rem;
  color: #545E6C;
  line-height: 1.4;
  font-weight: 100;
`
