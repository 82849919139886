import { updateUser } from './updateUser.api'

export const UpdateUser = async (userId: number, requestData: object, token: string): Promise<any> => {
  return updateUser.put(userId, requestData, token).catch(async (e: any) => {
    return Promise.reject(e)
  })
}

export const updateUserService = {
  UpdateUser
}
