import activeDirectory from './templates/active-directory.json'
import congratsLinked from './templates/congrats-linked.json'
import defaultPlatform from './templates/default-platform.json'
import login from './templates/login.json'
import modal from './templates/modal.json'
import incorrectPassword from './templates/incorrect-password.json'
import blockedAccess from './templates/blocked-access.json'
import selectPlatformOrOffice from './templates/select-platform.json'
import manual from './templates/manual.json'
import forgotPassword from './templates/forgot-password.json'
import errorModal from './templates/error-modal.json'

const eTemplates: {
  [key: string]: string
} = {
  activeDirectory: 'activeDirectory',
  congratsLinked: 'congratsLinked',
  defaultPlatform: 'defaultPlatform',
  login: 'login',
  modal: 'modal',
  incorrectPassword: 'incorrectPassword',
  blockedAccess: 'blockedAccess',
  selectPlatformOrOffice: 'selectPlatformOrOffice',
  manual: 'manual',
  forgotPassword: 'forgotPassword',
  errorModal: 'errorModal'
}

const templates: {
  [key: string]: {
    [key: string]: string
  }
} = {
  activeDirectory,
  congratsLinked,
  defaultPlatform,
  login,
  modal,
  incorrectPassword,
  blockedAccess,
  selectPlatformOrOffice,
  manual,
  forgotPassword,
  errorModal
}

export type IFunctionMessage = (value: string) => string

/**
 *
 * @param {string} value value to pass, it is the path of the text in the json file
 * @returns {string} return the string matched with the path passed
 */
export const message: IFunctionMessage = (value: string): string => {
  const firstPath = value.split('.').shift() ?? ''

  if (!eTemplates[firstPath]) return ''

  const secondPath = value.split('.').pop() ?? ''

  return templates[firstPath][secondPath] ?? ''
}
