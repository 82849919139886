import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import DefaultLayout from '../components/_layouts/default/DefaultTemplates'
import { readLocalStorage, deleteLocalStorage } from '../modules/local-storage/local-storage.service'
import { SessionData } from '../modules/login/Session'
import { selectUserSession, sessionUser, setIsContextSSO } from '../modules/login/sessionSlice'
import { sessionValidation, withoutTheRouteParameter } from '../shared/functions/sessionValidation'
import Chat from '../pages/Chat'
import { Login } from '../pages/Login/Login'
import { cclParamsHelper } from './cclParamsHelper'
import LocalStorageManager from '../helpers/localStorage'

interface RouteWrapperProps extends RouteProps {
  protectedRoute: boolean
  guard?: Function
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  protectedRoute,
  guard,
  ...rest
}: any) => {
  // TODO: Talvez transformar esse bloco em um Hook!
  // TODO: É um problema essa bloco em todas as rotas?

  if (guard) {
    guard()
  }
  const dispatch = useDispatch()

  // Garante recuperação dos dados do storage ao atualizar a página
  // também salva os dados da sessão no storage do redux

  let sessionData: SessionData | null = null
  const userSession: SessionData | null = useSelector(selectUserSession)
  if (!userSession) {
    sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
    if (sessionData) {
      dispatch(sessionUser(sessionData))
    }
  } else {
    sessionData = userSession
  }

  const isContextSSO = readLocalStorage('is_context_sso')

  if (isContextSSO) {
    dispatch(setIsContextSSO(true))
    deleteLocalStorage('is_context_sso')
    deleteLocalStorage('is_context_sso-encrypted')
  }

  return (
    <>
      <Route
        {...rest}
        render={(routeProps: RouteProps): any => {
          return sessionData && protectedRoute && sessionValidation() ? (
            <DefaultLayout>
              <Component {...routeProps} />
            </DefaultLayout>
          ) : (
            <Redirect to="/" />
          )
        }}
      />
      <Chat />
    </>
  )
}

export const PlatformOrLogin: React.FC<RouteProps> = ({ ...rest }) => {
  const result = !cclParamsHelper() && sessionValidation()

  return result ? <Redirect to={{ pathname: withoutTheRouteParameter(), state: { from: rest.location } }} /> : <Login />
}
