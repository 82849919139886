import { SessionData, OfficeLocalStorage } from '../../modules/login/Session'
import { getPlatformRoute, getOfficeRoute, getOncologyPlatformRoute } from '../../models/platformRoute'
import writeOfficeLocalStorage from './writeOfficeInLocalStorage'

import ENV from '../../modules/env'
import LocalStorageManager from '../../helpers/localStorage'
import { Platform } from '../../modules/login/Platform'

export type IWithoutTheRouteParameter = (userSession?: SessionData | null, queryParamsSSO?: {
  patientEpisodeNumber?: string | null,
  target?: string | null,
  resident?: string | null
}) => void

/**
 * Tem como função pegar e setar uma plataforma ou consultório para o usuário quando é chamado apenas o caminho raiz /
 * ou pega uma plataforma ou consultório default do usuário
 */
export const withoutTheRouteParameter: IWithoutTheRouteParameter = (userSession, queryParamsSSO) => {
  const sessionData = userSession ?? LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
  const platforms = sessionData?.user?.allPlatforms
  const defaultPlatform = sessionData?.user?.access?.find(a => a.default_platform_id)?.default_platform_id
  const defaultOffice = sessionData?.user?.access?.find(a => a.default_office_id)?.default_office_id
  const randomPlatform = platforms?.find((platform) =>
    !platform.access_only_to_offices
  )?.id
  const officeExist = LocalStorageManager.ReadEncryptedData('office-encrypted') as OfficeLocalStorage

  const randomOffice = sessionData?.user?.allPlatforms?.find((platform) =>
    platform.offices.length > 0
  )

  const getOncology = sessionData?.user?.allPlatforms?.find((platform) =>
    platform.id === ENV.ONCO_FRAMEWORK_PLATFORM_ID
  )

  const getCorpoClinico = sessionData?.user?.allPlatforms?.find((platform) =>
    !platform.access_only_to_offices && platform.id === ENV.CLINICAL_STAFF_PLATFORM_ID
  )

  if (queryParamsSSO?.patientEpisodeNumber && queryParamsSSO.target === 'oncology' && getOncology) {
    return getOncologyPlatformRoute(
      getOncology.id,
      {
        patientEpisodeNumber: queryParamsSSO.patientEpisodeNumber,
        resident: queryParamsSSO.resident
      }
    )
  }

  if (queryParamsSSO?.patientEpisodeNumber && getCorpoClinico) {
    return getPlatformRoute(
      getCorpoClinico.id,
      {
        patientEpisodeNumber: queryParamsSSO.patientEpisodeNumber,
        target: queryParamsSSO.target
      }
    )
  }

  if (officeExist) {
    return getOfficeRoute
  }

  if (defaultPlatform) {
    return getPlatformRoute(defaultPlatform)
  }

  const uniquePlatforms: Platform[] = []
  platforms?.map(platform => {
    if (!uniquePlatforms?.find(p => p?.id === platform?.id)) {
      uniquePlatforms.push(platform)
    }
  })

  const accessOnlyToOffices = uniquePlatforms.some(plat => plat?.access_only_to_offices)

  if (!defaultPlatform && uniquePlatforms.length === 1 && !accessOnlyToOffices) {
    return getPlatformRoute(uniquePlatforms[0].id)
  }

  if (defaultOffice) {
    sessionData?.user?.allPlatforms?.forEach((getDefaultOffice) => {
      const getOffice = getDefaultOffice?.offices.find(office => office.id === defaultOffice)

      if (getOffice) {
        writeOfficeLocalStorage({
          id: getOffice.id,
          name: getOffice.name,
          platformId: getDefaultOffice.id,
          internal: getOffice.internal,
          address: getOffice?.address
        })
      }
    })

    return getOfficeRoute
  }

  if (randomPlatform) return getPlatformRoute(randomPlatform)

  if (randomOffice) {
    const getRandomOffice = randomOffice.offices[0]
    if (getRandomOffice) {
      writeOfficeLocalStorage({
        id: getRandomOffice.id,
        name: getRandomOffice.name,
        platformId: randomOffice.id,
        internal: getRandomOffice.internal,
        address: getRandomOffice?.address
      })
    }

    return getOfficeRoute
  }
}
/**
 * Utilizado para verificar se a sessão do usuário está correta. Se por algum motivo foi perdido algum dado importante na sessão, essa  função serve para resetar a mesma!
 *
 * @param userSession
 * @returns retorna se a sessão está valida ou não
 */
export const sessionValidation = (userSession?: SessionData, logoutCallback?: Function): boolean | null => {
  try {
    let sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
    if (userSession) {
      sessionData = userSession
    }

    if (!sessionData) return null

    const platforms = sessionData?.user?.allPlatforms
    const defaultPlatform = sessionData?.user?.access?.find(a => a.default_platform_id)?.default_platform_id
    const defaultOffice = sessionData?.user?.access?.find(a => a.default_office_id)?.default_office_id
    const isSSOActive = sessionData?.user?.episode_number

    const userHasAccessPlatform = platforms?.some((platform) => !platform.access_only_to_offices)
    const userHasAccessDefaultPlatform = platforms?.some(
      (platform) => platform.id === defaultPlatform && !platform.access_only_to_offices
    )
    const userHasAccessDefaultOffice = platforms?.some(
      (platform) => platform.offices.some((office) => office.id === defaultOffice)
    )
    const userHasAccessOffice = platforms?.some((platform) => platform.offices.length > 0)

    const userWithEmptyOffices = platforms?.some((platform) => !platform.offices.length && platform.access_only_to_offices)

    if (isSSOActive) {
      return true
    }

    if (
      !!(!userHasAccessPlatform && !userHasAccessOffice) ||
      !sessionData ||
      !!(defaultPlatform && !userHasAccessDefaultPlatform) ||
      !!(defaultOffice && !userHasAccessDefaultOffice) ||
      userWithEmptyOffices
    ) {
      return false
    }

    return true
  } catch (error) {
    if (logoutCallback) logoutCallback()
    return false
  }
}
