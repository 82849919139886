import { User } from '../../../modules/login/User'
import { ACCESS_MANAGER_PLATFORM_TAG } from '../../../enums'
import { IGetPlatformsAndOffices, IPlatformOrOfficeList } from './interfaces'
import { Platform } from '../../../modules/login/Platform'

export const getPlatformsAndOffices: IGetPlatformsAndOffices = (userSession) => {
  const user: User | null = userSession?.user ?? null
  const filteredPlatforms: Platform[] = []
  user?.allPlatforms?.filter(platform => (platform.tag === ACCESS_MANAGER_PLATFORM_TAG)).map(platform => {
    if (!filteredPlatforms.find(p => p.id === platform.id)) {
      filteredPlatforms.push(platform)
    }
  })

  const platformOrOfficeList: IPlatformOrOfficeList[] = filteredPlatforms?.length
    ? filteredPlatforms.filter(platform => !platform.access_only_to_offices).map(platform => ({
      id: platform.id,
      name: platform.description.split('Cockpit')[1]
        ? platform.description.split('Cockpit')[1].trim()
        : platform.description,
      isOffice: false,
      platformId: platform.id,
      isInternal: true
    })
    ) : []

  filteredPlatforms
    ?.forEach((platformAndOffice) => {
      platformOrOfficeList?.push(...platformAndOffice?.offices.map(office => ({
        id: office.id,
        name: office.name,
        isOffice: true,
        platformId: platformAndOffice.id,
        isInternal: office.internal,
        address: office.address
      })))
    }
    )

  return [
    platformOrOfficeList
  ]
}
