import styled from 'styled-components'
import { IconExporter } from '@cockpit/zera'

export const ModalContainer = styled.div`
  div {
    z-index: 10011;
  }
`
interface colorSvg {
  color?: string
}

export const FeedbackIcon = styled(IconExporter)<colorSvg>`
  color: #b9c2ce;
`

export const Title = styled.h1<{ isSemiBold: boolean }>`
  font: ${props => (props.isSemiBold ? 600 : 500)} 20px 'Inter';
  color: #545e6c;
  text-align: center;
  margin: 16px 75px 0px 75px;
`

export const SubTitle = styled.h2`
  color: #707070;
  font: 400 16px 'Inter';
  text-align: center;
  margin: 8px 75px 0px 75px;

  @media (max-width: 500px) {
    margin: 8px 25px 8px 25px;
  }
`

export const SupportText = styled.p`
  color: #707070;
  font: 400 14px 'Inter';
  text-align: center;
  width: 430px;
  margin: 24px auto 16px auto;

  a {
    color: #257ce5;
  }

  @media (max-width: 500px) {
    width: calc(100% - 50px);
  }
`

export const SupportContact = styled.p`
  color: #707070;
  font: 400 14px 'Inter';
  text-align: center;
  width: 430px;
  margin: 0px auto 0px auto;
  + p {
    margin-top: 4px;
  }
  span {
    font-weight: 600;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    margin-top: 16px;
    width: calc(100% - 50px);
  }
`

export const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  min-height: 200px;
  margin-top: -20px;

  > button {
    width: 206px;
    margin: 0 auto;
  }

  @media (max-width: 500px) {
    padding: 0px 0px 12px 0px;
    margin-top: 75px;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 16px;
      width: 70%;
      margin: 16px 24% 0 24%;
    }
    > button {
      width: calc(100% - 48px);
      margin: 0 auto;
      position: fixed;
      bottom: 12px;
    }
  }
`
