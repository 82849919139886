import styled from 'styled-components'

export const Dialog = styled.dialog`
  height: 90vh;

  margin: auto;

  padding: 16px;

  border: none;
  border-radius: 13px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  font-family: 'Inter', sans-serif;
  background: transparent;

  outline: none;
  z-index: 1;

  @media only screen and (min-width: 600px) {
    width: 544px;
  }
`

export const DialogWrapper = styled.div`
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.7);

  border-radius: 8px;

  background-color: #fff;
`

export const ImageContainer = styled.div`
  width: 100%;

  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(#dfecfb 70%, transparent 0%) no-repeat;

  > svg {
    margin-top: 20px;
  }
`

export const ContentContainer = styled.div`
  padding: 40px 24px;

  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  line-height: 24px;
`

export const Title = styled.h1`
  font-weight: 600;
  color: #16171a;
`

export const Text = styled.p`
  color: #363940;
`

export const ActionArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button`
  width: 65%;

  padding: 12px;

  border: none;
  border-radius: 6px;

  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #ffffff;
  background-color: #1c7cf9;

  cursor: pointer;

  transition: 0.4;
  :hover {
    filter: brightness(120%);
  }
`
