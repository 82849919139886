import React from 'react'

import { Button, IconExporter } from '@cockpit/zera'
import { ResponsiveModal } from '../../../shared/ResponsiveModal'

import {
  Container,
  Title,
  Description
} from './styles'

import { message } from '../../../messages'

interface Props {
  showModal: boolean
  onClose: () => void
}

export const CongratsLinked: React.FC<Props> = ({
  showModal,
  onClose
}) => {
  return (
    <ResponsiveModal
      show={showModal}
      closeOnClickMask={false}
      onClose={onClose}
      padding='0 42px 40px'
      hideCloseIcon
    >
      <Container>
        <IconExporter name='check' iconsize={35} />
        <Title>{message('congratsLinked.congrats')}</Title>
        <Description>
          {message('congratsLinked.description')}
        </Description>
        <Button type="button" onClick={onClose}>
          {message('congratsLinked.confirm')}
        </Button>
      </Container>
    </ResponsiveModal>
  )
}
