import styled from 'styled-components'

export const StyledHeader = styled.header`
  height: 64px;
  background: #f3f5f9 0% 0% no-repeat padding-box;
  padding-left: 56px;
  padding-right: 40px;
  border-bottom: 1px solid #dfe3e8;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* @media (min-width: 1000px) {
    width: calc(100% - 70px);
    padding-left: 0px;
  } */
`

export const UserMenuContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
