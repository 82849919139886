import React from 'react'
import { useDispatch } from 'react-redux'
import { ResponsiveModal } from '../../shared/ResponsiveModal'
import { logoutThunk } from '../../modules/login/sessionSlice'
import { PasswordModalBody } from './components/PasswordModalBody'
import useResize from '../_layouts/useResize'

export const PasswordModal: React.FC<{
	oldPassword: string
	showPasswordModal: boolean
	setShowPasswordModal: Function
}> = ({ oldPassword, showPasswordModal, setShowPasswordModal }) => {
	const dispatch = useDispatch()
	const windowSize = useResize()

	const onCloseModal = () => {
		setShowPasswordModal(false)
		dispatch(logoutThunk())
	}

	const isMobile = windowSize < 500

	return (
		<ResponsiveModal
			show={showPasswordModal}
			onClose={onCloseModal}
			padding='40px 42px'
			backgroundColor={isMobile ? '#F8F9FC' : '#FFF'}
		>
			<PasswordModalBody
				oldPassword={oldPassword}
				setShowPasswordModal={setShowPasswordModal}
			/>
		</ResponsiveModal>
	)
}
