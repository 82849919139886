import React from 'react'
import { Authenticator } from './components'
import { AuthenticatorPropsInterface, clickOrigin } from './interfaces'
import { useDispatch } from 'react-redux'
import { logoutThunk } from '../../modules/login/sessionSlice'
import { ResponsiveModal } from '../../shared/ResponsiveModal'
import useResize from '../_layouts/useResize'
import { message } from '../../messages'

export const AuthenticatorModal: React.FC<{
  showModal: boolean
  onClose: () => void
  userName: string
  origin?: clickOrigin
}> = ({ showModal, onClose, userName, origin }) => {
  const dispatch = useDispatch()
  const windowSize = useResize()

  const onCloseModal = () => {
    onClose()
    if (origin === 'header') return
    dispatch(logoutThunk())
  }

  const authenticatorProps: AuthenticatorPropsInterface = {
    title: origin ? message('modal.digitalCertificate') : `${message('modal.hello')}, ${userName}!`,
    subTitle: origin ? message('modal.birdIdHeaderText') : message('modal.pleaseEnterYourBirdIDToken'),
    footer: {
      button: {
        text: message('modal.confirm')
      }
    }
  }

  return (
    <ResponsiveModal
      show={showModal}
      closeOnClickMask={false}
      onClose={onCloseModal}
      padding='0'
      backgroundColor={(windowSize < 500) ? '#F8F9FC' : '#FFF'}
    >
      <Authenticator authenticatorProps={authenticatorProps} origin={origin}/>
    </ResponsiveModal>
  )
}
