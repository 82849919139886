import { CryptoManager, LeviCryptoManagerSecret } from '@cockpit/levi-ui'

export type localStorageDataType =
  | 'session'
  | 'verifica_token'
  | 'token'
  | 'verifica_token_expires_in'
  | 'verifica_token_expiration_date'
  | 'access_token_expires_in'
  | 'office'
  | 'logged'
  | 'is_context_sso'
  | 'permissions'
  | 'session-encrypted'
  | 'permissions-encrypted'
  | 'verifica_token-encrypted'
  | 'token-encrypted'
  | 'verifica_token_expires_in-encrypted'
  | 'verifica_token_expiration_date-encrypted'
  | 'verifica_token_type-encrypted'
  | 'access_token_expires_in-encrypted'
  | 'office-encrypted'
  | 'logged-encrypted'
  | 'is_context_sso-encrypted'
  | 'episode_number-encrypted'
  | 'target-encrypted'
  | 'current-platform-encrypted'

interface LocalStorageManagerUtilities {
  /* eslint-disable @typescript-eslint/method-signature-style */
  WriteEncryptedData<T>(key: localStorageDataType, obj?: T | null): void
  ReadEncryptedData<T>(key: localStorageDataType): T | null | string
  ReadEncryptedPlainText<T>(key: localStorageDataType): T | null | string | number
}

class LocalStorageUtils extends CryptoManager implements LocalStorageManagerUtilities {
  public WriteEncryptedData<T>(key: localStorageDataType, obj?: T | null): void {
    const keyAsString = this.KeyAsString(key)
    localStorage.setItem(keyAsString, this.encrypt(obj))
  }

  public ReadEncryptedData<T>(key: localStorageDataType): T | null {
    const keyAsString = this.KeyAsString(key)
    const encryptedData = localStorage.getItem(keyAsString)
    if (!encryptedData) {
      return null
    }
    const obj = this.decryptObject<T>(encryptedData.replace(/"/g, ''))
    return obj
  }

  public ReadEncryptedPlainText<T>(key: localStorageDataType): T | null | string | number {
    const keyAsString = this.KeyAsString(key)
    const encryptedData = localStorage.getItem(keyAsString)
    if (!encryptedData) {
      return null
    }

    return this.decryptPlainText(encryptedData)
  }

  private KeyAsString(key: localStorageDataType): string {
    return key as string
  }
}

const LocalStorageManager = new LocalStorageUtils(LeviCryptoManagerSecret)

export default LocalStorageManager
