import React from 'react'

function CockpitNews() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="181" fill="none" viewBox="0 0 220 181">
      <g clipPath="url(#clip0_1_51121)">
        <path
          fill="#fff"
          d="M143.041 135.177c-30.15 0-54.679-24.522-54.679-54.664 0-30.142 24.529-54.665 54.679-54.665 3.188 0 6.369.276 9.509.824a.298.298 0 01.196.124.306.306 0 01.05.226.31.31 0 01-.042.11.304.304 0 01-.308.135 54.625 54.625 0 00-9.405-.815c-29.817 0-54.075 24.252-54.075 54.06 0 29.81 24.258 54.061 54.075 54.061 29.817 0 54.075-24.251 54.075-54.06a53.919 53.919 0 00-14.106-36.414.3.3 0 01.02-.425.303.303 0 01.426.018 54.52 54.52 0 0114.264 36.82c0 30.143-24.529 54.665-54.679 54.665z"
        ></path>
        <path
          fill="#1774DE"
          d="M167.15 44.596c5.975 0 10.819-4.842 10.819-10.815s-4.844-10.816-10.819-10.816c-5.974 0-10.818 4.843-10.818 10.816 0 5.973 4.844 10.815 10.818 10.815z"
        ></path>
        <path
          fill="#fff"
          d="M165.675 39.41a1.207 1.207 0 01-.963-.481l-2.951-3.935a1.196 1.196 0 01-.229-.892 1.201 1.201 0 012.154-.552l1.931 2.574 4.959-7.437a1.206 1.206 0 011.667-.33 1.203 1.203 0 01.336 1.665l-5.903 8.852a1.202 1.202 0 01-1.001.536z"
        ></path>
        <path
          fill="#484B52"
          d="M141.634 72.03L124.952 25.63l.423-.152-3.068-8.534-.423.152-5.123-14.246a4.29 4.29 0 00-2.217-2.438 4.301 4.301 0 00-3.292-.157L81.093 11.094a4.306 4.306 0 00-2.596 5.508l3.572 9.933-.669.257 2.28 5.919.535-.206.473 1.316-.57.22 2.28 5.918.437-.168L103.37 85.78a4.295 4.295 0 002.216 2.442 4.299 4.299 0 003.294.154l30.158-10.838a4.309 4.309 0 002.596-5.508z"
        ></path>
        <path
          fill="#fff"
          d="M107.294 86.846a2.067 2.067 0 01-1.941-1.366L80.217 15.57a2.062 2.062 0 011.244-2.639l6.417-2.306-.01.225a2.377 2.377 0 003.181 2.338l14.241-5.118a2.402 2.402 0 001.323-2.801 2.36 2.36 0 00-.529-.965l-.15-.167 6.628-2.382A2.066 2.066 0 01115.202 3l23.981 66.698a5.48 5.48 0 01-3.3 7.003l-27.89 10.023c-.224.08-.461.122-.699.122z"
        ></path>
        <path
          fill="#1774DE"
          d="M104.836 37.262a8.156 8.156 0 008.157-8.155 8.156 8.156 0 00-8.157-8.154 8.156 8.156 0 100 16.309z"
        ></path>
        <path
          fill="#DCE0E8"
          d="M125.048 40.966l-28.246 10.15a.714.714 0 11-.483-1.342l28.247-10.15a.712.712 0 01.885.974.712.712 0 01-.403.368zM116.66 47.402l-9.291 3.338a.715.715 0 01-.888-.975.72.72 0 01.405-.367l9.291-3.339a.715.715 0 01.887.975.715.715 0 01-.404.368zM118.87 53.548l-9.291 3.339a.716.716 0 01-.913-.43.704.704 0 01.192-.77.71.71 0 01.238-.143l9.291-3.339a.715.715 0 01.913.43.712.712 0 01-.43.913zM120.002 56.696l-9.291 3.339a.718.718 0 01-.771-.19.722.722 0 01-.185-.515.707.707 0 01.234-.495.71.71 0 01.239-.143l9.291-3.339a.715.715 0 01.888.976.72.72 0 01-.405.367zM122.96 48.56l-19.712 7.083a.718.718 0 01-.771-.19.722.722 0 01-.185-.515.724.724 0 01.234-.494.71.71 0 01.239-.144l19.712-7.083a.718.718 0 01.771.19.72.72 0 01.185.515.724.724 0 01-.234.494.712.712 0 01-.239.143z"
        ></path>
        <path
          fill="#1774DE"
          d="M118.99 64.338l-3.374 1.212a1.213 1.213 0 01-.821-2.283l3.374-1.212a1.213 1.213 0 01.821 2.283z"
        ></path>
        <path
          fill="#fff"
          d="M92.405 10.852a.47.47 0 100-.938.47.47 0 000 .938zM192.062 141.155a6.722 6.722 0 012.884-3.959c.141-.09-.006-.305-.147-.215a6.991 6.991 0 00-2.992 4.126c-.042.163.214.21.255.048z"
        ></path>
        <path
          fill="#484B52"
          d="M219.228 100.601l-8.743 14.708.333.175-2.961 5.612-.616-.326-1.946 3.27.484.278-3.172 5.491-.55-.317-28.527 47.996a5.521 5.521 0 01-7.558 1.924l-53.032-31.503a5.52 5.52 0 01-1.925-7.557l45.698-76.886a5.52 5.52 0 017.558-1.924l53.033 31.503a5.517 5.517 0 011.924 7.556z"
        ></path>
        <path
          fill="#fff"
          d="M167.862 176.444l-53.219-31.614a1.7 1.7 0 01-.593-2.329l46.001-77.398a1.7 1.7 0 012.329-.593l53.22 31.613a1.701 1.701 0 01.593 2.33l-46.001 77.398a1.704 1.704 0 01-2.33.593zM189.723 79.093a.469.469 0 100-.938.469.469 0 000 .938z"
        ></path>
        <path
          fill="#DCE0E8"
          d="M186.82 134.537l-44.425-26.39a1.235 1.235 0 01-.567-.753 1.233 1.233 0 01.134-.932 1.221 1.221 0 01.755-.564 1.233 1.233 0 01.933.138l44.424 26.389a1.222 1.222 0 01.593 1.233 1.222 1.222 0 01-.915 1.016 1.233 1.233 0 01-.932-.137zM169.081 130.447l-14.612-8.68a1.227 1.227 0 111.254-2.111l14.613 8.68a1.23 1.23 0 01-1.255 2.111zM163.336 140.114l-14.613-8.68a1.23 1.23 0 011.255-2.112l14.613 8.68a1.241 1.241 0 01.563.753 1.228 1.228 0 01-1.818 1.359zM160.393 145.065l-14.612-8.68a1.231 1.231 0 01-.591-1.232 1.222 1.222 0 01.481-.809 1.221 1.221 0 01.912-.232c.16.024.314.079.453.161l14.612 8.681a1.229 1.229 0 01.593 1.232 1.222 1.222 0 01-.915 1.016 1.233 1.233 0 01-.933-.137zM174.444 140.081l-31.003-18.417a1.226 1.226 0 01.326-2.242c.315-.081.649-.034.929.131l31.003 18.417a1.217 1.217 0 01.566.752 1.233 1.233 0 01-.133.933 1.234 1.234 0 01-1.236.588 1.239 1.239 0 01-.452-.162z"
        ></path>
        <path
          fill="#1774DE"
          d="M168.535 163.617l-5.306-3.151a2.092 2.092 0 01-.727-2.861 2.09 2.09 0 012.861-.73l5.306 3.152a2.09 2.09 0 01-2.134 3.59zM178.434 112.444c6.006 0 10.875-4.868 10.875-10.872 0-6.005-4.869-10.873-10.875-10.873s-10.875 4.868-10.875 10.873c0 6.004 4.869 10.872 10.875 10.872z"
        ></path>
        <path
          fill="#DCE0E8"
          d="M.128 145.753h147.174V60.571a2.413 2.413 0 00-2.408-2.407H2.536A2.411 2.411 0 00.128 60.57v85.182z"
        ></path>
        <path fill="#fff" d="M142.755 61.493H4.675v78.37h138.08v-78.37z"></path>
        <path
          fill="#484B52"
          d="M0 143.448v10.512a3.319 3.319 0 003.32 3.318h54.32v20.745h-.722a.425.425 0 00-.305.127.43.43 0 00-.126.304v1.443a.428.428 0 00.266.399.443.443 0 00.165.032H90.64a.444.444 0 00.165-.032.44.44 0 00.233-.234.428.428 0 00.033-.165v-1.443a.435.435 0 00-.126-.304.44.44 0 00-.305-.127h-.722v-20.745h54.321a3.31 3.31 0 002.347-.971c.308-.309.553-.674.72-1.077.166-.403.252-.834.252-1.27v-10.511L0 143.448z"
        ></path>
        <path
          fill="#DCE0E8"
          d="M122.775 87.703h-65.39a1.554 1.554 0 110-3.108h65.39a1.556 1.556 0 011.554 1.554 1.555 1.555 0 01-1.554 1.554zM100.834 94.718H79.326a1.555 1.555 0 110-3.108h21.508a1.554 1.554 0 110 3.108zM100.834 108.946H79.326a1.556 1.556 0 01-1.554-1.554 1.554 1.554 0 011.554-1.554h21.508a1.556 1.556 0 011.099 2.653 1.556 1.556 0 01-1.099.455zM100.834 116.233H79.326a1.556 1.556 0 01-1.554-1.554 1.549 1.549 0 01.96-1.435c.188-.078.39-.118.594-.118h21.508a1.56 1.56 0 011.099.455 1.551 1.551 0 01-1.099 2.652zM112.897 101.732H67.263a1.556 1.556 0 01-1.554-1.554 1.554 1.554 0 011.554-1.554h45.634a1.556 1.556 0 011.099 2.653 1.557 1.557 0 01-1.099.455z"
        ></path>
        <path
          fill="#1774DE"
          d="M121.686 131.154h-7.81a2.638 2.638 0 01-1.868-.774 2.64 2.64 0 010-3.736 2.638 2.638 0 011.868-.774h7.81a2.642 2.642 0 110 5.284zM36.824 111.976c7.507 0 13.594-6.084 13.594-13.59s-6.087-13.59-13.594-13.59c-7.508 0-13.595 6.084-13.595 13.59s6.087 13.59 13.595 13.59z"
        ></path>
        <path
          fill="#DCE0E8"
          d="M195.903 180.889H.36a.36.36 0 010-.719h195.543c.095 0 .186.038.254.105a.362.362 0 010 .508.359.359 0 01-.254.106z"
        ></path>
        <path fill="#fff" d="M73.651 59.828a.512.512 0 100-1.024.512.512 0 000 1.024z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_51121">
          <path fill="#fff" d="M0 0H220V180.889H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default CockpitNews
