import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

import cockpitLogoAnimation from '../../../assets/animation/cockpitLogo.json'

import { Container, Animation } from './styles'
import { ProgressBar } from '../Progress'

interface IPreloadingScreenProps {
    visible: boolean
    percentage?: number
}

export const PreloadingScreen: React.FC<IPreloadingScreenProps> = ({ visible, percentage }) => {
    return (
        <Container visible={visible}>
            <Animation>
                <Player
                    autoplay
                    loop
                    src={cockpitLogoAnimation}
                    >
                </Player>
            </Animation>
            <ProgressBar percentage={percentage} width='247px' />
        </Container>
    )
}
