import { Button, Input } from '@cockpit/zera'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Icons from '../../../assets/icons'
import { Container, Description, InputBox, InputField, PasswordChecks, PasswordViewer, RoundedCheck } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectUserSession,
  updateUser,
  selectLoading
} from '../../../modules/login/sessionSlice'

interface Props {
  oldPassword: string
  setShowPasswordModal: Function
}
interface Inputs {
  password: string
  confirmPassword: string
}

export const PasswordModalBody: React.FC<Props> = ({ oldPassword, setShowPasswordModal }) => {
  const sessionData = useSelector(selectUserSession)
  const { register, handleSubmit } = useForm<Inputs>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [minLengthValidation, setMinLengthValidation] = useState<boolean>(false)
  const [upperCaseValidation, setUpperCaseValidation] = useState<boolean>(false)
  const [lowerCaseValidation, setLowerCaseValidation] = useState<boolean>(false)
  const [numberValidation, setNumberValidation] = useState<boolean>(false)
  const [specialCharacterValidation, setSpecialCharacterValidation] = useState<boolean>(false)
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState<boolean>(true)
  const [updatePasswordError, setUpdatePasswordError] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const loadingLogin = useSelector(selectLoading)

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setMinLengthValidation(password.length >= 8)
    setUpperCaseValidation(password.toLocaleLowerCase() !== password)
    setLowerCaseValidation(password.toLocaleUpperCase() !== password)
    setNumberValidation(!!password.match(/\d+/g))
    setSpecialCharacterValidation(!!password.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/))
  }, [password])

  useEffect(() => {
    if ((!!password.length && !!confirmPassword.length) && (password !== confirmPassword)) {
      setConfirmPasswordValidation(false)
    } else {
      setConfirmPasswordValidation(true)
    }
  }, [password, confirmPassword])

  useEffect(() => {
    if (confirmPassword.length) {
      setIsButtonDisabled(!!(!minLengthValidation || !upperCaseValidation || !lowerCaseValidation || !numberValidation || !specialCharacterValidation || !confirmPasswordValidation))
    }
  }, [confirmPassword, minLengthValidation, upperCaseValidation, lowerCaseValidation, numberValidation, specialCharacterValidation, confirmPasswordValidation])

  useEffect(() => {
    if ((loadingLogin === 'success' || loadingLogin === 'failing') && !sessionData?.user?.first_access) {
      setIsLoading(false)
      setShowPasswordModal(false)
    }
  }, [loadingLogin, sessionData])

  const onSubmit = async (data: Inputs) => {
    try {
      setIsLoading(true)
      const requestData = {
        old_password: oldPassword,
        password: password,
        first_access: false
      }

      if (sessionData?.user?.id) {
        dispatch(updateUser(sessionData.user.id, requestData, sessionData.token, sessionData))
      }
    } catch (e: any) {
      setIsLoading(false)
      setUpdatePasswordError(true)
    }
  }

  return (
    <Container>
      <h1>Bem-vindo!</h1>
      <Description>
        <h3>Por questões de segurança, crie uma nova senha.</h3>
        <h3>Sua senha deve conter:</h3>
      </Description>
      <PasswordChecks>
        <label>
          <RoundedCheck isChecked={minLengthValidation}>
            <Icons.Check />
          </RoundedCheck>
          Pelo menos 8 caracteres
        </label>
        <label>
          <RoundedCheck isChecked={upperCaseValidation}>
            <Icons.Check />
          </RoundedCheck>
          Letra maiúscula (A-Z)
        </label>
        <label>
          <RoundedCheck isChecked={lowerCaseValidation}>
            <Icons.Check />
          </RoundedCheck>
          Letra minúscula (a-z)
        </label>
        <label>
          <RoundedCheck isChecked={numberValidation}>
            <Icons.Check />
          </RoundedCheck>
          Número (0-9)
        </label>
        <label>
          <RoundedCheck isChecked={specialCharacterValidation}>
            <Icons.Check />
          </RoundedCheck>
          Caractere especial (Ex.: @#$&*)
        </label>
      </PasswordChecks>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox>
          <label htmlFor="password">NOVA SENHA</label>
          <InputField>
            <Input
              onPaste={(e: any) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e: any) => {
                e.preventDefault()
                return false
              }}
              {...register('password')}
              type={isPasswordVisible ? 'text' : 'password'}
              onChange={(e: any) => setPassword(e.target.value)}
              margin={'5px 0 10px 0'}
            />
              <PasswordViewer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                { isPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
              </PasswordViewer>
          </InputField>
          <label htmlFor="password">CONFIRMAR NOVA SENHA</label>
          <InputField>
            <Input
              onPaste={(e: any) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e: any) => {
                e.preventDefault()
                return false
              }}
              {...register('confirmPassword')}
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              margin={'5px 0 10px 0'}
            />
              <PasswordViewer onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}>
                { isConfirmPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
              </PasswordViewer>
          </InputField>
          {!confirmPasswordValidation && (<p className="errorMessage">As senhas digitadas devem ser iguais</p>)}
          {updatePasswordError && (<p className="errorMessage">Erro ao atualizar a senha, tente novamente. Caso persista, contate o suporte.</p>)}
        </InputBox>
        <Button type="submit" isLoading={isLoading} disabled={!!isButtonDisabled}>
          SALVAR
        </Button>
      </form>
    </Container>
  )
}
