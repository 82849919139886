import { ReactElement, useEffect, useState } from 'react'
import MicroFrontend from '../../components/Microfront/Microfront'
import { useSelector } from 'react-redux'
import { selectCurrentPlatform, selectUserSession } from '../../modules/login/sessionSlice'
import { SessionData } from '../../modules/login/Session'
import { createBrowserHistory } from 'history'
import guardChat from '../../modules/resolvers/ChatGuard'

export const Chat: React.FC<any> = (): ReactElement => {
  const defaultHistory = createBrowserHistory()
  const userSession: SessionData | null = useSelector(selectUserSession)
  const [showChat, setShowChat] = useState(false)
  const selectedPlatform = useSelector(selectCurrentPlatform)

  useEffect(() => {
    if (!selectedPlatform) {
      setShowChat(false)
      return
    }

    guardChat(selectedPlatform).then(canShowChat => {
      setShowChat(canShowChat)
    })
  }, [selectedPlatform])

  if (!showChat) {
    return <></>
  }

  return (
    <MicroFrontend
      name={'Chat'}
      host={process.env.REACT_APP_CHAT_HOST ?? ''}
      history={defaultHistory}
      user={userSession}
    ></MicroFrontend>
  )
}
export default Chat
