import { Button, Input, theme } from '@cockpit/zera'
import React, { useEffect, useState } from 'react'
import { get, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Icons from '../../../../assets/icons'
import { ACCESS_MANAGER_PLATFORM_TAG } from '../../../../enums'
import { selectUserSession } from '../../../../modules/login/sessionSlice'
import { Separator, Title } from '../../../../styles/shared'
import { messages } from '../../constants/Messages'
import { useSecurityEditModal } from '../../context'
import { ErrorMessageRequeridField } from '../UserEditModal/styles'
import {
  FormContainer,
  Inform,
  TitleForm,
  InputField,
  PasswordViewer,
  PasswordChecks,
  RoundedCheck,
  ButtonBox,
  ContainerMessages,
  ErrorMessage,
  SuccessMessage,
  ContainerSecurityEditModal,
  Content
} from './styles'

export interface Inputs {
  currentPassword: string;
  password: string
  confirmPassword: string
  defaultPlatformOrOfficeId: string
}

export const SecurityEditModal: React.FC = () => {
  const sessionData = useSelector(selectUserSession)
  const userInActiveDirectory = !!sessionData?.user.user_in_active_directory
  const defaultPlatformId = Number(sessionData?.user?.access?.find(a => a.default_platform_id)?.default_platform_id)
  const defaultOfficeId = Number(sessionData?.user?.access?.find(a => a.default_office_id)?.default_office_id)

  const [disabledFieldsEmpty, setDisabledFieldsEmpty] = useState(true)

  const { register, handleSubmit, formState, watch } = useForm<Inputs>({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
      defaultPlatformOrOfficeId: defaultPlatformId
        ? `${defaultPlatformId}-platform`
        : defaultOfficeId ? `${defaultOfficeId}-office` : undefined
    }
  })

  const {
    isLoading,
    password,
    minLengthValidation,
    upperCaseValidation,
    lowerCaseValidation,
    numberValidation,
    specialCharacterValidation,
    confirmPasswordValidation,
    updatePasswordError,
    updatePasswordSuccess,
    isButtonDisabled,
    isPasswordVisible,
    isConfirmPasswordVisible,
    isCurrentPasswordVisible,
    isCurrentPasswordEmpty,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    setIsCurrentPasswordEmpty,
    setIsPasswordVisible,
    setIsConfirmPasswordVisible,
    setIsCurrentPasswordVisible,
    setUpdatePasswordSuccess,
    setUpdatePasswordError,
    onSubmitSecurityEdit,
    setOnlyDefaultPlatformChanged,
    setIsLoading
  } = useSecurityEditModal()

  React.useEffect(() => {
    setIsLoading(false)
    setOnlyDefaultPlatformChanged(false)
    setUpdatePasswordSuccess(false)
    setUpdatePasswordError(false)
    setPassword('')
    setConfirmPassword('')
    setIsConfirmPasswordVisible(false)
    setIsCurrentPasswordVisible(false)
    setIsPasswordVisible(false)
  }, [])

  const watchPlatform = watch('defaultPlatformOrOfficeId')
  const watchCurrentPassword = watch('currentPassword')
  const watchPassword = watch('password')

  const filteredPlatforms =
  sessionData?.user?.allPlatforms?.filter(platform =>
    platform.tag === ACCESS_MANAGER_PLATFORM_TAG
  )

  const platformOrOfficeList = filteredPlatforms?.length
    ? filteredPlatforms.filter(platform => !platform.access_only_to_offices).map(platform => ({
      value: `${platform.id}-platform`,
      label: platform.description.split('Cockpit')[1]
          ? platform.description.split('Cockpit')[1].trim()
          : platform.description
      })
    ) : []

  filteredPlatforms
    ?.forEach((platformAndOffice) => {
      platformOrOfficeList?.push(...platformAndOffice?.offices.map(office => ({
        value: `${office.id}-office`,
        label: office.name
      })))
    }
  )

   React.useEffect(() => {
    setIsCurrentPasswordEmpty(!(watchCurrentPassword.length >= 8))
  }, [watchCurrentPassword])

  useEffect(() => {
    const currentPassword = !isCurrentPasswordEmpty

    if (watchPlatform && (!currentPassword && !password && !confirmPassword)) {
      setDisabledFieldsEmpty(false)
      return
    }

    if (!watchPlatform && (currentPassword && password && confirmPassword)) {
      setDisabledFieldsEmpty(false)
      return
    }

    if (watchPlatform && currentPassword && password && confirmPassword) {
      setDisabledFieldsEmpty(false)
      return
    }

    setDisabledFieldsEmpty(true)
  }, [
    watchPlatform,
    isCurrentPasswordEmpty,
    password,
    confirmPassword
  ])

  return (
    <ContainerSecurityEditModal>
      <FormContainer
        onSubmit={handleSubmit(onSubmitSecurityEdit)}
        userInActiveDirectory={userInActiveDirectory}
      >
        <Content>
          {platformOrOfficeList?.length !== 1 && (
            <React.Fragment>
              {/* <Title
                textTransform='uppercase'
                marginBottom={theme.spacing.medium}
                marginTop={theme.spacing.large}
                fontWeight='bold'
                color='#6B7786'
              >
                {messages.changePlatform}
                <Separator marginTop={theme.spacing.medium} />
              </Title>
              <Title
                marginBottom={theme.spacing.medium}
                marginTop={theme.spacing.base}
                color='#545E6C'>
                {messages.choosePlatform}
              </Title>
              {platformOrOfficeList && (
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: messages.choosePlatform }
                  }}
                  name='defaultPlatformOrOfficeId'
                  render={({ field }) => {
                    return <Select
                      {...field}
                      options={platformOrOfficeList}
                      setValue={(value) => {
                          setValue(field.name, String(value))
                          setIsButtonDisabled(false)
                        }
                      }
                      />
                    }}
                />
              )} */}
              {get(formState.errors, 'defaultPlatformOrOfficeId') &&
              <ErrorMessageRequeridField>
                <Title color={theme.colors.red1} fontSize='14px'>
                  {get(formState.errors, 'defaultPlatformOrOfficeId')?.message}
                </Title>
              </ErrorMessageRequeridField>}
            </React.Fragment>
          )}
          {!userInActiveDirectory && (
            <React.Fragment>
              <Inform>
            <Title
              textTransform='uppercase'
              marginBottom={theme.spacing.medium}
              marginTop={theme.spacing.large}
              fontWeight='bold'
              color='#6B7786'
              displayNoneMobile='none'
              >
                {messages.editPassword}
              <Separator
              displayNoneMobile='none'
              marginTop={theme.spacing.medium}/>
              </Title>
              <TitleForm>{messages.currentPassword}</TitleForm>
              <InputField>
                <Input
                  onPaste={(e: any) => {
                    e.preventDefault()
                    return false
                  }}
                  onCopy={(e: any) => {
                    e.preventDefault()
                    return false
                  }}
                  {...register('currentPassword', {
                    required: !(watchPlatform && !watchPassword)
                  })}
                  id="currentPassword"
                  type={isCurrentPasswordVisible ? 'text' : 'password'}
                  margin={'5px 0 10px 0'}
                  defaultValue={password}
                  data-testid='currentPassword'
                  error={get(formState.errors, 'currentPassword')}
                />
                <PasswordViewer
                data-testid='password-viewer'
                onClick={() =>
                setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                }>
                {isCurrentPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
                </PasswordViewer>
                </InputField>
              </Inform>
              <Inform>
              <Title
              marginBottom={theme.spacing.medium}
              marginTop={theme.spacing.medium}
              color='#545E6C'>
                {messages.passwordMustBe}
                </Title>
              <PasswordChecks>
                <label>
                  <RoundedCheck isChecked={minLengthValidation}>
                    <Icons.Check />
                  </RoundedCheck>
                  {messages.passwordMustBe_characters}
                </label>
                <label>
                  <RoundedCheck isChecked={upperCaseValidation}>
                    <Icons.Check />
                  </RoundedCheck>
                  {messages.passwordMustBe_uppercase}
                </label>
                <label>
                  <RoundedCheck isChecked={lowerCaseValidation}>
                    <Icons.Check />
                  </RoundedCheck>
                  {messages.passwordMustBe_lowercase}
                </label>
                <label>
                  <RoundedCheck isChecked={numberValidation}>
                    <Icons.Check />
                  </RoundedCheck>
                  {messages.passwordMustBe_number}
                </label>
                <label>
                  <RoundedCheck isChecked={specialCharacterValidation}>
                    <Icons.Check />
                  </RoundedCheck>
                  {messages.passwordMustBe_specialCharacters}
                </label>
              </PasswordChecks>
              </Inform>
              <TitleForm>{messages.newPassword}</TitleForm>
              <InputField>
              <Input
                onPaste={(e: any) => {
                  e.preventDefault()
                  return false
                }}
                onCopy={(e: any) => {
                  e.preventDefault()
                  return false
                }}
                {...register('password')}
                type={isPasswordVisible ? 'text' : 'password'}
                onChange={(e: any) => setPassword(e.target.value)}
                margin={'5px 0 10px 0'}
                id="password"
                data-testid="password-test"
                error={!confirmPasswordValidation || updatePasswordError}
              />
              <PasswordViewer
              data-testid='password-viewer'
              onClick={() =>
              setIsPasswordVisible(!isPasswordVisible)
              }>
              {isPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
              </PasswordViewer>
              </InputField>
              <TitleForm>Confirmar nova senha</TitleForm>
              <InputField>
              <Input
                onPaste={(e: any) => {
                  e.preventDefault()
                  return false
                }}
                onCopy={(e: any) => {
                  e.preventDefault()
                  return false
                }}
                {...register('confirmPassword')}
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                margin={'5px 0 10px 0'}
                id="confirmPassword"
                data-testid="confirmPassword"
                error={!confirmPasswordValidation || updatePasswordError}
                />
                <PasswordViewer
                data-testid='password-viewer'
                onClick={() =>
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                }>
                {isConfirmPasswordVisible ? <Icons.ViewActive/> : <Icons.View/> }
                </PasswordViewer>
              </InputField>
              {!confirmPasswordValidation && (
                <ErrorMessage>
                  <Title color={theme.colors.red1}
                  fontSize='14px'>
                    {messages.confirmPasswordValidationError}
                  </Title>
                </ErrorMessage>
              )}
            </React.Fragment>
          )}
        </Content>

        <ButtonBox>
          <ContainerMessages>
          {updatePasswordError && (
            <ErrorMessage>
              <Title
              color={theme.colors.red1}
              fontSize='12px'>
                {messages.updatePasswordError}
              </Title>
            </ErrorMessage>
          )}
          {updatePasswordSuccess && (
            <SuccessMessage>
              <Title
              color={theme.colors.green1}
              fontSize='12px'>
                {messages.updatePasswordSuccess}
              </Title>
            </SuccessMessage>
          )}
          </ContainerMessages>
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={disabledFieldsEmpty || !!isButtonDisabled}
          >
            {messages.save}
          </Button>
        </ButtonBox>
      </FormContainer>
    </ContainerSecurityEditModal>
  )
}
