import styled from 'styled-components'
import ENV from '../../modules/env'

const ImageLoginBackground = `${ENV.IMAGES_URL}/images/login-background.jpg`

export const Container = styled.div`
	width: 100%;
	height: 100%;

	footer {
		width: 100%;

		@media(max-width: 500px) {
			color: #6D7785;
		}
	}
`

export const LoginSection = styled.section`
	min-height: 100vh;
	position: relative;
	background-image: url(${ImageLoginBackground});
	background-size: cover;
	justify-content: center;
	align-items: center;
	display: flex;

	form {
		width: 70%;
	}

	@media(min-width: 500px) {
		z-index: 0 !important;
	}
`

export const Closebutton = styled.div`
	display: flex;
	align-self: flex-end;

	a {
		fill: gray;
	}
`

export const Mask = styled.div`
	background: transparent linear-gradient(0deg, #001434 0%, #0057ff33 100%) 0% 0% no-repeat padding-box;
	position: absolute;
	width: 100%;
	min-height: 100%;
	z-index: 0;
`
export const Footer = styled.footer`
	position: absolute;
	color: #fff;
	filter: brightness(80%);
	text-align: center;
	bottom: 0;
	width: 90%;
	margin: 0 auto 10px auto;

	address {
		line-height: 20px;
		margin-bottom: 20px;
	}

	a {
		color: #5aa8eb;
		text-decoration: none;
	}
`
export const ErrorMsg = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ff1a1e;
	color: #fff;
	padding: 6px;
	border-radius: 3px;
	margin-top: 12px;
	width: 70%;
	min-height: 44px;
	text-align: center;
`
