export interface ThemeInterface {
  themeColors: {
    semantic: {
      primary: string
      secondary: string
      success: string
      danger: string
      warning: string
      info: string
      light: string
      dark: string
    }
    neutral: {
      gray20: string
      gray40: string
      gray60: string
      gray80: string
      gray90: string
      gray100: string
    }
  }
  border: {
    radius: string
  }
  palette: {
    gray1: string
    gray2: string
    gray3: string
    gray4: string
    gray5: string
    white: string
    black: string
    main: {
      background: string
      externalInformation: string
      patientInformation: string
    }
    button: {
      primary: string
      error: string
      success: string
      warning: string
      disabled: string
      mainHover: string
      hover1: string
      hover2: string
      icons: string
    }
    queue: {
      waiting: string
      onConsultation: string
      notFinished: string
      backToQueue: string
    }
    highlight: {
      red: string
      blue: string
    }
  }
  colors: {
    white: string
    black: string

    // From light to dark
    gray1: string
    gray2: string
    gray3: string
    gray4: string
    gray5: string
    gray6: string
    gray7: string

    blue1: string
    blue2: string
    blue3: string
    blue4: string
    blue5: string
    blue6: string

    red1: string

    green1: string

    orange1: string
    orange2: string
  }
  borderRadius: {
    patientInformation: string
    fields: string
    buttons: string
    notifications: string
    checkbox: string
    tags: string
  }
  spacing: {
    tiny: string
    xsmall: string
    small: string
    base: string
    medium: string
    large: string
    xlarge: string
    big: string
  }
  iconSizes: {
    tiny: string
    xsmall: string
    small: string
    base: string
    medium: string
    large: string
    xlarge: string
    big: string
  }
  font: {
    root: string
  }
  fontWeight: {
    bold: string
    semibold: string
    medium: string
    regular: string
  }
  menu: {
    background: string
    divider: string
    iconContainer: string
    iconContainerColor: string
    iconContainerColorHover: string
  }
  breakpoints: {
    xsmall: number
    small: number
    medium: number
    large: number
    xlarge: number
  }
}

export interface ThemeProps {
  theme: ThemeInterface
}

export const theme: ThemeInterface = {
  themeColors: {
    semantic: {
      primary: '#004F92',
      secondary: '#257CE5',
      success: '#4CAF50',
      danger: '#E30E11',
      dark: '#000000',
      light: '#FFFFFF',
      info: '',
      warning: '#FF8729'
    },
    neutral: {
      gray20: '#EAF4FF',
      gray40: '#E9ECF3',
      gray60: '#DFE3E8',
      gray80: '#BCC9D9',
      gray90: '#6B7786',
      gray100: '#2B2D34'
    }
  },
  border: {
    radius: '4px'
  },
  palette: {
    gray1: '#EAF4FF',
    gray2: '#E9ECF3',
    gray3: '#BCC9D9',
    gray4: '#6B7786',
    gray5: '#DFE3E8',
    white: '#FFFFFF',
    black: '#000000',
    main: {
      background: '#F3F5F9',
      externalInformation: '#E9ECF3',
      patientInformation: '#FFFFFF'
    },
    button: {
      primary: '#257CE5',
      error: '#FF2424',
      success: '#4CAF50',
      warning: '#FF8729',
      disabled: '#378ff778',
      mainHover: '#257CE5',
      hover1: '#F6F7FB',
      hover2: '#004F92',
      icons: '#6B7786'
    },
    queue: {
      waiting: '#D7E9FE',
      onConsultation: '#4CAF50',
      notFinished: '#FF8729',
      backToQueue: '#AAB0BE'
    },
    highlight: {
      red: '#FF2424',
      blue: '#378FF7'
    }
  },
  colors: {
    white: '#ffffff',
    black: '#000000',

    // From light to dark
    gray1: '#f6f7fb',
    gray2: '#f3f5f9',
    gray3: '#e9ecf3',
    gray4: '#6B7786',
    gray5: '#6b7786',
    gray6: '#dfe3e8',
    gray7: '#f0f2f5',

    blue1: '#eaf4ff',
    blue2: '#bcc9d9',
    blue3: '#378ff7',
    blue4: '#257ce9',
    blue5: '#004f92',
    blue6: '#ebf4f3',

    red1: '#FF2424',

    green1: '#4CAF50',

    orange1: '#FFE7D2',
    orange2: '#FFB324'
  },
  borderRadius: {
    patientInformation: '4px',
    fields: '4px',
    buttons: '4px',
    notifications: '4px',
    checkbox: '5px',
    tags: '15px'
  },
  spacing: {
    tiny: '4px',
    xsmall: '8px',
    small: '12px',
    base: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
    big: '64px'
  },
  iconSizes: {
    tiny: '4px',
    xsmall: '8px',
    small: '12px',
    base: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
    big: '64px'
  },
  font: {
    root: 'Inter'
  },
  fontWeight: {
    bold: '700',
    semibold: '600',
    medium: '500',
    regular: '400'
  },
  menu: {
    background: '#122D52',
    divider: '#9BA8BA',
    iconContainer: '#122D52',
    iconContainerColor: '#9BA8BA',
    iconContainerColorHover: '#fff'
  },
  breakpoints: {
    xsmall: 768,
    small: 999,
    medium: 1200,
    large: 1400,
    xlarge: 1550
  }
}
