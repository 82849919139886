import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useCustomEvent } from '../../../shared/functions/useCustomEvent'

import Header from '../../header/Header'
import { AppWrapper, MainContent } from './styles'
import { PreloadingScreen } from '../../LoaderScreen/PreloadingScreen'
import { getPreloading, getProgress, setPreloading, setProgress } from '../../../modules/session/sessionSlice'
import { useLocation } from 'react-router-dom'
import LocalStorageManager from '../../../helpers/localStorage'
import useResize from '../useResize'
import { ETarget } from '../../../enums'
import { useContextSSO } from '../../../pages/Login/hooks'

export interface ScreenLoadingEvent {
  finishedLoading: boolean
}

const DefaultLayout: React.FC = ({ children }: any) => {
  const isVisible = useSelector(getPreloading)
  const progress = useSelector(getProgress)
  const dispatch = useDispatch()
  const { subscribe } = useCustomEvent<ScreenLoadingEvent>('screen-loading')
  const { search } = useLocation()
  const isMobile = useResize() <= 500

  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const queryTarget: ETarget | null = useMemo(() => queryParams.get('target') as any, [queryParams])

  const target = queryTarget ?? LocalStorageManager.ReadEncryptedPlainText('target-encrypted')

  const contextSSO = useContextSSO()
  const queryParamsSSO = contextSSO[1]

  const isDecisionSupport = queryParamsSSO.target === 'suporte-decisao'

  const isEinsteinDoctorApp: boolean = isMobile ? target === ETarget.PRESCRIPTION : false

  useEffect(() => {
    if (subscribe?.finishedLoading) {
      dispatch(setProgress(100))
      setTimeout(() => dispatch(setPreloading(!subscribe.finishedLoading)), 1000)
    }
  }, [subscribe])

  return (
    <AppWrapper hasHeader={!isEinsteinDoctorApp}>
      <PreloadingScreen visible={isVisible} percentage={progress} />
      {!isEinsteinDoctorApp && <Header />}
      <MainContent hasHeader={!isDecisionSupport ?? !isEinsteinDoctorApp}>{children}</MainContent>
    </AppWrapper>
  )
}

export default DefaultLayout
