import { IconExporter } from '@cockpit/zera'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Container, OptionDiv } from './styles'
export const SideBarPerfil: React.FC = (): ReactElement => {
  const verifyUrl = (path: string): boolean => {
    return window.location.pathname === path
  }
  return (
    <Container>
      <Link to={'/perfil'}>
        <OptionDiv isActive={verifyUrl('/perfil')} onClick={() => { }}>
          <span>
            <IconExporter name='user' width='14px' />
            Meus dados
          </span>
          <IconExporter name='arrow_right' width='14px' />
        </OptionDiv>
      </Link>
      <Link to={'/seguranca'}>
      <OptionDiv isActive={verifyUrl('/seguranca')}>
        <span>
          <IconExporter name='user' width='14px' />
          Segurança
        </span>
        <IconExporter name='arrow_right' width='14px' />
      </OptionDiv>
      </Link>
    </Container>
  )
}
