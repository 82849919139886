import React from 'react'

const css = `
  .abcdf{fill:none;stroke:#a7abb2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1.5px;}
`

const Logout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.907" height="15.5" viewBox="0 0 15.907 15.5"><defs><style>{css}</style></defs><g transform="translate(-2.022 -2.021)"><g transform="translate(2.772 2.772)"><path className="abcdf" d="M9.266,3.495V2.789A2.789,2.789,0,0,0,6.477,0H2.788A2.789,2.789,0,0,0,0,2.789v8.423A2.789,2.789,0,0,0,2.788,14h3.7a2.781,2.781,0,0,0,2.781-2.78v-.714" transform="translate(0 0)"/><path className="abcdf" d="M9.112.5H0" transform="translate(5.295 6.5)"/><path className="abcdf" d="M0,0,2.216,2.206,0,4.413" transform="translate(12.191 4.794)"/></g></g></svg>
)

export default Logout
