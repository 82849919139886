import CockpitNews from '../../assets/icons/cockpit-news'
import {
  ActionArea,
  Button,
  Dialog,
  ImageContainer,
  ContentContainer,
  Text,
  Title,
  InfoArea,
  DialogWrapper
} from './modal-unified-login-warning.styles'

interface ModalProps {
  handleAcceptUnifiedLoginWarningModal: () => void
  shouldRenderUnifiedLoginWarningModal: boolean
}

export function ModalUnifiedLoginWarning({
  handleAcceptUnifiedLoginWarningModal,
  shouldRenderUnifiedLoginWarningModal
}: ModalProps) {
  if (!shouldRenderUnifiedLoginWarningModal) return null

  return (
    <Dialog open>
      <DialogWrapper>
        <ImageContainer>
          <CockpitNews />
        </ImageContainer>
        <ContentContainer>
          <InfoArea>
            <Title>Novidades no seu acesso ao Cockpit</Title>
            <Text>A página de login do Cockpit será atualizada para deixar seu login mais prático e seguro.</Text>
            <Text>Em breve, você poderá fazer login com apenas um clique utilizando seu login de rede ou e-mail.</Text>
          </InfoArea>
          <ActionArea>
            <Button onClick={handleAcceptUnifiedLoginWarningModal}>Entendi</Button>
          </ActionArea>
        </ContentContainer>
      </DialogWrapper>
    </Dialog>
  )
}
