import LocalStorageManager from '../../helpers/localStorage'
import {
  SessionData,
  OfficeLocalStorage
} from '../../modules/login/Session'

export const healthProfessionalHasModule = (moduleId: number | string, sessionData?: SessionData | null) => {
  const session = sessionData ?? (LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData ?? null) as SessionData | null
  const office = LocalStorageManager.ReadEncryptedData('office-encrypted') as OfficeLocalStorage

  const platform = session?.user?.allPlatforms?.filter(
    element => element.id === Number(process.env.REACT_APP_CLINICAL_STAFF_PLATFORM_ID)
  )

  if (platform) {
    if (office?.id) {
      return platform[0]?.offices?.find(
        element => element.id === Number(office.id)
      )?.features?.some(
        element => element.id === Number(moduleId)
      )
    }

    return platform[0]?.application_modules?.some(
      element => element.id === Number(moduleId)
    )
  }else return false
}
