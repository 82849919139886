import { SessionData } from '../login/Session'
import LocalStorageManager from '../../helpers/localStorage'
import { EModulesIds } from '../../enums/access'
import ENV from '../env'
import { FrontPlatform } from '../../models/FrontPlatform.enum'
import { getFeatures } from '../login/login.service'

export default async function guardChat(selectedPlatform: number): Promise<boolean> {
  if (!isChatEnabledForPlatform(selectedPlatform)) return false

  const sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
  if (!sessionData) return false

  const platforms = sessionData?.user?.allPlatforms?.filter(element =>
    ENV.CHAT_ENABLED_FOR_PLATFORMS.includes(String(element.id))
  )
  if (!platforms?.length) return false

  const hasTelemedicinePlatform = platforms.some(platform => platform.id === FrontPlatform.TELEMEDICINE)
  if (hasTelemedicinePlatform) {
    return await canShowChat(sessionData.token)
  }

  const hasChatModule = platforms?.some(platform =>
    platform?.application_modules?.some(element => element.id === EModulesIds.CHAT_CONFIG_MODULE_ID)
  )

  return hasChatModule
}

function isChatEnabledForPlatform(platform: number): boolean {
  return ENV.CHAT_ENABLED_FOR_PLATFORMS.includes(String(platform))
}

async function canShowChat(token: string): Promise<boolean> {
  const features = await getFeatures(token)
  return features?.platforms?.some(platform => platform?.features?.some(feature => feature.id === ENV.CHAT_FEATURE_ID))
}
