import styled from 'styled-components'

interface IOptionDiv {
  isActive: boolean;
}

export const Container = styled.div`
  margin: 65px 0 0 50px;
  width: 256px;
  height: 70vh;
  border-right: 1px solid #E0E3E7;
  position: fixed;

  @media(max-width: 1024px){
    margin: 65px 0 0 35px;
    width: 241px;
  }
  @media(max-width: 768px){
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 45px;
    margin: 10px 0 0 0;
    border-right: none;
    border-bottom: 2px solid #E0E3E7;
    padding-left: 10%;
  }
  a{
    text-decoration: none;
    :visited{
      color: black;
    }
  }
`

export const OptionDiv = styled.div<IOptionDiv>`
  width: 206px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: ${props => props.isActive ? '#E0EAF8' : '#F3F5F9'};
  color: ${props => props.isActive ? '#257CE5' : '#000000'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  transition: .5s;
  font: normal 16px 'Inter';
  span{
    display: flex;
    flex-direction: row;
    svg{
      margin-right: 10px;
    }
  }
  :hover{
    background-color: #E0EAF8;
    color: #257CE5;
    filter: opacity(85%);
    cursor: pointer;
  }

  @media(max-width: 768px){
    background-color: #F3F5F9;
    color: black;
    border-bottom: ${props => props.isActive ? '2px solid #257CE5' : '2px solid #E0E3E7'};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 0 10px 0px;
    width: auto;
    height: 45px;
    margin-right: 35px;
    svg{
      display: none;
    }
    :hover{
      background-color: #F3F5F9;
      color: black;
      cursor: default;
    }
  }
`
