import { FrontPlatform } from '../../models/FrontPlatform.enum'
import { SessionData } from '../login/Session'
import LocalStorageManager from '../../helpers/localStorage'

/**
 * Verifica se usuário tem acesso a plataforma do Corpo Clínico
 */

export default function guardClinicalStaff(): void | boolean {
  const sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData

  if (sessionData) {
    // TODO: [0] temporario, a interface final de acessos nao esta pronta
    const hasAccess = sessionData?.user?.allPlatforms?.find(
      (p) => p.id === FrontPlatform.CLINICAL_STAFF
    )

    if (hasAccess) {
      return true
    }
  }

  window.location.href = '/'
}
