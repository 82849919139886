import { Button } from '@cockpit/zera'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 5px 0 0;
  position: relative;
`

export const Title = styled.div`
  font-size: 20px;
  color: #2D3139;
`

export const SelectCertification = styled.div`
  padding: 50px 0;
`

export const ItemCert = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border: 1px solid #C7CAD1;
  border-radius: 8px;
  padding: 8px 16px;

  cursor: pointer;

  transition: all 0.2s linear;
  margin-bottom: 16px;

  &:hover {
    background-color: #F6F7F9;
  }

  svg * {
    stroke: #969BA7;
  }
`

export const LeftCert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 1000px;
    margin-right: 24px;
    background-color: #F6F7F9;
    object-fit: scale-down;
    
  }
`

export const TitleCert = styled.div`
  font-weight: medium;
  font-size: 16px;
  font-family: Inter;
  color: #2D3139;
`

export const Description = styled.div`
  font-size: 16px;
  font-family: Inter;
  color: #4F5460;
  margin-bottom: 40px;
`

export const LogoCert = styled.div<{ mb?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  ${({ mb }) => mb && css`
    margin-bottom: 100px;
    @media(min-width: 501px) {
      margin-bottom: 40px;
    }
  `}
`

export const ButtonBack = styled.button`
  font-weight: medium;
  font-size: 14px;
  font-family: Inter;
  color: #4F5460;
  display: flex;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 10;
  border: 0px solid;
  &:hover {
    opacity: 0.7;
  }
  svg {
    margin-right: 5px;
  }
  svg * {
    stroke: #4F5460;
  }
`

export const LabelSelect = styled.div`
  font-weight: medium;
  font-size: 14px;
  font-family: Inter;
  color: #4F5460;
  margin-bottom: 8px;
`

export const ContentCert = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 450px;
  padding: 0 0;
  @media (min-width: 501px) {
    padding: 0 30px;
  }
`

export const ButtonValidated = styled(Button)``

export const CheckboxCert = styled.div`
  display: block;
  margin-top: 25px;
  * {
    text-transform: initial;
    font-weight: normal;
    font-size: 14px;
    font-family: Inter;
    color: #4F5460;
  }
`

export const Error = styled.div`
  background: #FFECEB;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  color: #D93B2A;
  padding: 8px;
  margin-bottom: 20px;
`

export const LoadingText = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  font-family: Inter;
  color: #707070;
  line-height: 30px;
  padding: 0 50px;
  margin-bottom: 40px;
`

export const SelectArea = styled.div`
  cursor: pointer !important;
  display: block;
  height: fit-content;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 501px) {
    margin-bottom: 20px;
  }
  * {
    cursor: pointer !important;
  }
`

export const VerificationContainer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 501px) {
    margin-bottom: 0;
  }
`

export const Validating = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  font-family: Inter;
  color: #6D7785;
  margin-bottom: 20px;
`

export const Verification = styled.div<{ isError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  .vi__wrapper {
    width: 100%;
    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      @media(min-width: 501px) {
        gap: 0px;
      }
      .vi__character.character {
        width: 100%;
        max-width: 43px;
        ${({ isError }) => isError && css`
          border: 1px solid #D93B2A;
        `}
      }
    }
  }
`

export const ButtonClose = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  width: fit-content;
  height: fit-content;
  display: block;
  padding: 0;
  margin: 0;
  border: 0px solid;
  background-color: transparent;
  z-index: 20;
`
