import styled from 'styled-components'

interface IAppWrapperProps {
  theme: {
    mainBackground: string
  }
  hasHeader: boolean
}

export const AppWrapper = styled.div<IAppWrapperProps>`
  background: ${(props): string => props.theme.mainBackground};
  min-height: ${(props): string => (props.hasHeader ? 'calc(100vh - 80px)' : 'calc(100vh)')};
`

export const MainContent = styled.section<{ hasHeader: boolean }>`
  margin-top: ${({ hasHeader }) => (hasHeader ? '64px' : '0px')};
  margin-bottom: ${({ hasHeader }) => (hasHeader ? '0px' : '0px')};
`

export const ChatContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.xsmall}px) {
    #chat-wrapper {
      max-height: calc(100% - 64px) !important;
      min-height: calc(100% - 64px) !important;
    }
  }
`
