import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '../store/store'
import { addCertificate, loginService } from './login.service'
import { authenticationService } from './authentication.service'
import { deleteLocalStorage } from '../local-storage/local-storage.service'
import { SessionData } from './Session'
import { VerificaAuthData } from './AuthenticateResponse'
import { removeAxiosAuth, setAxiosAuth } from '../http/api'
import {
  isAuthorizedUser,
  incorrectPasswordCounter,
  userIsBlocked,
  userHasAccessToPlatformsOrOffices,
  serverError
} from './login.helpers'
import { sessionValidation } from '../../shared/functions/sessionValidation'
import { PermissionsObject } from '../resolvers'
import { AxiosHttpClient } from '../../utils'
import ENV from '../env'
import { PermissionsData } from './PermissionsResponse'
import LocalStorageManager from '../../helpers/localStorage'

interface SessionSliceInterface {
  userSession: SessionData | null
  errors: any | null
  certification: string | null
  loading: 'failing' | 'success' | 'pending' | 'idle'
  isContextSSO: boolean
}

interface AuthenticationSliceInterface {
  data: VerificaAuthData | null
  errors: any | null
  loading: string
}

interface PermissionsSliceInterface {
  data: PermissionsData | null
  errors: any | null
  loading: 'failing' | 'success' | 'pending' | 'idle'
}

export const permissionsSlice = createSlice<
  PermissionsSliceInterface,
  SliceCaseReducers<PermissionsSliceInterface>,
  string
>({
  name: 'permissions',
  initialState: {
    loading: 'idle',
    data: null,
    errors: null
  },
  reducers: {
    permissionsData: (state, action) => {
      state.data = action.payload
      state.loading = 'success'
    },
    permissionsError: (state, action) => {
      state.errors = action.payload
      state.loading = 'failing'
    },
    setPermissionsLoading: (state, action) => {
      state.loading = action.payload ?? 'idle'
    },
    setResetPermissions: (state, action) => {
      state.data = action.payload
      state.loading = 'idle'
      state.errors = action.payload
    }
  }
})

export const sessionSlice = createSlice<SessionSliceInterface, SliceCaseReducers<SessionSliceInterface>, string>({
  name: 'session',
  initialState: {
    loading: 'idle',
    userSession: null,
    errors: null,
    certification: null,
    isContextSSO: false
  },
  reducers: {
    sessionUser: (state, action) => {
      state.userSession = action.payload
      state.loading = 'success'
    },
    sessionError: (state, action) => {
      state.errors = action.payload
      state.loading = action.payload ? 'failing' : 'idle'
    },
    sessionCertification: (state, action) => {
      state.certification = action.payload
      state.loading = 'success'
    },
    setLoading: (state, action) => {
      state.loading = action.payload ?? 'idle'
    },
    setIsContextSSO: (state, action) => {
      state.isContextSSO = action.payload
    },
    setResetState: (state, action) => {
      state.userSession = action.payload
      state.loading = 'idle'
      state.certification = action.payload
      state.errors = action.payload
    }
  }
})

export const authenticationSlice = createSlice<
  AuthenticationSliceInterface,
  SliceCaseReducers<AuthenticationSliceInterface>,
  string
>({
  name: 'authentication',
  initialState: {
    loading: 'idle',
    data: null,
    errors: null
  },
  reducers: {
    authenticationData: (state, action) => {
      state.data = action.payload
      state.loading = action.payload ? 'success' : 'idle'
    },
    authenticationError: (state, action) => {
      state.errors = action.payload
      state.loading = 'failing'
    },
    setAuthenticationLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { sessionUser, sessionError, sessionCertification, setLoading, setIsContextSSO, setResetState } =
  sessionSlice.actions
export const { authenticationData, authenticationError, setAuthenticationLoading } = authenticationSlice.actions
export const { permissionsData, setPermissionsLoading, setResetPermissions } = permissionsSlice.actions

export const login = (name: string, password: string) => async (dispatch: Function) => {
  dispatch(setLoading('pending'))

  return loginService
    .login(name, password)
    .then(async user => {
      removeAxiosAuth()
      if (!sessionValidation(user)) return dispatch(sessionError({ error: 'unauthorizedProfile' }))
      if (!isAuthorizedUser(user)) return dispatch(sessionError({ error: 'unauthorizedProfile' }))
      if (!userHasAccessToPlatformsOrOffices(user)) return dispatch(sessionError({ error: 'unauthorizedProfile' }))

      const permission = await getPermissions(user.token)

      setAxiosAuth(user.token)

      dispatch(permissionsData(permission))
      dispatch(sessionUser(user))

      LocalStorageManager.WriteEncryptedData<PermissionsData>('permissions-encrypted', permission)

      return Promise.resolve()
    })
    .catch(e => {
      if (incorrectPasswordCounter(e.data)) {
        return dispatch(
          sessionError({
            error: 'incorrectPassword',
            info: {
              missing_attempts: e.data.info.missing_attempts
            }
          })
        )
      }

      if (userIsBlocked(e.data)) {
        return dispatch(
          sessionError({
            error: 'userIsBlocked',
            info: {
              email: e.data.info.email
            }
          })
        )
      }

      if (serverError(e)) {
        return dispatch(sessionError({ error: 'serverError' }))
      }

      dispatch(sessionError(e.data))
    })
}

export const getPermissions = async (token: string | undefined) => {
  const { getUserPermissions } = new PermissionsObject()

  const response = await getUserPermissions({
    axiosClientHttp: new AxiosHttpClient(),
    headers: {
      authorization: `Bearer ${token}`
    },
    url: `${ENV.API_LOGIN}/authorization`
  })

  return response
}

export const loginSSO = (signature: string, externalUsername: string) => async (dispatch: Function) => {
  dispatch(setLoading('pending'))
  dispatch(setIsContextSSO(true))

  return loginService
    .loginSSO(signature, externalUsername)
    .then(user => {
      deleteLocalStorage('session-encrypted')
      deleteLocalStorage('verifica_token-encrypted')

      if (!sessionValidation(user)) return dispatch(sessionError({ error: 'unauthorizedProfile' }))
      if (!isAuthorizedUser(user)) return dispatch(sessionError({ error: 'unauthorizedSSO' }))

      dispatch(sessionUser(user))
      LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', user)
      setAxiosAuth(user.token)
      return Promise.resolve()
    })
    .catch(e => {
      dispatch(sessionError(e.data ?? { error: 'unauthorizedSSO' }))
      dispatch(setIsContextSSO(false))
    })
}

export const contextSSO = (isActive: boolean) => async (dispatch: Function) => {
  dispatch(setIsContextSSO(isActive))
}

export const updateUser =
  (userId: number, requestData: object, token: string, sessionData?: SessionData) => async (dispatch: Function) => {
    dispatch(setLoading('pending'))

    return loginService
      .updateUser(userId, requestData, token)
      .then(user => {
        if (sessionData) {
          const userSession = Object.assign({ ...sessionData }, { user })
          dispatch(sessionUser(userSession))
        } else {
          const sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
          sessionData.user = user
          LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionData)
          dispatch(sessionUser(sessionData))
        }
      })
      .catch(e => {
        dispatch(sessionError(e.data))
      })
  }

export const loginActiveDirectory = (username: string, password: string) => async (dispatch: Function) => {
  dispatch(setLoading('pending'))

  return loginService
    .loginActiveDirectory(username, password)
    .then((data: SessionData) => {
      const response = !!data?.user?.id

      if (!response) return dispatch(sessionError({ error: 'unauthorizedProfileActiveDirectory' }))

      const userSession = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData

      const userSessionActiveDirectory = {
        ...data.user,
        ...userSession,
        user: {
          ...data.user,
          successDirectorySuggestion: true,
          user_in_active_directory: true,
          active_directory_suggestion: false
        }
      }

      dispatch(sessionUser(userSessionActiveDirectory))
      LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', userSessionActiveDirectory)

      dispatch(sessionError(null))

      return Promise.resolve()
    })
    .catch(errorLog => {
      if (errorLog?.data?.error === 'external username already exists') {
        dispatch(sessionError({ error: 'externalExistsActiveDirectory' }))
        return
      }

      dispatch(sessionError({ error: 'unauthorizedProfileActiveDirectory' }))
    })
}

export const setDefaultPlatformOfficeId =
  (params: { defaultPlatformId?: number; defaultOfficeId?: number }) => async (dispatch: Function) => {
    const userStore = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
    const userSession = {
      ...userStore,
      user: {
        ...userStore?.user,
        access: userStore?.user.access.map((item, index) => {
          return {
            ...item,
            default_office_id: item?.platforms?.some(p => p.offices.some(o => o.id === params.defaultOfficeId))
              ? params.defaultOfficeId
              : undefined,
            default_platform_id: item?.platforms?.some(p => p.id === params.defaultPlatformId)
              ? params.defaultPlatformId
              : undefined
          }
        })
      }
    }

    return loginService
      .defaultPlatformOrOffice(params)
      .then((response: boolean) => {
        if (!response) return dispatch(sessionError({ error: 'unauthorizedProfile' }))

        dispatch(sessionUser(userSession))
        LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', userSession)
        setAxiosAuth(userSession.token)

        return Promise.resolve()
      })
      .catch(e => {
        dispatch(sessionError(e.data))
      })
  }

export const resetAuthenticationErrors = () => (dispatch: Function) => {
  return dispatch(authenticationError(null))
}

export const authenticate =
  (username: string, password: string, sessionData?: SessionData) => async (dispatch: Function) => {
    dispatch(setAuthenticationLoading('pending'))
    return authenticationService
      .authenticate(username, password)
      .then(async data => {
        dispatch(
          authenticationData({
            access_token: '271350fba3a5ee24ac4f1ae8a5e295f0124c2b34'
          })
        )

        LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionData)

        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch(authenticationError(error))
      })
  }

export const authenticateBirdID =
  (username: string, password: string, lifetime: string, sessionData?: SessionData) => async (dispatch: Function) => {
    dispatch(setAuthenticationLoading('pending'))
    return authenticationService
      .authenticateBirdID(username, password, lifetime, sessionData?.token)
      .then(async data => {
        dispatch(
          authenticationData({
            access_token: data.access_token
          })
        )

        LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionData)

        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch(authenticationError(error))
      })
  }

export const createSessionDataStorage = (sessionData?: SessionData | null) => async (dispatch: Function) => {
  dispatch(setAuthenticationLoading('pending'))

  LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionData)

  dispatch(setAuthenticationLoading('success'))
}

export const logoutThunk = (messageError?: string) => (dispatch: Function) => {
  loginService.logout()
  dispatch(setResetPermissions(null))
  dispatch(setResetState(null))
  dispatch(authenticationData(null))

  if (messageError) {
    dispatch(sessionError({ error: messageError }))
  }
}

export const setCertification = (certificate: string) => (dispatch: Function) => {
  addCertificate(certificate)
  dispatch(sessionCertification(certificate))
}

export const setLoginWithoutBirdId = (sessionData: SessionData | null) => (dispatch: Function) => {
  dispatch(createSessionDataStorage(sessionData))
  dispatch(setLoading('success'))
}

export const selectUserSession = (state: RootState): SessionData | null => state.session.userSession
export const selectCertification = (state: RootState): string | null => state.session.certification
export const selectSessionErrors = (state: RootState) => state.session.errors
export const selectAuthentication = (state: RootState): VerificaAuthData | null => state.authentication.data
export const selectAuthenticationErrors = (state: RootState) => state.authentication.errors
export const selectLoading = (state: RootState): 'failing' | 'success' | 'pending' | 'idle' => state.session.loading
export const selectAuthenticationLoading = (state: RootState): string => state.authentication.loading
export const selectContextSSO = (state: RootState): boolean => state.session.isContextSSO
export const selectPermissions = (state: RootState): PermissionsData | null => state.permissions.data
export default sessionSlice.reducer
export const selectCurrentPlatform = (state: RootState): number | undefined => state.selectPlatform.currentPlatform.id
