import React, { useState } from 'react'
import { Tooltip, Modal } from '@cockpit/zera'

import { SupportText, Feedback, FeedbackIcon, SubTitle, SupportContact, Title, ModalContainer } from './styles'
import useResize from '../../_layouts/useResize'
import { message } from '../../../messages'
import { CopyLink } from '../../../shared/CopyLink'
import { openWhatsSupport } from '../../Footer/common/functions'
import { openPhoneSupport } from './functions'

interface Props {
  show: boolean
  onClose: () => void
}

export const ErrorModal = ({ show, onClose }: Props) => {
  const windowSize = useResize()
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  return (
    <ModalContainer>
      <Modal
        size="small"
        customWidth={windowSize > 650 ? 526 : 500}
        useMask
        show={show}
        closeClickMask={false}
        onClose={onClose}
        isMobileFullscreen
        headerBorderBottom={windowSize <= 500}
        modalBodyPadding="32px 0px"
        customFullscreenCloseButton={false}
      >
        <Feedback>
          <FeedbackIcon name="attention_triangle" iconsize="63px" />
          <Title isSemiBold>{message('errorModal.title')}</Title>
          <React.Fragment>
            <SubTitle>{message('errorModal.subTitle')}</SubTitle>
            <SupportText>
              {message('errorModal.content')}
              <CopyLink link={message('login.email')} setIsLinkCopied={setIsLinkCopied}>
                <Tooltip
                  cursor="pointer"
                  opacity={0.9}
                  description={isLinkCopied ? 'Link copiado' : 'Copiar link'}
                  position="top"
                >
                  {message('login.email')}
                </Tooltip>
              </CopyLink>
            </SupportText>
            <SupportContact>
              <span onClick={() => openPhoneSupport()}>{message('login.phone')}</span> |{' '}
              <span onClick={() => openWhatsSupport()}>{message('login.whatsNumber')}</span>
            </SupportContact>
            <SupportContact>{message('login.openingHours')}</SupportContact>
          </React.Fragment>
        </Feedback>
      </Modal>
    </ModalContainer>
  )
}
