import { UserAvatar, theme, Button, Input, Tooltip, IconExporter } from '@cockpit/zera'
import React from 'react'
import { get, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectUserSession } from '../../../../modules/login/sessionSlice'
import { Separator, Title } from '../../../../styles/shared'
import { EmojiRegex } from '../../../../utils/regex'
import useResize from '../../../_layouts/useResize'
import { messages } from '../../constants/Messages'
import { useEditUserModal } from '../../context'
import { getUserProfileName } from '../../utils'
import {
  ContainerUserAvatarEditModal,
  ProfileDataUser,
  TitleName,
  ButtonBoxSignature,
  FormContainer,
  TitleForm,
  InputContentGrid,
  InputBox,
  ErrorMessage,
  ButtonBox,
  ContainerMessages,
  SuccessMessage,
  DisplayBoxUserNameWithIcon,
  UserSidebarAvatar,
  ErrorMessageRequeridField
} from './styles'

export interface IForm {
  name: string
  email: string
  phone: string
  classCode: string
}

export const UserEditModal: React.FC = (): JSX.Element => {
  const { register, handleSubmit, formState, watch } = useForm<IForm>()

  const sessionData = useSelector(selectUserSession)

  const windowSize = useResize()

  const {
    getOpenSignatureModal,
    isLoading,
    phoneErrorMessage,
    updatePerfilError,
    updatePerfilSuccess,
    classCodeValidation,
    setPhoneErrorMessage,
    setClassCodeValidation,
    setPhone,
    onSubmitUserEdit,
    setUpdatePerfilSuccess,
    setUpdatePerfilError
  } = useEditUserModal()

  const watchClassCode = watch('classCode')

  React.useEffect(() => {
    setUpdatePerfilSuccess(false)
    setUpdatePerfilError('')
  }, [])

  React.useEffect(() => {
    if (watchClassCode?.match(EmojiRegex)) {
      setClassCodeValidation(false)
    } else {
      setClassCodeValidation(true)
    }
  }, [watchClassCode])

  const handleInputPhone = React.useCallback(event => {
    if (event.target.value.length > 2 && event.target.value.length < 15) {
      setPhoneErrorMessage(messages.incompletedPhone)
    } else if (event.target.value.length <= 1) {
      setPhoneErrorMessage(messages.requiredField)
    } else {
      setPhone(event.target.value)
      setPhoneErrorMessage('')
    }
  }, [])

  return (
    <>
      <ContainerUserAvatarEditModal>
        <UserSidebarAvatar>
          <UserAvatar
            name={sessionData?.user.name ?? ''}
            image={
              sessionData?.user.avatar && sessionData?.user.avatar !== 'avatar_path' ? sessionData?.user.avatar : ''
            }
          />
        </UserSidebarAvatar>
        <ProfileDataUser>
          <TitleName>{sessionData?.user.name}</TitleName>
          <Title fontSize="14px" color={theme.colors.gray4} marginTop="2%">
            {getUserProfileName(sessionData?.user)}
          </Title>
        </ProfileDataUser>
        {sessionData?.user?.signature?.length && (
          <ButtonBoxSignature>
            <Button
              data-testid="signature-modal-click"
              type="button"
              minWidth="small"
              variant="outlined"
              onClick={() => getOpenSignatureModal(true)}
            >
              {messages.openSignatureModal}
            </Button>
          </ButtonBoxSignature>
        )}
      </ContainerUserAvatarEditModal>
      <Title
        textTransform="uppercase"
        marginBottom={theme.spacing.medium}
        marginTop={theme.spacing.large}
        fontWeight="bold"
        color="#6B7786"
      >
        {messages.dataProfile}
      </Title>
      <Separator />
      <FormContainer onSubmit={handleSubmit(onSubmitUserEdit)}>
        <TitleForm>{messages.fullName}</TitleForm>
        <Input
          width={'470px'}
          {...register('name', {
            required: messages.requiredField,
            pattern: {
              value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
              message: messages.numberOfCharactersRequired
            }
          })}
          type={'text'}
          defaultValue={sessionData?.user.name}
          error={get(formState.errors, 'name')}
        />
        {get(formState.errors, 'name') && (
          <ErrorMessageRequeridField role="alert">{get(formState.errors, 'name')?.message}</ErrorMessageRequeridField>
        )}
        <InputContentGrid>
          <InputBox>
            <DisplayBoxUserNameWithIcon>
              <TitleForm>{messages.userName}</TitleForm>
              <Tooltip
                position={windowSize >= 768 ? 'right' : 'top'}
                description={'Nome utilizado para realizar o login'}
              >
                <IconExporter margin="0 0 8px 10px" name="info_icon" iconsize="18px" />
              </Tooltip>
            </DisplayBoxUserNameWithIcon>
            <Input disabled type={'text'} defaultValue={sessionData?.user.username} />
          </InputBox>
          <InputBox>
            <TitleForm>{messages.email}</TitleForm>
            <Input
              {...register('email', {
                required: messages.requiredField,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: messages.invalidEmail
                }
              })}
              type="email"
              defaultValue={sessionData?.user.email}
              error={get(formState.errors, 'email')}
            />
            {get(formState.errors, 'email') && (
              <ErrorMessageRequeridField role="alert">
                {get(formState.errors, 'email')?.message}
              </ErrorMessageRequeridField>
            )}
          </InputBox>
        </InputContentGrid>
        <InputContentGrid>
          <InputBox>
            <TitleForm>{messages.CPF}</TitleForm>
            <Input disabled type={'number'} defaultValue={sessionData?.user.personal_document.identifier} />
          </InputBox>
          <InputBox>
            <TitleForm>{messages.phone}</TitleForm>
            <Input
              {...register('phone', {
                required: true
              })}
              data-testid="phone-input"
              mask="(99) 99999-9999"
              defaultValue={sessionData?.user.phone}
              onChange={handleInputPhone}
              type="tel"
              error={phoneErrorMessage !== ''}
            />
            {phoneErrorMessage !== '' && (
              <ErrorMessageRequeridField role="alert">{phoneErrorMessage}</ErrorMessageRequeridField>
            )}
          </InputBox>
        </InputContentGrid>
        {sessionData?.user?.health_professional_data?.class_codes[0] ? (
          <>
            <Title
              textTransform="uppercase"
              marginBottom={theme.spacing.medium}
              marginTop={theme.spacing.large}
              fontWeight="bold"
              color="#6B7786"
            >
              {messages.professionalData}
            </Title>
            <Separator />
            <InputContentGrid>
              <InputBox>
                <TitleForm>{messages.profile}</TitleForm>
                <Input
                  disabled
                  defaultValue={sessionData?.user?.health_professional_data?.class_codes[0]?.description}
                />
              </InputBox>
              <InputBox>
                <TitleForm>{messages.classCode}</TitleForm>
                <Input
                  {...register('classCode', {
                    // required: messages.requiredField
                  })}
                  disabled
                  defaultValue={sessionData?.user?.health_professional_data?.class_codes[0]?.code}
                  error={get(formState.errors, 'classCode')}
                />
                {get(formState.errors, 'classCode') && (
                  <ErrorMessageRequeridField role="alert">
                    {get(formState.errors, 'classCode')?.message}
                  </ErrorMessageRequeridField>
                )}
              </InputBox>
            </InputContentGrid>
          </>
        ) : null}
        <ButtonBox>
          <ContainerMessages>
            {updatePerfilError && (
              <ErrorMessage>
                <Title color={theme.colors.red1} fontSize="12px">
                  {updatePerfilError === 'emailError' ? messages.updatePerfilEmailError : messages.updatePerfilError}
                </Title>
              </ErrorMessage>
            )}
            {updatePerfilSuccess && (
              <SuccessMessage>
                <Title color={theme.colors.green1} fontSize="12px">
                  {messages.updatePerfilSuccess}
                </Title>
              </SuccessMessage>
            )}
          </ContainerMessages>
          <Button
            disabled={
              !!(get(formState.errors, 'name') ?? phoneErrorMessage !== '') ||
              (get(formState.errors, 'email') ?? !classCodeValidation)
            }
            type="submit"
            isLoading={isLoading}
          >
            {messages.save}
          </Button>
        </ButtonBox>
      </FormContainer>
    </>
  )
}
