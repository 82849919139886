import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3.5rem 2.875rem;
    text-align: center;

    svg {
        margin-bottom: 1.5rem;
    }

    @media (max-width: 500px) {
        height: 100%;
        margin-top: calc(3.5rem - 3rem);
        margin: calc(3.5rem - 3rem) 1.5rem 3.5rem;
    }
`

export const Title = styled.h1`
    color: #545E6C;
    font-weight: 500;
    font-size: 1.375rem;

    @media (max-width: 500px) {
        font-size: calc(1.375rem + 1px);
    }
`

export const Body = styled.div`
    padding: 1.5rem 0;
    margin-bottom: 0.5rem;
`

export const Paragraph = styled.p`
    color: #707070;
    text-align: center;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.4;
    width: 100%;

    & + & {
        margin-top: 1rem;
    }

    span {
        font-weight: 500;
    }

    * {
        font-family: Inter, sans-serif;
        font-weight: initial;
    }

    @media (max-width: 500px) {
        font-size: calc(1rem + 1px);
    }
`

export const Footer = styled.div`
    button {
        font-family: Inter, sans-serif;
        font-weight: initial;
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        display: flex;
        width: 100%;

        button {
            width: 100%;
            height: 3rem;
            margin: 1rem 0 0;
        }
    }
`
