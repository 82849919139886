import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { getPlatformIdByRoute } from '../../models/platformRoute'
import { setCurrentPlatform } from '../../modules/session/sessionSlice'
import { logoutThunk } from '../../modules/login/sessionSlice'
import { deleteLocalStorage } from '../../modules/local-storage/local-storage.service'
import { OfficeLocalStorage, SessionData } from '../../modules/login/Session'
import { withoutTheRouteParameter, sessionValidation } from './sessionValidation'
import writeOfficeLocalStorage from './writeOfficeInLocalStorage'
import LocalStorageManager from '../../helpers/localStorage'

/**
 * O objetivo é identificar em qual plataforma o usuário está e informar para o estado do redux quais são os dados da plataforma ou consultório selecionado.
 */
export const useGetRouteCorrect = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation() as { pathname: string; search: string }

  const sessionDataLocalStorage = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData

  const pathnameOffice = 'consultorio'
  const platforms = sessionDataLocalStorage?.user?.allPlatforms

  const handleCurrentPlatform = () => {
    const platformIdByRoute = getPlatformIdByRoute(location.pathname)

    if (sessionValidation() && platformIdByRoute) {
      LocalStorageManager.WriteEncryptedData('current-platform-encrypted', platformIdByRoute)
    }
  }

  useEffect(() => {
    handleCurrentPlatform()
  }, [location.pathname])

  useEffect(() => {
    const pathname = location.pathname.split('/')[1]

    const logout = () => dispatch(logoutThunk())

    if (!sessionValidation(undefined, logout) && sessionValidation(undefined, logout) !== null) {
      dispatch(logoutThunk('unauthorizedProfile'))

      history.push('/')
    } else if (pathname === pathnameOffice) {
      const userHasAccessOffice = platforms?.find(platform => platform.offices.length > 0)

      if (userHasAccessOffice) {
        const office = LocalStorageManager.ReadEncryptedData('office-encrypted') as OfficeLocalStorage

        const getRandomOffice = userHasAccessOffice.offices[0]
        if (getRandomOffice && !office) {
          writeOfficeLocalStorage({
            id: getRandomOffice.id,
            name: getRandomOffice.name,
            platformId: userHasAccessOffice.id,
            internal: getRandomOffice.internal,
            address: getRandomOffice?.address
          })

          dispatch(
            setCurrentPlatform({
              id: getRandomOffice.id,
              isOffice: true,
              name: getRandomOffice.name,
              internal: getRandomOffice.internal
            })
          )
        } else {
          dispatch(
            setCurrentPlatform({
              id: office?.id,
              isOffice: true,
              name: office?.name,
              internal: getRandomOffice.internal
            })
          )
        }
      }
    }

    if (pathname && pathname !== pathnameOffice) {
      const platformIdByRoute = getPlatformIdByRoute(pathname)
      const platform = platforms?.find(
        platform => platform.id === platformIdByRoute && !platform.access_only_to_offices
      )
      const isOffice = sessionDataLocalStorage?.user?.allPlatforms?.some(platform => platform.access_only_to_offices)
      if (isOffice) {
        history.push(`/${pathnameOffice}`)
      } else if (!platform && platformIdByRoute) {
        history.push(withoutTheRouteParameter())
      } else if (platform && platformIdByRoute) {
        dispatch(
          setCurrentPlatform({
            id: platform?.id,
            isOffice: false,
            name: platform?.description.split('Cockpit')[1],
            internal: true
          })
        )
      }

      deleteLocalStorage('office')
      deleteLocalStorage('office-encrypted')
    }
  }, [location.pathname])
}
