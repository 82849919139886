import React, { useEffect } from 'react'
import { BorderActiveColor, ContainerContent, ContainerFlex, TabsTitle, WrapperContent, ContainerBody, ContainerModal } from './styles'
import { useEditUserModal } from '../../context'
import { messages } from '../../constants/Messages'
import { SecurityEditModal } from '../SecurityEditModal/SecurityEditModal'
import { UserEditModal } from '../UserEditModal/UserEditModal'
import { IconExporter, ModalProps } from '@cockpit/zera'
import { Separator } from '../../../../styles/shared'
import { selectUserSession } from '../../../../modules/login/sessionSlice'
import { useSelector } from 'react-redux'
import { ResponsiveModal } from '../../../../shared/ResponsiveModal'

interface IHeaderModalProps extends ModalProps {}

export const HeaderEditModal: React.FC<IHeaderModalProps> = ({ ...rest }: IHeaderModalProps) => {
  const sessionData = useSelector(selectUserSession)
  const userInActiveDirectory = !!sessionData?.user.user_in_active_directory

  const {
    setOpenUserEditModal,
    isOpenFromHeader,
    setIsOpenFromHeader,
    // openSignatureModal,
    setClickShowContentModal,
    clickShowContentModal
  } = useEditUserModal()

  useEffect(() => {
    if (isOpenFromHeader) {
      return setIsOpenFromHeader(false)
    }
  }, [])

  return (
    <ContainerModal>
      <ResponsiveModal
        {...rest}
        closeOnClickMask={rest.closeClickMask ?? false}
        onClose={() => setOpenUserEditModal(false)}
        hideCloseIcon
        maxW={'570px'}
        width={'570px'}
        padding={'0'}
      >
        <ContainerFlex>
          <IconExporter
            onClick={rest.onClose}
            name="close"
            width="14px"
            data-testId="modal-edit-desktop-close-svg-modal-button"
          />
          <BorderActiveColor isBorderActive={clickShowContentModal}>
            <TabsTitle onClick={() => setClickShowContentModal(true)}>{messages.profileEdit}</TabsTitle>
          </BorderActiveColor>
          {!sessionData?.user.user_in_active_directory && (
            <BorderActiveColor isBorderActive={!clickShowContentModal}>
              <TabsTitle onClick={() => setClickShowContentModal(false)}>
                {isOpenFromHeader ? messages.settings : messages.seurityData}
              </TabsTitle>
            </BorderActiveColor>
          )}
        </ContainerFlex>
        <Separator />
        <ContainerBody>
          <ContainerContent overflowInitial={userInActiveDirectory && !clickShowContentModal}>
            <WrapperContent hidden={!clickShowContentModal}>
              <UserEditModal />
            </WrapperContent>
            {!sessionData?.user.user_in_active_directory && (
              <WrapperContent hidden={clickShowContentModal}>
                <SecurityEditModal />
              </WrapperContent>
            )}
          </ContainerContent>
        </ContainerBody>
      </ResponsiveModal>
    </ContainerModal>
  )
}
