const removePrefixDoctor = (str: string): string => {
  return str
    .toLocaleLowerCase()
    .replace('dr ', '')
    .replace('dra ', '')
    .replace('dr. ', '')
    .replace('dra. ', '')
    .trim()
}

export default removePrefixDoctor
