import React from 'react'

import { Button } from '@cockpit/zera'

import Icons from '../../../assets/icons'
import { message } from '../../../messages'

import { Container, Title, Body, Paragraph, Footer } from './styles'
import { ResponsiveModal } from '../../../shared/ResponsiveModal'
import useResize from '../../_layouts/useResize'

const { Danger: IconDanger } = Icons

interface Props {
  showModal: boolean
  onClose: () => void
}

export const IncorrectPasswordModal: React.FC<Props> = ({ showModal, onClose }) => {
  const windowSize = useResize()
  const isMobile = windowSize < 500

  return (
    <ResponsiveModal
      show={showModal}
      onClose={onClose}
      closeOnClickMask={false}
      width='542px'
    >
      <Container>
        <IconDanger height={56} />
        <Title>{message('incorrectPassword.title')}</Title>
        <Body>
          {!isMobile ? (
            <>
              <Paragraph>
                Você possui apenas <span>mais uma tentativa</span>.<br />Caso o nome de usuário e/ou senha estejam incorretos,<br />
                seu usuário será bloqueado.
              </Paragraph>
              <Paragraph>
                Para evitar o bloqueio, recomendamos que redefina sua<br />senha utilizando a opção <span>“Esqueceu a senha?”</span>
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph>
                Você possui apenas <span>mais uma tentativa</span>.<br />Caso o nome de usuário e/ou senha estejam incorretos,
                seu usuário será bloqueado.
              </Paragraph>
              <Paragraph>
                Para evitar o bloqueio, recomendamos que redefina sua senha utilizando a<br />opção <span>“Esqueceu a senha?”</span>
              </Paragraph>
            </>
          )}
        </Body>
        <Footer>
          <Button type="button" onClick={onClose}>
            {message('incorrectPassword.button-confirm')}
          </Button>
        </Footer>
      </Container>
    </ResponsiveModal>
  )
}
