import { createBrowserHistory } from 'history'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import MicroFrontend from '../../components/Microfront/Microfront'
import ENV from '../../modules/env'
import { SessionData } from '../../modules/login/Session'
import { selectUserSession } from '../../modules/login/sessionSlice'

const defaultHistory = createBrowserHistory()
const Exam: React.FC<any> = ({ history }): ReactElement => {
	const userSession: SessionData | null = useSelector(selectUserSession)

	return (
		<MicroFrontend
			name={'ANewHope'}
			host={ENV.TELEMEDICINA_HOST}
			history={history || defaultHistory}
			user={userSession}
		></MicroFrontend>
	)
}
export default Exam
