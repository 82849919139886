import React, { ReactElement } from 'react'

import UserMenuContainer from '../UserMenuContainer/UserMenuContainer'
import { StyledHeader, UserMenuContent } from './styles'
import SelectPlatform from './SelectPlatform'
import { EditUserModalProvider, SecurityEditModalProvider } from '../EditUserModal/context'

import ModalsEditUser from './ModalsEditUser'
import { useContextSSO } from '../../pages/Login/hooks'

const Header: React.FC = (): ReactElement => {
	const contextSSO = useContextSSO()
  const queryParamsSSO = contextSSO[1]

	if (queryParamsSSO.target === 'suporte-decisao') {
		return <React.Fragment />
	}

	return (
		<EditUserModalProvider>
			<SecurityEditModalProvider>
				<StyledHeader>
					<SelectPlatform />
					<UserMenuContent>
						{/* <SwitchBirdId/> */}
						<UserMenuContainer />
					</UserMenuContent>
				</StyledHeader>
				<ModalsEditUser />
			</SecurityEditModalProvider>
		</EditUserModalProvider>
	)
}

export default Header
