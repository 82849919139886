import styled from 'styled-components'
import ENV from '../../modules/env'

const ImageLoginBackground = `${ENV.IMAGES_URL}/images/login-background.jpg`

export const LoginSection = styled.section`
  position: relative;
  background-image: url(${ImageLoginBackground});
  background-size: cover;
`
export const FormContainer = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoginLogo = styled.h1`
  position: relative;
  width: 400px;
  height: 50px;
  text-align: center;
  & img {
    height: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 65px;
  }
  @media (max-width: 767px) {
    height: 45px;
  }
  @media (max-width: 320px) {
    height: 40px;
  }
  @media (max-width: 280px) {
    height: 35px;
  }
`

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0; 
`

export const Mask = styled.div`
  background: transparent linear-gradient(0deg, #001434 0%, #0057ff33 100%) 0%
    0% no-repeat padding-box;
  position: absolute;
  width: 100%;
  min-height: 100%;
  z-index: 0;
`

export const LoginForm = styled.div`
  width: 300px;
  text-align: center;
  margin-bottom: 30px;
  & h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  & button {
    margin: 16px 0;
    display: block;
    font-weight: 600;
    height: 48px;
    width: 100%;
    cursor: pointer;
  }

  & a {
    font-size: 14px;
    color: #fff;
    text-align: center;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  @media (max-width: 375px) {
    width: 250px;
  }
  @media (max-width: 280px) {
    width: 220px;
  }
`

export const FormLabel = styled.label`
  color: #000;
  text-align: left;
  font: normal normal 600 14px/15px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: block;
  margin-bottom: 6px;
`
export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: -12.5%;
    margin: 0 -100% 1% 0;

    span {
      text-align: left;
    }

    > span {
      > span {
        margin-top: -20%;
        hyphens: none !important;
        @media (max-width: 414px) {
          margin-top: 78%;
          right: 7%;
        }
        @media (max-width: 320px) {
          margin-top: 78%;
          right: 7%;
        }
      }
    }
    
    @media (max-width: 414px) {
      left: -14.5%;
    }
    @media (max-width: 320px) {
      left: -16.5%;
    }
    div {
      width: 40px;
      height: 100%;
      svg {
        margin-top: 8px;
        margin-right: -4px;
        transition: all 0.5s;
        
        path {
          stroke: #909297;

        }
      }
    }
  }
  > svg {
    position: relative;
    left: -13%;
    width: 42px;
    margin: 0 -100% 1% 0;
    transition: all 0.5s;
    @media (min-width: 1025px) {
      :hover {
        cursor: pointer;
      }
    }
    @media (max-width: 414px) {
      left: -15%;
    }
    @media (max-width: 320px) {
      left: -17%;
    }
  }
  input {
    height: 45px;
    padding: 0 36px 0 16px;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }
`

export const SupportFooter = styled.div`

  text-align: center;

  >p {
    display: flex;
    justify-content: center;

    a {
      margin-left: 3px;
    }

    >span{
      height: 22px;
      line-height: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;
      + span{
        border-left: 1px solid white;
        cursor: pointer;
        transition: all .5s;
        :hover{
          filter: brightness(120%);
        }
      }
      svg{
        margin-right: 3px;
      }
    }
  }

  >p + p{
    margin-top: 4px;
  }

  @media(max-width: 700px){
    bottom: 41px;

    >p{
      width: fit-content;
      height: 20px;
      margin: 0 auto;
      svg{
        margin-right: 4px;
      }
      align-items: center;
    }
  }
`

export const Footer = styled.footer`

  color: #DFE3E8;
  text-align: center;
  width: 90%;

  p {
    font-size: 12px;
  }

  @media (max-width: 414px) {
  }
  address {
    line-height: 20px;
    margin-bottom: 20px;
  }
  a {
    color: #62B8FF;
    text-decoration: none;
  }

  @media(max-width: 700px){
    bottom: 22px;
    p{
      font-size: 14px;
    }
  }
`
export const ErrorMsg = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe9e9;
  color: #ff1a1e;
  padding: 12px;
  border-radius: 4px;
  margin: 8px 0;
  max-width: 300px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  text-align: center;

  @media (max-width: 321px) {
    width: 255px;
  }
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const PasswordViewer = styled.div`
  position: relative;
  left: -13%;
  width: 42px;
  margin: 0 -100% 1% 0;
  transition: all 0.5s;
  @media(min-width: 1025px){
    :hover{
      cursor: pointer;
    }
  }
  @media (max-width: 414px) {
    left: -15%;
  }
  @media (max-width: 320px) {
    left: -17%;
  }
`
