import React, { Dispatch } from 'react'
import { LinkContainer } from './style'

interface ICopyLinkProps {
  children: React.ReactNode
  link: string
  setIsLinkCopied?: Dispatch<boolean>
  setCopyError?: Dispatch<boolean>
}

export const CopyLink = ({ children, link, setIsLinkCopied, setCopyError }: ICopyLinkProps) => {
  const handleIsLinkCopied = () => {
    if (setIsLinkCopied) {
      setIsLinkCopied(true)
      setTimeout(() => {
        setIsLinkCopied(false)
      }, 3000)
    }
  }
  const handleCopyLink = (link: string) => {
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(link)
      handleIsLinkCopied()
    } else if (document.queryCommandSupported?.('copy')) {
      var textarea = document.createElement('textarea')
      textarea.textContent = link
      textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand('copy') // Security exception may be thrown by some browsers.
        handleIsLinkCopied()
      } catch (e) {
        // eslint-disable-next-line no-empty
      } finally {
        document.body.removeChild(textarea)
      }
    }
  }

  return (
    <LinkContainer onClick={() => handleCopyLink(link)} data-testid="link-text">
      {children}
    </LinkContainer>
  )
}
