import { AxiosRequestConfig, AxiosResponse } from 'axios'
import ENV from '../env'
import api, { errorHandling } from '../http/api'
import { SessionData } from './Session'
import { User } from './User'

import LocalStorageManager from '../../helpers/localStorage'
import { Features } from './Features'

const BASE_ENDPOINT = '/sessions/'
export interface Login {
  username: string
  password: string
}

interface LoginSSO {
  signature: string
  external_username: string
}

interface IDefaultPlatform {
  access: Array<{
    profile_id: number
    platforms: [
      {
        platform_id: number
      },
      {
        platform_id: number
      }
    ]
    default_platform_id?: number | null
    default_office_id?: number | null
  }>
  health_professional_data: {
    class_codes: Array<{
      type_id: number
      origin: string
      code: string
    }>
  }
}

const post = async (username: string, password: string): Promise<SessionData> => {
  const loginData = {
    username: username,
    password: password
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN,
    headers: {
      'Content-Type': 'application/json',
      'active-directory': 'true'
    }
  }

  return api
    .post<Login, AxiosResponse<SessionData>>(BASE_ENDPOINT, loginData, requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

const postActiveDirectory = async (username: string, password: string): Promise<SessionData> => {
  const loginData = {
    username: username,
    password: password
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN
  }

  return api
    .post<Login, AxiosResponse<SessionData>>('/session/activeDirectory', loginData, requestConfig)
    .then(async response => Promise.resolve(response.data))
    .catch(errorHandling)
}

const putDefaultPlatformOrOffice = async (params: {
  defaultPlatformId?: number
  defaultOfficeId?: number
}): Promise<boolean> => {
  const user = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted')?.user
  const access = user?.access
  const healthProfessionalData = user?.health_professional_data

  const putData = {
    access: access?.map(item => ({
      profile_id: item.profile.id,
      platforms: item?.platforms?.map(platform => {
        if (platform.offices.length) {
          return {
            platform_id: platform.id,
            offices: platform.offices.map(office => ({
              id: office.id
            }))
          }
        }

        return {
          platform_id: platform.id
        }
      }),
      default_platform_id: item?.platforms?.some(p => p.id === params.defaultPlatformId)
        ? params.defaultPlatformId
        : undefined,
      default_office_id: item?.platforms?.some(p => p.offices.some(o => o.id === params.defaultOfficeId))
        ? params.defaultOfficeId
        : undefined
    })),
    health_professional_data: {
      class_codes: healthProfessionalData?.class_codes.map(item => ({
        type_id: item.classification_id,
        origin: item.origin,
        code: item.code
      }))
    }
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN
  }

  return api
    .put<IDefaultPlatform, AxiosResponse>(`/user/${user?.id}`, putData, requestConfig)
    .then(response => true)
    .catch(errorHandling)
}

const postSSOCernerMiddleware = async (signature: string, externalUsername: string): Promise<SessionData> => {
  const loginSSOData = {
    signature: signature,
    external_username: externalUsername
  }

  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN
  }

  return api
    .post<LoginSSO, AxiosResponse<SessionData>>('/session/sso', loginSSOData, requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

const updateUser = async (userId: number, requestData: object, token: string): Promise<User> => {
  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  return api
    .put<LoginSSO, AxiosResponse<User>>(`/user/${userId}`, requestData, requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

const getFeatures = async (token: string): Promise<Features> => {
  const requestConfig: AxiosRequestConfig = {
    baseURL: ENV.API_LOGIN,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  return api
    .get<Login, AxiosResponse<Features>>('/authorization', requestConfig)
    .then(response => response.data)
    .catch(errorHandling)
}

export const loginApi = {
  post,
  postActiveDirectory,
  putDefaultPlatformOrOffice,
  postSSOCernerMiddleware,
  updateUser,
  getFeatures
}
