import React, { useState } from 'react'
import { Tooltip, IconExporter } from '@cockpit/zera'
import useResize from '../_layouts/useResize'

import { Container, SupportFooter } from './styles'
import { message } from '../../messages'
import { CopyLink } from '../../shared/CopyLink'
import { openWhatsSupport } from './common/functions'
import { FooterModal } from './FooterModal'

interface Props {
    isFocused: boolean
}
const Footer: React.FC<Props> = ({ isFocused }) => {
    const windowSize = useResize()
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)
    const [showFooterModal, setShowFooterModal] = useState(false)

    return (
        <>
            <FooterModal show={showFooterModal} onClose={() => setShowFooterModal(false)} />
            <Container isFocused={isFocused}>
                {windowSize > 700 ? (
                    <>
                        <SupportFooter>
                            <p>{message('login.contactUs')}
                            <CopyLink link={message('login.email')} setIsLinkCopied={setIsLinkCopied}>
                                <Tooltip cursor='pointer' opacity={0.9} description={isLinkCopied ? 'Link copiado' : 'Copiar link'} position='top'>
                                    {message('login.email')}
                                </Tooltip>
                            </CopyLink>
                            </p>
                            <p>
                            <span>{message('login.phone')}</span>
                            <span data-testid='whats_number' onClick={() => openWhatsSupport()}><IconExporter name='whats_icon' iconsize={13} />{message('login.whatsNumber')}</span>
                            <span>{message('login.openingHours')}</span>
                            </p>
                        </SupportFooter>
                        <p>
                            {message('login.webFooterEinsteinRights')}
                        </p>
                    </>
                ) : (
                    <>
                        <SupportFooter>
                            <p onClick={() => setShowFooterModal(true)}>
                            <IconExporter name='interrogation' iconsize={17.5} />{message('login.helpCenter')}
                            </p>
                        </SupportFooter>
                        <p>
                            {message('login.mobileFooterEinsteinRights')}
                        </p>
                    </>
                )}
            </Container>
        </>
    )
}

export default Footer
