import { AxiosRequestConfig, AxiosResponse } from 'axios'
import ENV from '../env'
import api, { errorHandling } from '../http/api'

const AUTH_BASE_ENDPOINT = '/user/'

const put = async (userId: number, requestData: object, token: string): Promise<any> => {
  const endPoint = `${AUTH_BASE_ENDPOINT}${userId}`

	const requestConfig: AxiosRequestConfig = {
		baseURL: ENV.API_USER_AUTH,
    headers: {
      Authorization: `Bearer ${token}`
    }
	}
	return api
		.put<AxiosResponse<any>>(endPoint, requestData, requestConfig)
		.then((response: any) => {
      if (response.data?.error) {
        throw new Error(response.data?.error)
			}
			return response.data
		})
		.catch(errorHandling)
}

export const updateUser = {
	put
}
