import { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import guardTelemedicina from '../modules/resolvers/TelemedicinaGuard'
import guardClinicas from '../modules/resolvers/ClinicasGuard'
import guardCorpoClinico from '../modules/resolvers/CorpoClinicoGuard'
import guardHMVSC from '../modules/resolvers/HMVSCGuard'
import Clinics from '../pages/Clinics'
import HMVSC from '../pages/HMVSC'
import Exam from '../pages/Exam'
import Queue from '../pages/Queue'
import { RouteWrapper, PlatformOrLogin } from './Route'
import { ForgotPassword } from '../pages/ForgotPassword/ForgotPassword'
import ClinicalStaff from '../pages/ClinicalStaff'
import { Security } from '../pages/Security'
import ENV from '../modules/env/index'
import ClinicalOnco from '../pages/ClinicalOnco'
import UserManual from '../pages/UserManual'
import { useGetRouteCorrect } from '../shared/functions/hooks'
import { message } from '../messages'
import FormFramework from '../pages/FormFramework'
import Financial from '../pages/ClinicalStaff/financial'

export const Routes: FC = () => {
  useGetRouteCorrect()

  return (
    <Switch>
      <Route exact path="/" component={PlatformOrLogin} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/manual-de-uso-corpo-clinico"
        component={() => {
          return <UserManual title={message('manual.clinicalStaff')} url={ENV.CLINICAL_STAFF_USER_MANUAL as string} />
        }}
      />
      <Route
        exact
        path="/manual-de-uso-cuidado-integrado"
        component={() => {
          return <UserManual title={message('manual.integratedCare')} url={ENV.INTEGRATED_CARE_USER_MANUAL as string} />
        }}
      />
      <Route
        exact
        path="/pre-venda"
        component={() => {
          window.location.href = ENV.LANDING_PAGE_HOST ?? ''
          return null
        }}
      />

      <RouteWrapper protectedRoute guard={guardTelemedicina} path="/telemedicina" component={Queue} />

      {/* <RouteWrapper protectedRoute path="/perfil" component={Perfil} /> */}
      <RouteWrapper protectedRoute path="/seguranca" component={Security} />
      <RouteWrapper guard={guardClinicas} protectedRoute path="/clinica-einstein" component={Clinics} />
      <RouteWrapper guard={guardHMVSC} protectedRoute path="/hmvsc" component={HMVSC} />
      <RouteWrapper guard={guardClinicas} protectedRoute path="/agenda" component={Clinics} />
      <RouteWrapper protectedRoute path="/atendimento" component={Exam} />
      <RouteWrapper guard={guardClinicas} protectedRoute path="/historico" component={Clinics} />
      <RouteWrapper guard={guardCorpoClinico} protectedRoute path="/oncologia" component={ClinicalOnco} />
      <BrowserRouter forceRefresh>
        <RouteWrapper
          guard={guardCorpoClinico}
          protectedRoute
          path={['/corpo-clinico', '/consultorio']}
          component={ClinicalStaff}
        />
        <RouteWrapper protectedRoute path="/formulario" component={FormFramework} />
        <RouteWrapper guard={guardCorpoClinico} protectedRoute path="/financeiro" component={Financial} />
      </BrowserRouter>
    </Switch>
  )
}
