import React, { ReactNode, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSession, sessionUser } from '../../../../modules/login/sessionSlice'
import { User } from '../../../../modules/login/User'
import { UpdateUser } from '../../../../modules/updateUser/updateUser.service'
import { formattedEditUser } from '../../utils'
import { IForm } from '../../components/UserEditModal/UserEditModal'
import LocalStorageManager from '../../../../helpers/localStorage'
import { SessionData } from '../../../../modules/login/Session'

interface IEditUserModalHelpers {
    openSignatureModal: boolean
    openUserEditModal: boolean
    clickShowContentModal: boolean
    isLoading: boolean
    isOpenFromHeader: boolean
    phoneErrorMessage: string
    updatePerfilError: string
    updatePerfilSuccess: boolean
    classCodeValidation: boolean
    phone: string
    setIsOpenFromHeader: React.Dispatch<React.SetStateAction<boolean>>
    setOpenSignatureModal: React.Dispatch<React.SetStateAction<boolean>>
    setOpenUserEditModal: React.Dispatch<React.SetStateAction<boolean>>
    setClickShowContentModal: React.Dispatch<React.SetStateAction<boolean>>
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setPhoneErrorMessage: React.Dispatch<React.SetStateAction<string>>
    setUpdatePerfilError: React.Dispatch<React.SetStateAction<string>>
    setUpdatePerfilSuccess: React.Dispatch<React.SetStateAction<boolean>>
    setClassCodeValidation: React.Dispatch<React.SetStateAction<boolean>>
    setPhone: React.Dispatch<React.SetStateAction<string>>
}

export interface IEditUserModalProps extends IEditUserModalHelpers {
  onSubmitUserEdit: (data: IForm) => Promise<void>
  getOpenSignatureModal: (value: boolean) => void
  getOpenEditModal: (value: boolean) => void
}

interface IEditUserModalProviderProps {
  children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const EditUserModalContext = createContext<IEditUserModalProps>({} as IEditUserModalProps)

const EditUserModalProvider: React.FC<IEditUserModalProviderProps> = ({ children }: IEditUserModalProviderProps) => {
  const sessionData = useSelector(selectUserSession)

  const [openUserEditModal, setOpenUserEditModal] = React.useState<boolean>(false)
  const [clickShowContentModal, setClickShowContentModal] = React.useState<boolean>(false)
  const [openSignatureModal, setOpenSignatureModal] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState<string>('')
  const [updatePerfilError, setUpdatePerfilError] = React.useState<string>('')
  const [updatePerfilSuccess, setUpdatePerfilSuccess] = React.useState<boolean>(false)
  const [classCodeValidation, setClassCodeValidation] = React.useState<boolean>(false)
  const [phone, setPhone] = React.useState<string>(sessionData?.user.phone ? sessionData?.user.phone : '')
  const [isOpenFromHeader, setIsOpenFromHeader] = React.useState<boolean>(false)

  const dispatch = useDispatch()

  const onSubmitUserEdit = async (data: IForm) => {
    try {
      setIsLoading(true)
      const requestData = formattedEditUser(sessionData, data, phone)
      const sessionObject: any = LocalStorageManager.ReadEncryptedData('session-encrypted')
      const response: User = sessionData?.user.id && await UpdateUser(sessionData?.user.id, requestData, sessionData.token)
      sessionObject.user = response
      LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionObject)
      dispatch(sessionUser(sessionObject))
      setUpdatePerfilError('')
      setUpdatePerfilSuccess(true)

      setTimeout(() => {
        setOpenUserEditModal(false)
      }, 4000)
    }
    catch (e: any) {
      setUpdatePerfilSuccess(false)
      setUpdatePerfilError(
        e?.data?.error === 'Email already exists.' ? 'emailError' : 'genericError'
      )
    }
    finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 800)
    }
  }

  const getOpenEditModal = (value: boolean): void => {
    if(value) {
      setClickShowContentModal(true)
      setOpenUserEditModal(true)
      setOpenSignatureModal(false)
    }
  }

  const getOpenSignatureModal = (value: boolean): void => {
    if(value) {
      setOpenSignatureModal(true)
      setOpenUserEditModal(false)
    }
  }

  return (
    <EditUserModalContext.Provider
    value={{
      getOpenSignatureModal,
      openSignatureModal,
      setOpenSignatureModal,
      getOpenEditModal,
      openUserEditModal,
      setOpenUserEditModal,
      clickShowContentModal,
      setClickShowContentModal,
      isLoading,
      phoneErrorMessage,
      updatePerfilError,
      updatePerfilSuccess,
      classCodeValidation,
      setIsLoading,
      setPhoneErrorMessage,
      setUpdatePerfilError,
      setUpdatePerfilSuccess,
      setClassCodeValidation,
      onSubmitUserEdit,
      setPhone,
      phone,
      isOpenFromHeader,
      setIsOpenFromHeader
      }}>
      {children}
    </EditUserModalContext.Provider>
  )
}

  const useEditUserModal = () => {
    return React.useContext(EditUserModalContext)
  }

  export { useEditUserModal, EditUserModalProvider, EditUserModalContext }
