import React from 'react'
import { Container } from './styles'

const UserManual: React.FC<{ url: string, title: string }> = ({ url, title }) => {
	return (
		<Container>
			<iframe src={url} title={title} />
		</Container>
	)
}

export default UserManual
