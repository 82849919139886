import { Input, Button } from '@cockpit/zera'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import Icons from '../../../assets/icons'
import {
  Container, ModalTitle, InputBox, InputForm,
  ResendCodeButton, InvalidEmail, ConfirmationTitle, Text, ContainerConfirmed
} from './ForgotPasswordModalBody.styles'
import { ErrorMessage } from '../../ErrorMessage'
import { forgot } from '../../../modules/password/password.service'
import { message } from '../../../messages'
import Countdown from './Countdown'
import { addDays, addSeconds } from 'date-fns/esm'

interface Inputs {
  email: string
}

interface IForgotPasswordModalBody {
  setRedirect: Function
}

export const ForgotPasswordModalBody: React.FC<IForgotPasswordModalBody> = ({ setRedirect }) => {
  const { register, handleSubmit } = useForm<Inputs>()
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false)
  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false)
  const [sessionError, setSessionError] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<string>('forgotPassword.messageError')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const [canResend, setCanResend] = useState<boolean>(true)
  const [deadline, setDeadline] = useState<Date>(addDays(new Date(), 1))

  const handleResendButton = (remainingTime: number) => {
    setDeadline(addSeconds(new Date(), remainingTime))
    setCanResend(false)
  }

  useEffect(() => {
    if (canResend) {
      setSessionError(false)
    }
  }, [canResend])

  const onSubmit = async ({ email }: Inputs) => {
    const emailIsValid = /\S+@\S+\.\S+/
    setSessionError(false)

    if (!emailIsValid.test(email)) {
      setEmailIsInvalid(true)
    } else {
      setEmailIsInvalid(false)
      setIsLoading(true)

      try {
        await forgot(email)
        setIsSuccess(true)
        setTimeout(() => {
          setRedirect(<Redirect to="/" />)
        }, 3000)
      } catch (e: any) {
        setSessionError(true)
        setIsLoading(false)
        if (e?.data?.remainingTime) {
          return (handleResendButton(e?.data?.remainingTime), setIsDisabledButton(true), setMessageError('forgotPassword.resend'))
        }
      }
    }
  }

  const handleEnableButton = (isEnable: boolean) => {
    if (isEnable && !isDisabledButton) {
      setIsDisabledButton(true)
    } else if (!isEnable && isDisabledButton) {
      setEmailIsInvalid(false)
      setIsDisabledButton(false)
    }

    if (sessionError) {
      setIsDisabledButton(false)
      setSessionError(false)
    }
  }

  return (
    !isSuccess ? (
      <Container isError={sessionError}>
        <ModalTitle>{message('forgotPassword.modalTitle')}</ModalTitle>
        <ErrorMessage
          show={sessionError}
          showByProperty='display'
          margin='0 0 8px'
          maxWidth='300px'
          onClose={() => setSessionError(false)}
        >

          {message(messageError)}

        </ErrorMessage>
        <InputForm autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <InputBox isError={sessionError} invalidEmail={emailIsInvalid} >
            <Input
              {...register('email', { required: true })}
              placeholder={message('forgotPassword.emailInputPlaceholder')}
              autoComplete='off'
              type="text"
              onChange={({ target }) => handleEnableButton(!!target.value.length)}
            >
            </Input>
            <InvalidEmail>{message('forgotPassword.invalidEmail')}</InvalidEmail>
          </InputBox>
          <Button isLoading={isLoading} disabled={!isDisabledButton || sessionError}>{message('forgotPassword.buttonSendEmail')}</Button>
          <ResendCodeButton disabled={!canResend} onClick={() => handleResendButton(60)}>
            {!canResend && <>
              {message('forgotPassword.resendIn')} <Countdown setCanResend={setCanResend} deadline={deadline} />
            </>}
          </ResendCodeButton>
        </InputForm>
      </Container>
    ) : (
      <ContainerConfirmed>
        <Icons.Confirmed size={65} />
        <ConfirmationTitle>{message('forgotPassword.ConfirmedTitle')}</ConfirmationTitle>
        <Text>
          {message('forgotPassword.confirmedTextBody')}
        </Text>
      </ContainerConfirmed>
    )
  )
}
