import React, { ReactElement } from 'react'
import { SideBarPerfil } from '../../components/sideBarPerfil/SideBarPerfil'
import { Container } from './styles'
import { UserUpdatePasswordContainer } from '../../components/UserUpdatePasswordContainer/UserUpdatePasswordContainer'

export const Security: React.FC = (): ReactElement => {
  return (
    <Container>
      <SideBarPerfil/>
      <UserUpdatePasswordContainer/>
    </Container>
  )
}
