import { AxiosRequestConfig, AxiosResponse } from 'axios'
import ENV from '../env'
import api, { errorHandling } from '../http/api'

const AUTH_BASE_ENDPOINT = '/user/forgot-password'

const post = async (email: string): Promise<any> => {
	const passwordData = {
		email
	}

	const requestConfig: AxiosRequestConfig = {
		baseURL: ENV.API_USER_AUTH
	}

	return api
		.post<AxiosResponse<any>>(AUTH_BASE_ENDPOINT, passwordData, requestConfig)
		.then((response: any) => {
			if (response.data?.error) {
				throw new Error('Erro inesperado')
			}

			if (response.data?.status && response.data?.status === 400) {
				throw new Error('E-mail não encontrado')
			}

			return response.data
		})
		.catch(errorHandling)
}

export const passwordApi = {
	post
}
