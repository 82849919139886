import { IGetSessionErrorKey, IDynamicErrorMessage, IUserHasMoreThanOneAccess, IWithoutTheRouteParameter } from './interfaces'
import { getPlatformRoute, getOfficeRoute } from '../../models/platformRoute'
import writeOfficeLocalStorage from '../../shared/functions/writeOfficeInLocalStorage'
import { ACCESS_MANAGER_PLATFORM_TAG } from '../../enums'
import { Platform } from '../../modules/login/Platform'
import LocalStorageManager from '../../helpers/localStorage'
import { OfficeLocalStorage } from '../../modules/login/Session'

export const getSessionErrorKey: IGetSessionErrorKey = (sessionErrors) => {
  if (sessionErrors?.error === 'unauthorizedProfile') {
    return 'unauthorizedProfile'
  }

  if (sessionErrors?.error?.includes('locked')) {
    return 'userIsBlocked'
  }

  return 'incorrectCredentials'
}

export const dynamicErrorMessage: IDynamicErrorMessage = (property, error) => {
  if (error && error === 'incorrectPassword') {
    const singularOrPlural = property === 1 ? 'tentativa' : 'tentativas'
    return `Usuário e/ou senha incorretos, você possui mais ${property} ${singularOrPlural}`
  }
}

export const userHasMoreThanOneAccess: IUserHasMoreThanOneAccess = (sessionData) => {
  const uniquePlatformById: Platform[] = []
  sessionData?.user?.allPlatforms?.filter((platform) =>
    !platform.access_only_to_offices && platform.tag === ACCESS_MANAGER_PLATFORM_TAG).map((platform) => {
      if (!uniquePlatformById.find(p => p.id === platform.id)) {
        uniquePlatformById.push(platform)
      }
    })
  const platformsLength = uniquePlatformById.length ?? 0

  const officeLength: any[] = []

  sessionData?.user?.allPlatforms?.forEach((platform) => {
    if (platform?.offices.length) {
      officeLength.push(...platform.offices)
    }
  })

  const defaultPlatformId = sessionData?.user?.access?.find(a => a.default_platform_id)?.default_platform_id
  const defaultOfficeId = sessionData?.user?.access?.find(a => a.default_office_id)?.default_office_id
  const successDirectorySuggestion = !!sessionData?.user?.successDirectorySuggestion
  const isDirectorySuggestion = !!sessionData?.user?.active_directory_suggestion

  return {
    defaultPlatformId,
    defaultOfficeId,
    successDirectorySuggestion,
    isDirectorySuggestion,
    moreThanOnePlatformOrOffice: (
      (platformsLength > 1 || officeLength.length > 1) || (platformsLength === 1 && officeLength.length === 1)
    )
  }
}

export const withoutTheRouteParameter: IWithoutTheRouteParameter = (sessionData) => {
  const defaultPlatform = sessionData?.user?.access?.find(a => a.default_platform_id)?.default_platform_id
  const defaultOffice = sessionData?.user?.access?.find(a => a.default_office_id)?.default_office_id
  const randomPlatform = sessionData?.user?.allPlatforms?.find((platform) =>
    !platform.access_only_to_offices
  )?.id
  const officeExist = LocalStorageManager.ReadEncryptedData('office-encrypted') as OfficeLocalStorage

  const randomOffice = sessionData?.user?.allPlatforms?.find((platform) =>
    platform.offices.length > 0
  )

  if (officeExist) {
    return getOfficeRoute
  }

  if (defaultPlatform) {
    return getPlatformRoute(defaultPlatform)
  }

  if (defaultOffice) {
    sessionData?.user?.allPlatforms?.forEach((getDefaultOffice) => {
      const getOffice = getDefaultOffice?.offices.find(office => office.id === defaultOffice)

      if (getOffice) {
        writeOfficeLocalStorage({
          id: getOffice.id,
          name: getOffice.name,
          platformId: getDefaultOffice.id,
          internal: getOffice.internal,
          address: getOffice?.address
        })
      }
    })

    return getOfficeRoute
  }

  if (randomOffice) {
    const getRandomOffice = randomOffice.offices.find((office) => office.id)
    if (getRandomOffice) {
      writeOfficeLocalStorage({
        id: getRandomOffice.id,
        name: getRandomOffice.name,
        platformId: randomOffice.id,
        internal: getRandomOffice.internal,
        address: getRandomOffice?.address
      })
    }

    return getOfficeRoute
  }

  if (randomPlatform) return getPlatformRoute(randomPlatform)
}
