import React from 'react'

import { Button } from '@cockpit/zera'

import Icons from '../../../assets/icons'
import { message } from '../../../messages'
import { ResponsiveModal } from '../../../shared/ResponsiveModal'
import useResize from '../../_layouts/useResize'

import { Container, Title, Body, Paragraph, Footer } from './styles'

const { Danger: IconDanger } = Icons

interface Props {
  showModal: boolean
  onClose: () => void,
  emailMasked?: string
}

export const BlockedAccessModal: React.FC<Props> = ({
  showModal,
  onClose,
  emailMasked
}) => {
  const windowSize = useResize()

  const isMobile = windowSize < 500

  return (
    <ResponsiveModal
      show={showModal}
      closeOnClickMask={false}
      onClose={onClose}
      padding='0'
      width='526px'
      backgroundColor={isMobile ? '#F8F9FC' : '#FFF'}
    >
      <Container>
        <IconDanger height={isMobile ? 'calc(3.5rem + 1px)' : '3.5rem'} />
        <Title>{message('blockedAccess.title')}</Title>
        <Body>
          {!isMobile ? (
            <>
              <Paragraph>
                Devido ao excesso de tentativas, seu usuário<br />foi bloqueado.
              </Paragraph>
              <Paragraph>
                Siga as intruções enviadas p/ o e-mail <span>{emailMasked}</span><br />ou entre em contato com o Suporte Técnico.
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph>
                Devido ao excesso de tentativas,<br />seu usuário foi bloqueado.
              </Paragraph>
              <Paragraph>
                Siga as intruções enviadas p/ o e-mail<br /><span>{emailMasked}</span> ou entre em contato com o Suporte Técnico.
              </Paragraph>
            </>
          )}
        </Body>
        <Footer>
          <Button type="button" onClick={onClose}>
            {message('blockedAccess.button-close')}
          </Button>
        </Footer>
      </Container>
    </ResponsiveModal>
  )
}
