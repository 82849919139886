
const Confirmed = ({ size }: { size: number}) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 69 69">
    <g id="confirmado" transform="translate(2 -43.417)">
      <g id="Info_Circle" data-name="Info Circle" transform="translate(0 45.417)">
        <path id="Stroke_1" data-name="Stroke 1" d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z" transform="translate(0 0)" fill="none" stroke="#378ff7" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Stroke_3" data-name="Stroke 3" d="M0,8.725l8.728,8.725L26.178,0" transform="translate(20.12 24.676)" fill="none" stroke="#378ff7" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
      </g>
    </g>
  </svg>
)

export default Confirmed
