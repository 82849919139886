import React, { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react'

import { differenceInSeconds } from 'date-fns'

interface ITimerProps {
  deadline: Date
  setCanResend: Dispatch<SetStateAction<boolean>>
}

const Countdown: React.FC<ITimerProps> = ({ deadline, setCanResend }: ITimerProps) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const dayInSeconds = 60 * 60 * 24
  const hourInSeconds = 60 * 60
  const minuteInSeconds = 60

  const diffInSeconds = differenceInSeconds(deadline, currentTime)

  const getCoundown = () => {
    if (diffInSeconds <= 0) {
      setCanResend(true)
      return {
        minutes: 0,
        seconds: 0
      }
    }

    setCanResend(false)

    const days = Math.floor(diffInSeconds / dayInSeconds)
    const hours = Math.floor((diffInSeconds - days * dayInSeconds) / hourInSeconds)
    const minutes = Math.floor((diffInSeconds - days * dayInSeconds - hours * hourInSeconds) / minuteInSeconds)
    const seconds = diffInSeconds - days * dayInSeconds - hours * hourInSeconds - minutes * minuteInSeconds
    return {
      minutes,
      seconds
    }
  }

  const countdown = useMemo(getCoundown, [dayInSeconds, hourInSeconds, minuteInSeconds, setCanResend, diffInSeconds])

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime()
      setCurrentTime(now)
    }, 1000)
  }, [])

  return <>00:{String(countdown.seconds).length === 1 ? `0${countdown.seconds}` : countdown.seconds}</>
}

export default Countdown
