import LocalStorageManager from '../../helpers/localStorage'
import { VerificaAuthData } from './AuthenticateResponse'
import { authenticationApi } from './authentication.api'

export const authenticate = async (
	username: string,
	password: string
): Promise<VerificaAuthData> => {
	return authenticationApi
		.post(username, password)
		.then(async (authenticationResponse: VerificaAuthData) => {
			let expirationDate = new Date()
            expirationDate = new Date(expirationDate.getTime() + 1000 * Number(authenticationResponse.expires_in))

			LocalStorageManager.WriteEncryptedData<string>('verifica_token-encrypted', authenticationResponse.access_token)
			LocalStorageManager.WriteEncryptedData<string>('verifica_token_expires_in-encrypted', authenticationResponse.expires_in)
			LocalStorageManager.WriteEncryptedData<number>('verifica_token_expiration_date-encrypted', expirationDate.getTime())
			LocalStorageManager.WriteEncryptedData<string>('verifica_token_type-encrypted', 'BirdID')

			return Promise.resolve(authenticationResponse)
		})
		.catch(async (e) => {
			return Promise.reject(e)
		})
}

export const authenticateBirdID = async (
	username: string,
	password: string,
	lifetime: string,
	token?: string
): Promise<VerificaAuthData> => {
	return authenticationApi
		.postBirdID(username, password, lifetime, token)
		.then(async (authenticationResponse: VerificaAuthData) => {
			let expirationDate = new Date()
            expirationDate = new Date(expirationDate.getTime() + 1000 * Number(authenticationResponse.expires_in))

			LocalStorageManager.WriteEncryptedData<string>('verifica_token-encrypted', authenticationResponse.access_token)
			LocalStorageManager.WriteEncryptedData<string>('verifica_token_expires_in-encrypted', authenticationResponse.expires_in)
			LocalStorageManager.WriteEncryptedData<number>('verifica_token_expiration_date-encrypted', expirationDate.getTime())
			LocalStorageManager.WriteEncryptedData<string>('verifica_token_type-encrypted', 'BirdID')

			return Promise.resolve(authenticationResponse)
		})
		.catch(async (e) => {
			return Promise.reject(e)
		})
}

/* export const unauthenticate = (): Promise<VerificaAuthData> => {
	return authenticationApi
		.get()
		.then((authenticationResponse: VerificaAuthData) => {
			return Promise.resolve(authenticationResponse)
		})
		.catch((e) => Promise.reject(e))
} */

export const authenticationService = {
	authenticate,
	authenticateBirdID
}
