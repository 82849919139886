import LocalStorageManager from '../helpers/localStorage'
import ENV from '../modules/env'
import { SessionData } from '../modules/login/Session'
import { NewURL } from '../utils'

const platformRoute = [
  { id: ENV.TELEMEDICINE_PLATFORM_ID, route: '/telemedicina' },
  { id: ENV.CLINICS_PLATFORM_ID, route: '/clinica-einstein/agenda' },
  { id: ENV.CLINICAL_STAFF_PLATFORM_ID, route: '/corpo-clinico' },
  { id: ENV.CLINICAL_STAFF_PLATFORM_ID, route: '/oncologia' },
  { id: ENV.HMVSC_PLATFORM_ID, route: '/hmvsc' },
  { id: ENV.ONCO_FRAMEWORK_PLATFORM_ID, route: '/formulario' }
]

const defaultRoute = {
  id: ENV.TELEMEDICINE_PLATFORM_ID,
  route: '/telemedicina'
}

interface LoginSSO {
  patientEpisodeNumber?: string
  target?: string | null
}

interface OncologyLoginSSO {
  patientEpisodeNumber: string
  resident?: string | null
}

type GetPlatformRoute = (id: number | null | string, loginSSO?: LoginSSO) => string

type GetOncologyPlatformRoute = (id: number | null | string, loginSSO?: OncologyLoginSSO) => string

const session = LocalStorageManager.ReadEncryptedData('session-encrypted') as SessionData | undefined

/**
 * @returns True if user has access to default route (Telemedicina)
 */
export const userHasAccessToDefaultRoute = () => {
  let hasAccess = false

  if (session?.user) {
    hasAccess = !!session?.user?.allPlatforms.find(platform => platform.id === defaultRoute.id)
  }

  return hasAccess
}

export const getPlatformRoute: GetPlatformRoute = (id, loginSSO) => {
  const filter = platformRoute.filter(element => element.id === id)

  const route = filter[0]?.route || defaultRoute.route

  const isDefaultRoute = route === defaultRoute.route
  if (isDefaultRoute && !userHasAccessToDefaultRoute() && session) {
    const userPlatforms = platformRoute.filter(platform => session?.user?.allPlatforms.find(({ id }) => id === platform.id))
    return userPlatforms[0].route
  }

  if (loginSSO && id === ENV.CLINICAL_STAFF_PLATFORM_ID) {
    if (loginSSO.target) {
      const path = new NewURL(`${route}/inicio`)

      if (loginSSO.patientEpisodeNumber) {
        if (loginSSO.target === 'prescricao') {
          path.setQueryParams('medicalRecord', loginSSO.patientEpisodeNumber).setQueryParams('target', loginSSO.target)
        } else {
          path.setQueryParams('episodeNumber', loginSSO.patientEpisodeNumber).setQueryParams('target', loginSSO.target)
        }
      }

      return path.toString()
    }

    const defaultPathLoginSSO = new NewURL(`${route}/inicio`)

    if (loginSSO.patientEpisodeNumber) {
      defaultPathLoginSSO.setQueryParams('episodeNumber', loginSSO.patientEpisodeNumber)
    }

    return defaultPathLoginSSO.toString()
  }

  return route
}

export const getOncologyPlatformRoute: GetOncologyPlatformRoute = (id, loginSSO) => {
  const platform = platformRoute.find(platform => platform.id === id)
  const route = platform?.route
  const path = new NewURL(`${route}`)

  if (loginSSO) {
    path.setQueryParams('episodeNumber', loginSSO?.patientEpisodeNumber)

    if (loginSSO.resident !== undefined && loginSSO.resident !== null) {
      path.setQueryParams('resident', loginSSO?.resident)
    }
  }

  return path.toString()
}

export const getPlatformIdByRoute = (route: string) => {
  const id = platformRoute.filter(element => element.route.includes(route) || route.includes(element.route))[0]?.id
  if (!id) return
  return Number(id) || defaultRoute.id
}

export const getOfficeRoute = '/consultorio'
