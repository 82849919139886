import styled from 'styled-components'

export const UserMenuContainer = styled.div`
  max-width: 250px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const UserImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  overflow: hidden;
  & img {
    height: 100%;
    cursor: pointer;
  }
  div {
    cursor: pointer;
    margin: 0;
  }
`
export const UserData = styled.div`
  width: calc(100% - 52px);
  margin-right: 12px;
  text-align: right;

  div {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 576px) {
    display: none;
  }
`

export const UserName = styled.span`
  text-align: right;
  font: normal normal medium 14px/17px Inter;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 4px;

  & #user-name {
    color: #000;
  }
`
export const ClassCode = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray4};
`

export const UserRetractableContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: ${({ isOpen }) => (isOpen ? '350px' : '0')};
  right: -40px;
  top: -20px;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 27px 41px #0000001f;
  transition: width 0.5s;
  z-index: 12;
  overflow-x: hidden;
`
