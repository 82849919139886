import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconExporter } from '@cockpit/zera'
import { useLocation, Redirect } from 'react-router-dom'

import { selectUserSession } from '../../../modules/login/sessionSlice'
import { getCurrentPlatform } from '../../../modules/session/sessionSlice'
import { SessionData } from '../../../modules/login/Session'
import { getPlatformRoute, getPlatformIdByRoute, getOfficeRoute } from '../../../models/platformRoute'
import { getPlatformsAndOffices } from './functions'
import {
  Container,
  LogoContainer,
  TitlePlatform,
  SelectPlatformOrOffice,
  ContainerSelectPlatformsOrOffices,
  ArrowPlat,
  SelectPlatMobile,
  OptionsMobile,
  TitleMobile,
  ButtonCloseMobile,
  ContainerOptions,
  PreferencesOption,
  ContainerPreferencesMobile,
  Mask
} from './styles'
import {
  deleteLocalStorage
} from '../../../modules/local-storage/local-storage.service'
import Icons from '../../../assets/icons'
import { Separator, Title } from '../../../styles/shared'
import { useEditUserModal } from '../../EditUserModal/context'
import { message } from '../../../messages'
import { IPlatformOrOffice } from './interfaces'
import LocalStorageManager from '../../../helpers/localStorage'
import ENV from '../../../modules/env'

const { LogoEinstein, LogoCockpit } = Icons

const SelectPlatform: React.FC = () => {
  const [show, setShow] = useState(false)
  const [redirect, setRedirect] = useState<any>(null)
  const userSession: SessionData | null = useSelector(selectUserSession)
  const currentPlatformOrOffice = useSelector(getCurrentPlatform)
  const { getOpenEditModal, setIsOpenFromHeader } = useEditUserModal()
  const location = useLocation() as { pathname: string }

  const [platformAndOfficeList] = getPlatformsAndOffices(userSession)

  const userHasOnlyOnePlatformOrOffice = platformAndOfficeList?.length !== 1

  useEffect(() => {
    const handleWindowClick = () => setShow(false)
    if (show) {
      document.addEventListener('click', handleWindowClick)

      document.querySelector('body')?.setAttribute('style', 'overflow:hidden')
    } else if (document.getElementsByClassName('modal-active').length === 0) {
      document.querySelector('body')?.removeAttribute('style')
    }
    return () => document.removeEventListener('click', handleWindowClick)
  }, [show, setShow])

  const handlePlatformOrOffice = (selectedPlatformOrOffice: IPlatformOrOffice) => {
    setShow(false)

    const sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
    // const platformPop = sessionData.user.access.pop()

    // platformPop && sessionData.user.access.unshift(platformPop)

    deleteLocalStorage('session-encrypted')
    LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionData)

    if (selectedPlatformOrOffice.isOffice) {
      LocalStorageManager.WriteEncryptedData<IPlatformOrOffice>('office-encrypted', selectedPlatformOrOffice)

      setRedirect(<Redirect push to={{ pathname: String(getOfficeRoute) }} />)
    } else {
      deleteLocalStorage('office-encrypted')

      const platformId = Number(selectedPlatformOrOffice.id)
      const userPlatformId = getPlatformIdByRoute(location.pathname)

      if (platformId !== userPlatformId) {
        const route = getPlatformRoute(platformId)

        setRedirect(<Redirect push to={{ pathname: String(route) }} />)
      }
    }
  }

  return (
    <Container>
      {redirect}
      <LogoContainer
        onClick={() => {
          if (userHasOnlyOnePlatformOrOffice) {
            setShow(!show)
          }
        }}
        show={show}
        onlyHaveOnePlatformOrOffice={userHasOnlyOnePlatformOrOffice}
      >
        { ENV.TENANT
          ? <img
              alt="logo SaaS"
              src={`${ENV.TENANT_ICON_URL}`}
              width={29}
              height={29}
            />
          : !currentPlatformOrOffice.internal
          ? <LogoCockpit />
          : <LogoEinstein/>
        }
        <TitlePlatform data-testid="title-platform-header">{currentPlatformOrOffice?.name}</TitlePlatform>
        {userHasOnlyOnePlatformOrOffice && (
          <ArrowPlat show={show}>
            <IconExporter name="arrow_right" iconsize={10} />
          </ArrowPlat>
        )}
      </LogoContainer>
      <ContainerSelectPlatformsOrOffices show={userHasOnlyOnePlatformOrOffice && show}>
        {platformAndOfficeList?.map(item => (
          <ContainerOptions key={item.name}>
            <SelectPlatformOrOffice
              active={item.id === currentPlatformOrOffice?.id && item.isOffice === currentPlatformOrOffice?.isOffice}
              onClick={() => handlePlatformOrOffice(item)}
            >
              <span>
                <IconExporter name="check" iconsize={7} />
              </span>
              {item.name}
            </SelectPlatformOrOffice>
          </ContainerOptions>
        ))}
        <PreferencesOption
          onClick={() => {
            getOpenEditModal(true)
            setShow(!show)
            setIsOpenFromHeader(true)
          }}
        >
          <Separator />
          <Title marginTop="15px" fontSize="15px">
            {message('selectPlatformOrOffice.preferencesOption')}
          </Title>
        </PreferencesOption>
      </ContainerSelectPlatformsOrOffices>
      <SelectPlatMobile className={`modal-${show ? 'active' : 'inactive'}`} show={show}>
        <ButtonCloseMobile type="button" onClick={() => setShow(false)} />
        <TitleMobile>{message('selectPlatformOrOffice.titleMobile')}</TitleMobile>
        <OptionsMobile>
          {platformAndOfficeList?.map(item => (
            <ContainerOptions key={item.name}>
              <SelectPlatformOrOffice
                active={item.id === currentPlatformOrOffice?.id && item.isOffice === currentPlatformOrOffice?.isOffice}
                onClick={() => handlePlatformOrOffice(item)}
              >
                <span>
                  <IconExporter name="check" iconsize={7} />
                </span>
                {item.name}
              </SelectPlatformOrOffice>
            </ContainerOptions>
          ))}
        </OptionsMobile>
        <ContainerPreferencesMobile>
          <PreferencesOption
            onClick={() => {
              getOpenEditModal(true)
              setIsOpenFromHeader(true)
            }}
          >
            <Separator />
            <Title marginTop="15px" fontSize="15px">
              {message('selectPlatformOrOffice.preferencesOption')}
            </Title>
          </PreferencesOption>
        </ContainerPreferencesMobile>
      </SelectPlatMobile>
      <Mask show={userHasOnlyOnePlatformOrOffice && show} />
    </Container>
  )
}

export default SelectPlatform
