import LocalStorageManager from '../../helpers/localStorage'
import { FrontPlatform } from '../../models/FrontPlatform.enum'
import { SessionData } from '../login/Session'

/**
 * Verifica se usuário tem acesso a plataforma do HMVSC
 */

export default function guardHMVSC(): void | boolean {
  const sessionData = LocalStorageManager.ReadEncryptedData<SessionData>('session-encrypted') as SessionData
  if (sessionData) {
    // TODO: [0] temporario, a interface final de acessos nao esta pronta
    const hasAccess = sessionData?.user?.allPlatforms?.find(
      (p) => p.id === FrontPlatform.HMVSC
    )

    if (hasAccess) {
      return true
    }
  }

  window.location.href = '/'
}
