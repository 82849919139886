import React, { useEffect, useState } from 'react'

import birdId from '../../../../assets/icons/svg/bird-id.svg'

import {
  ButtonBack,
  ButtonValidated,
  CheckboxCert,
  Container,
  ContentCert,
  Error,
  LabelSelect,
  LogoCert,
  SelectArea,
  Validating,
  Verification,
  VerificationContainer
} from '../styles'
import { Checkbox, IconExporter, Select } from '@cockpit/zera'
import VerificationInput from 'react-verification-input'
import { PlatformsCertification } from '../../../../modules/login/AuthenticateResponse'
import { useDispatch, useSelector } from 'react-redux'
import {
  authenticateBirdID,
  selectAuthenticationLoading,
  selectUserSession
} from '../../../../modules/login/sessionSlice'
import { SessionData } from '../../../../modules/login/Session'

interface Props {
  platform?: PlatformsCertification
  onBack: () => void
  onClose: () => void
  setLoadingRequest: (value: boolean) => void
  isMobile: boolean
  setIsStandart: (id: number | undefined, defaultValue: boolean) => void
}

const BirdId: React.FC<Props> = ({ platform, setIsStandart, onBack, onClose, isMobile, setLoadingRequest }) => {
  const [selected, setSelected] = useState('')
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [authCode, setAuthCode] = useState<string>('')

  const dispatch = useDispatch()
  const sessionData: SessionData | null = useSelector(selectUserSession)
  const isAuthenticationLoading = useSelector(selectAuthenticationLoading)

  const handleSubmit = async () => {
    setLoading(true)
    setIsError(false)

    await dispatch(
      authenticateBirdID(
        sessionData?.user.personal_document.identifier ?? '',
        authCode,
        selected,
        sessionData ?? undefined
      )
    )
  }

  const handleBack = () => {
    if (loading) {
      setLoading(false)
      setIsError(false)
      return
    }

    onBack()
  }

  const pressEnter = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter' && authCode.length === 6) {
      handleSubmit()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', pressEnter)
    return () => {
      window.removeEventListener('keydown', pressEnter)
    }
  }, [authCode, pressEnter])

  useEffect(() => {
    if (isAuthenticationLoading === 'failing') {
      setLoading(false)
      setIsError(true)
      return
    }

    if (isAuthenticationLoading === 'success') {
      onClose()
    }
  }, [isAuthenticationLoading])

  useEffect(() => {
    setLoadingRequest(loading)
  }, [loading])

  useEffect(() => {
    if (platform && !selected) {
      setSelected(platform.lifetime[0].time)
    }
  }, [platform, selected])

  return (
    <Container>
      {!isMobile && !loading && (
        <ButtonBack type="button" onClick={handleBack}>
          <IconExporter name="arrow_left" iconsize={12} />
          Alterar certificadora
        </ButtonBack>
      )}
      <ContentCert>
        <LogoCert>
          <img src={platform?.logo_url ?? birdId} alt="birdId" width={89} height={51} />
        </LogoCert>
        <LabelSelect>Expirar certificado em</LabelSelect>
        <SelectArea>
          <Select
            name="period"
            value={selected}
            onChange={e => setSelected(e.target.value.toString())}
            options={
              platform
                ? platform.lifetime.map(item => ({
                    value: item.time,
                    label: item.label
                  }))
                : []
            }
          />
        </SelectArea>

        <VerificationContainer>
          <LabelSelect>Token BirdID</LabelSelect>
          <Verification isError={isError}>
            <VerificationInput
              length={6}
              validChars="0-9"
              autoFocus
              onChange={value => {
                setIsError(false)
                setAuthCode(value)
              }}
              placeholder=""
              classNames={{
                container: 'container',
                character: 'character',
                characterInactive: 'character--inactive',
                characterSelected: 'character--selected'
              }}
              inputProps={{
                inputMode: 'numeric',
                'data-testid': 'otp-code-input',
                autoComplete: 'one-time-code'
              }}
            />
          </Verification>
          {isError && <Error>Código inválido</Error>}
          {loading && <Validating>Validando seu acesso. Por favor, aguarde</Validating>}
        </VerificationContainer>
        <ButtonValidated
          isLoading={loading}
          disabled={authCode.length < 6 || !selected}
          width="100%"
          onClick={handleSubmit}
        >
          Validar
        </ButtonValidated>
        <CheckboxCert>
          <Checkbox
            name="isStandart"
            label="Usar como certificadora padrão"
            onClick={() => setIsStandart(platform?.id, !platform?.default)}
            checked={platform?.default}
          />
        </CheckboxCert>
      </ContentCert>
    </Container>
  )
}

export default BirdId
