import { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { IUseSessionErrors, ResetState, TargetType, UseContextSSO } from './interfaces'
import { logoutThunk, loginSSO } from '../../modules/login/sessionSlice'
import LocalStorageManager from '../../helpers/localStorage'

export const useSession: IUseSessionErrors = (loadingLogin, sessionErrors) => {
  const dispatch = useDispatch()
  const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false)
  const [blockedAccess, setBlockedAccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isServerError, setIsServerError] = useState(false)

  const resetState = (state: ResetState[]): void => {
    if (state.includes('blockedAccess')) setBlockedAccess(false)
    if (state.includes('incorrectPassword')) setIncorrectPassword(false)
    if (state.includes('isError')) setIsError(false)
    if (state.includes('isLoading')) setIsLoading(false)
    if (state.includes('isLoading')) setIsLoading(false)
    if (state.includes('isServerError')) setIsServerError(false)
    if (state.includes('all')) dispatch(logoutThunk())
  }

  useEffect(() => {
    if (loadingLogin === 'pending') {
      setIsLoading(true)
    }

    if (
      loadingLogin === 'failing' &&
      sessionErrors?.error === 'incorrectPassword' &&
      sessionErrors.info?.missing_attempts === 1
    ) {
      setIncorrectPassword(true)
    }

    if (loadingLogin === 'failing' && sessionErrors?.error === 'userIsBlocked') {
      setBlockedAccess(true)
    }

    if (loadingLogin === 'success' || loadingLogin === 'failing') {
      setIsLoading(false)
    }

    if (sessionErrors?.error === 'serverError') {
      setIsServerError(true)
    }

    if (
      sessionErrors &&
      loadingLogin === 'failing' &&
      sessionErrors.error === 'incorrectPassword' &&
      sessionErrors.info?.missing_attempts
    ) {
      setIsError(true)
    } else if (sessionErrors?.error !== 'serverError' && loadingLogin === 'failing') {
      setIsError(true)
    }
  }, [loadingLogin, sessionErrors])

  return {
    incorrectPassword,
    blockedAccess,
    isLoading,
    isError,
    isServerError,
    resetState
  }
}

export const useContextSSO: UseContextSSO = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { search } = useLocation()

  const queryParams = useMemo(() => new URLSearchParams(search), [search])
  const patientEpisodeNumber = queryParams.get('episodeNumber')
  const target = queryParams.get('target') as TargetType
  const resident = queryParams.get('resident')

  useEffect(() => {
    const signatureParams = queryParams.get('signature')
    const externalUsernameParams = queryParams.get('user')
    const episodeNumber = queryParams.get('episodeNumber')

    if (signatureParams && externalUsernameParams) {
      const signature = signatureParams.split(' ').join('+')
      const externalUsername = externalUsernameParams

      dispatch(loginSSO(signature, externalUsername))
      LocalStorageManager.WriteEncryptedData<boolean>('is_context_sso-encrypted', true)
      LocalStorageManager.WriteEncryptedData<string | null>('episode_number-encrypted', episodeNumber)
      LocalStorageManager.WriteEncryptedData<TargetType>('target-encrypted', target)
      setLoading(true)
    }
  }, [queryParams, target])

  return [loading, { patientEpisodeNumber, target, resident }]
}
