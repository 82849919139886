import styled from 'styled-components'

export const DisplayContainerImage = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
`

export const ContainerImage = styled.div`
  background: #F3F5F9;
  display: flex;
  justify-content: center;
  width: 70% ;
  height: 180px;

  & img {
    width: 100%;
  }

  @media(max-width: 421px){
    width: 90%;
  }
`
export const MobileBoxIconArrow = styled.div`
  display: none;

  @media(max-width: 421px){
    display: block;
    width: 100%;
    border-bottom: 1px solid #e9ecf3;
  }
`
export const DisplayMobileBoxIconArrow = styled.div`
 @media(max-width: 421px){
    display: flex;
    align-items:center;
    height: 50px;
    & svg {
      & path{
      stroke: currentColor; 
    }
  }  
}  
`
export const ContainerButtonLeft = styled.div`
  width: 100% ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5% 0;
  padding: 18px;
  border-bottom: 2px solid #e9ecf3;

  & svg {
    &path{
      fill: #dfe3e8; 
    }
  }

  @media(max-width: 421px){
    border:none;
    margin: 2% 0;

  & svg {
    & path {
      display: none ;
    }
  }
}
  `
export const BoxLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ButtonLeft = styled.button`
  display: block;
  text-align: center;
  width: 30px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #dfe3e8;
  color: #dfe3e8;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    & path {
      stroke: #000;
    }
  }

  &:hover{
    background-color: #dfe3e8;

    & svg {
      & path {
      display: block;
      stroke: #378ff7;
    }
  }
}

@media(max-width: 421px){
  display: none ;

  & svg {
    & path {
      display: none ;
    }
    }
  }
`
