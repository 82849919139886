import React from 'react'

const View = (props: React.SVGProps<SVGSVGElement>) => (
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Grupo_161713" data-name="Grupo 161713" transform="translate(0.063)">
    <g id="Grupo_9978" data-name="Grupo 9978">
      <rect id="Retângulo_3763" data-name="Retângulo 3763" width="40" height="40" transform="translate(-0.063)" fill="none"/>
    </g>
    <path id="Caminho_25997" data-name="Caminho 25997" d="M411.949,291.349a.626.626,0,0,0-.88-.093l-3.835,3.105-.993.806-2.5,2.023a3.181,3.181,0,0,0-1.874-.656,3.063,3.063,0,0,0-3.063,3.063,3.876,3.876,0,0,0,.318,1.334L396.4,303.14c-.238-.218-.465-.438-.676-.661a15.623,15.623,0,0,1-2.142-2.883h0c.681-1.2,3.667-5.922,8.285-5.922a7.205,7.205,0,0,1,3.53.966l1.026-.831a8.573,8.573,0,0,0-4.555-1.359c-6.028,0-9.39,6.591-9.531,6.871a.612.612,0,0,0,0,.548,16.121,16.121,0,0,0,2.422,3.388c.209.225.429.448.664.669L391.88,306.8a.626.626,0,0,0,.394,1.112.618.618,0,0,0,.394-.14l3.727-3.019.991-.8,2.509-2.031.97-.786,2.714-2.2a1.85,1.85,0,0,1,.13.662h0a1.865,1.865,0,0,1-1.71,1.824l-1.255,1.016a3.049,3.049,0,0,0,4.19-2.841,3.11,3.11,0,0,0-.051-.51,3.039,3.039,0,0,0-.329-.941l2.689-2.178c.24.215.469.433.683.654a15.579,15.579,0,0,1,2.23,2.975c-.681,1.2-3.667,5.92-8.285,5.92a7.256,7.256,0,0,1-3.651-1.033l-1.022.828a8.619,8.619,0,0,0,4.673,1.43c6.026,0,9.39-6.6,9.53-6.871a.612.612,0,0,0,0-.548,15.949,15.949,0,0,0-2.506-3.486c-.211-.223-.434-.445-.671-.664l3.636-2.945A.625.625,0,0,0,411.949,291.349Zm-11.831,8.778a1.751,1.751,0,0,1,.069-1.264A1.838,1.838,0,0,1,401.5,297.8a1.866,1.866,0,0,1,.367-.038v.006a1.825,1.825,0,0,1,.877.231Z" transform="translate(-381.459 -279.804)" fill="#a7abb2"/>
  </g>
</svg>
)

export default View
