import dotenv from 'dotenv'
dotenv.config()

const ENV = {
  LANDING_PAGE_HOST: process.env.REACT_APP_LANDING_PAGE_HOST,
  TELEMEDICINA_HOST: process.env.REACT_APP_TELEMEDICINA_HOST,
  CLINICS_HOST: process.env.REACT_APP_CLINICS_HOST,
  CLINICAL_STAFF_HOST: process.env.REACT_APP_CLINICAL_STAFF_HOST,
  HMVSC_HOST: process.env.REACT_APP_HMVSC_HOST,
  ONCO_HOST: process.env.REACT_APP_ONCO_HOST,
  FORM_FRAMEWORK_LOGIN_HOST: process.env.REACT_APP_FRAMEWORK_LOGIN_HOST,

  TELEMEDICINE_PLATFORM_ID: Number(process.env.REACT_APP_TELEMEDICINE_PLATFORM_ID),
  CLINICS_PLATFORM_ID: Number(process.env.REACT_APP_CLINICS_PLATFORM_ID),
  CLINICAL_STAFF_PLATFORM_ID: Number(process.env.REACT_APP_CLINICAL_STAFF_PLATFORM_ID),
  HMVSC_PLATFORM_ID: Number(process.env.REACT_APP_HMVSC_PLATFORM_ID),
  ONCO_FRAMEWORK_PLATFORM_ID: Number(process.env.REACT_APP_ONCO_FRAMEWORK_PLATFORM_ID),

  API_BASE_URL: process.env.REACT_API_BASE_URL,
  API_LOGIN: process.env.REACT_APP_API_LOGIN,
  API_USER_AUTH: process.env.REACT_APP_API_USER_AUTH,
  API_VERIFICA: process.env.REACT_APP_VERIFICA_API_URL,
  IMAGES_URL: process.env.REACT_APP_IMAGES_URL,
  SKIP_PREFLIGHT_CHECK: process.env.SKIP_PREFLIGHT_CHECK,
  APP_NAME: process.env.APP_NAME,
  API_USER_FORGOT: process.env.REACT_APP_API_USER_FORGOT,

  CLINICAL_STAFF_USER_MANUAL: process.env.REACT_APP_CLINICAL_STAFF_USER_MANUAL,
  INTEGRATED_CARE_USER_MANUAL: process.env.REACT_APP_INTEGRATED_CARE_USER_MANUAL,

  TENANT: process.env.REACT_APP_TENANT,
  TENANT_LOGO_URL: process.env.REACT_APP_TENANT_LOGO_URL,
  TENANT_ICON_URL: process.env.REACT_APP_TENANT_ICON_URL,
  CHAT_ENABLED_FOR_PLATFORMS: process.env.REACT_APP_CHAT_ENABLED_FOR_PLATFORMS?.split(';') ?? [],
  CHAT_FEATURE_ID: process.env.REACT_APP_CHAT_FEATURE_ID,
  REACT_APP_FINANCIAL_HOST: process.env.REACT_APP_FINANCIAL_HOST
}

export default ENV
