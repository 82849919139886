import React from 'react'
import { message } from '../../messages'
import { Container } from './styles'

interface ILinkCopiedToastProps {
  show: boolean
}
export const LinkCopiedToast = ({ show }: ILinkCopiedToastProps) => {
  return (
    <Container show={show}>
      {message('login.isCopied')}
    </Container>
  )
}
