import { AxiosRequestConfig, AxiosResponse } from 'axios'
import api, { errorHandling } from '../http/api'
import { PlatformsCertification, VerificaAuthData } from './AuthenticateResponse'
import { setCertification } from '../../modules/login/sessionSlice'
import ENV from '../env'
import { SessionData } from './Session'

const AUTH_VERIFICA_BASE_ENDPOINT = '/certificate/sessions'
const CERTIFICATE_BASE_ENDPOINT = '/certificate/catalog'
const DEFAULT_BASE_ENDPOINT = '/certificate/default'

interface Authentication {
	code: string
}

const post = async (
	username: string,
	password: string
): Promise<VerificaAuthData> => {
	const authenticationData = {
		username,
		password
	}

	const requestConfig: AxiosRequestConfig = {
		baseURL: ENV.API_VERIFICA
	}

	return api
		.post<Authentication, AxiosResponse<VerificaAuthData>>(
			AUTH_VERIFICA_BASE_ENDPOINT,
			authenticationData,
			requestConfig
		)
		.then((response: any) => {
			if (response.data?.error) {
				throw new Error('Erro inesperado')
			}

			if (response.data?.status && response.data?.status === 400) {
				throw new Error('Código incorreto')
			}

			setCertification(response.data.access_token)
			return response.data
		})
		.catch(errorHandling)
}

const postBirdID = async (
	username: string,
	password: string,
	lifetime: string,
	token?: string
): Promise<VerificaAuthData> => {
	const authenticationData = {
		username,
		password
	}

	const requestConfig: AxiosRequestConfig = {
		baseURL: ENV.API_VERIFICA,
		headers: {
			Authorization: `Bearer ${token}`
		}
	}

	return api
		.post<Authentication, AxiosResponse<VerificaAuthData>>(
			AUTH_VERIFICA_BASE_ENDPOINT.concat(`?provider=soluti&lifetime=${lifetime}`),
			authenticationData,
			requestConfig
		)
		.then((response: any) => {
			if (response.data?.error) {
				throw new Error('Erro inesperado')
			}

			if (response.data?.status && response.data?.status === 400) {
				throw new Error('Código incorreto')
			}

			setCertification(response.data.access_token)
			return response.data
		})
		.catch(errorHandling)
}

const getPlataformCertification = async (token: string): Promise<PlatformsCertification[]> => {
	const requestConfig: AxiosRequestConfig = {
		baseURL: ENV.API_VERIFICA,
		headers: {
			Authorization: `Bearer ${token}`
		}
	}

	try {
		const response = await api
			.get<PlatformsCertification[]>(
				CERTIFICATE_BASE_ENDPOINT,
				requestConfig
			)

		return response.data
	} catch (err) {
		return []
	}
}

const defaultCertificate = async (id: number, sessionData: SessionData): Promise<{ success: boolean }> => {
	const requestConfig: AxiosRequestConfig = {
		baseURL: ENV.API_VERIFICA,
		headers: {
			Authorization: `Bearer ${sessionData.token}`
		}
	}

	const classCode = sessionData.user.health_professional_data.class_codes[0]

	try {
		await api
			.put(
				DEFAULT_BASE_ENDPOINT.concat(id ? `?certificate_id=${id}` : ''),
				{
					health_professional: {
							name: sessionData.user.name,
							sex: sessionData.user.gender,
							class_code: {
									classification: classCode.description,
									code: classCode.code,
									origin: classCode.origin
							}
					}
			},
				requestConfig
			)

		return { success: true }
	} catch (err) {
		return { success: false }
	}
}

export const authenticationApi = {
	post,
	postBirdID,
	getPlataformCertification,
	defaultCertificate
}
