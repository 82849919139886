import { theme } from '@cockpit/zera'
import styled from 'styled-components'

interface ISeparatorProps {
    marginTop?: string
    displayNoneMobile?: string
}

interface IPropsTitle {
    marginTop?: string
    marginBottom?: string
    marginRight?: string
    marginLeft?: string
    textTransform?: string
    fontWeight?: string
    fontSize?: string
    color?: string
    displayNoneMobile?: string
}

export const Separator = styled.div<ISeparatorProps>`
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop ?? ''};
  background-color: #dfe3e8;
  z-index: 5;
  position: relative;
  height: 2px;
  transform: translateY(-2px);
  @media(max-width: 768px){
    display: ${({ displayNoneMobile }) => displayNoneMobile ?? 'block'};
}
`
export const Title = styled.p<IPropsTitle>`
margin-bottom: ${({ marginBottom }) => marginBottom ?? ''};
margin-top: ${({ marginTop }) => marginTop ?? ''};
margin-right: ${({ marginRight }) => marginRight ?? ''};
margin-left: ${({ marginLeft }) => marginLeft ?? ''};
font-size: ${({ fontSize }) => fontSize ?? '16px'};
color: ${({ color }) => color ?? theme.colors.black};
font-weight: ${({ fontWeight }) => fontWeight ?? theme.fontWeight.regular};
text-transform: ${({ textTransform }) => textTransform ?? 'none'};

@media(max-width: 768px){
    display: ${({ displayNoneMobile }) => displayNoneMobile ?? 'block'}; ;
}
`
