import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {
    font-family: Inter, sans-serif;
  }
`

export const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
`

export const LabelInput = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  margin-bottom: 30px;
  strong {
    font-weight: 500;
  }
`

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  @media (min-width: 415px) {
    flex-direction: row;
  }
  .btn-later {
    padding: 0;
    border: 0px solid;
    &:hover {
      color: rgb(55, 143, 247);
      background-color: transparent;
    } 
  }
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: -12.5%;
    margin: 0 -100% 1% 0;

    span {
      text-align: left;
    }

    > span {
      > span {
        margin-top: -20%;

        @media (max-width: 414px) {
          margin-top: 78%;
          right: 7%;
        }
        @media (max-width: 320px) {
          margin-top: 78%;
          right: 7%;
        }
      }
    }
    
    @media (max-width: 414px) {
      left: -14.5%;
    }
    @media (max-width: 320px) {
      left: -16.5%;
    }
    div {
      width: 40px;
      height: 100%;
      svg {
        margin-top: 8px;
        margin-right: -4px;
        transition: all 0.5s;
        
        path {
          stroke: #909297;

        }
      }
    }
  }
  > svg {
    position: relative;
    left: -13%;
    width: 42px;
    margin: 0 -100% 1% 0;
    transition: all 0.5s;
    @media (min-width: 1025px) {
      :hover {
        cursor: pointer;
      }
    }
    @media (max-width: 414px) {
      left: -15%;
    }
    @media (max-width: 320px) {
      left: -17%;
    }
  }
  input {
    height: 40px;
    padding: 0 16px;
  }
`

export const PasswordViewer = styled.div`
  position: relative;
  left: -13%;
  width: 42px;
  margin: 0 -100% 1% 0;
  transition: all 0.5s;
  @media(min-width: 1025px){
    :hover{
      cursor: pointer;
    }
  }
  @media (max-width: 414px) {
    left: -15%;
  }
  @media (max-width: 320px) {
    left: -17%;
  }
`

export const ErrorMsg = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe9e9;
  color: #ff1a1e;
  padding: 6px;
  border-radius: 3px;
  margin-top: 12px;
  width: 100%;
  height: 44px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 321px) {
    width: 255px;
  }
`
