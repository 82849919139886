import { FrontPlatform } from '../../../models/FrontPlatform.enum'
import { SessionData } from '../../../modules/login/Session'
import { User } from '../../../modules/login/User'
import { IForm } from '../components/UserEditModal/UserEditModal'

export const formattedEditUser = (sessionData: SessionData | null, data: IForm, phone: string) => {
  data.phone = phone.replace(/[^0-9]/g, '')
  const healthProfessionalData = sessionData?.user.health_professional_data
  if (healthProfessionalData?.class_codes) {
    const healthInsurancesIds: number[] = []
    sessionData?.user?.access?.map(access => access?.health_insurance_data?.map(item => healthInsurancesIds.push(item.portal_empresas_id)))

    return {
      name: data.name.trim(),
      email: data.email,
      phone: data.phone
    }
  }
  return {
    name: data.name.trim(),
    email: data.email,
    phone: data.phone
  }
}

export const formattedDefaultPlatformId = (sessionData: SessionData | null, defaultPlatformId: string | number): number | FrontPlatform | null | undefined => {
  const findPlataformId =
    defaultPlatformId === 'Cockpit Telemedicina'
      ? FrontPlatform.TELEMEDICINE : defaultPlatformId === 'Cockpit Clínica Einstein'
        ? FrontPlatform.CLINICAL_STAFF : defaultPlatformId === 'Cockpit Corpo Clínico'
          ? FrontPlatform.CLINICS : defaultPlatformId === 'Cockpit HMVSC'
            ? FrontPlatform.HMVSC : sessionData?.user?.access?.find(a => a.default_platform_id)?.default_platform_id

  return findPlataformId
}

export const formattedBySecurityModal = (sessionData: SessionData | null, oldPassword: string | undefined, password: string | undefined, platformsOrOffice: { defaultPlatformId?: number, defaultOfficeId?: number }) => {
  const platformOrOffice = platformsOrOffice?.defaultOfficeId ?? platformsOrOffice?.defaultPlatformId

  if (!platformOrOffice && password && oldPassword) {
    return {
      name: sessionData?.user.name,
      username: sessionData?.user.username,
      email: sessionData?.user.email,
      phone: sessionData?.user.phone,
      gender: sessionData?.user.gender,
      date_birth: sessionData?.user.date_birth,
      client_id: sessionData?.user.client_id,
      signature: sessionData?.user.signature,
      old_password: oldPassword,
      password: password
    }
  }

  if (platformOrOffice && password && oldPassword) {
    const healthProfessionalData = sessionData?.user.health_professional_data

    const specialties = healthProfessionalData?.specialties[0] ? {
      specialties: healthProfessionalData?.specialties.map((specialty) => ({ id: specialty.specialty_id }))
    } : undefined

    const healthInsurancesIds: number[] = []
    sessionData?.user?.access?.forEach(access => access?.health_insurance_data?.map(item => healthInsurancesIds.push(item.portal_empresas_id)))
    const healthInsurances = healthInsurancesIds.length > 0 ? {
      health_insurances: healthInsurancesIds
    } : undefined

    return {
      name: sessionData?.user.name,
      username: sessionData?.user.username,
      email: sessionData?.user.email,
      phone: sessionData?.user.phone,
      gender: sessionData?.user.gender,
      date_birth: sessionData?.user.date_birth,
      client_id: sessionData?.user.client_id,
      signature: sessionData?.user.signature,
      old_password: oldPassword,
      password: password,
      access: sessionData?.user?.access?.map(access => ({
        ...healthInsurances,
        profile_id: access.profile.id,
        platforms: access.platforms.map(platform => {
          if (platform.offices.length) {
            return {
              platform_id: platform.id,
              user_has_platform_and_modules: {
                access_only_to_offices: platform.access_only_to_offices
              },
              offices: platform.offices.map(offices => ({
                id: offices.id
              }))
            }
          }

          return {
            platform_id: platform.id,
            user_has_platform_and_modules: {
              access_only_to_offices: platform.access_only_to_offices
            }
          }
        }),
        default_platform_id: access.platforms.some(p => p.id === platformsOrOffice?.defaultPlatformId) ? platformsOrOffice?.defaultPlatformId : undefined,
        default_office_id: access?.platforms?.some(p => p.offices.some(o => o.id === platformsOrOffice?.defaultOfficeId)) ? platformsOrOffice?.defaultOfficeId : undefined
      })),
      health_professional_data: {
        ...specialties,
        class_codes: [
          {
            type_id: sessionData?.user.health_professional_data.class_codes[0].classification_id,
            origin: sessionData?.user.health_professional_data.class_codes[0].origin,
            code: sessionData?.user.health_professional_data.class_codes[0].code
          }
        ]
      }
    }
  }

  if (!password && !oldPassword && platformOrOffice) {
    return {
      name: sessionData?.user.name,
      username: sessionData?.user.username,
      email: sessionData?.user.email,
      phone: sessionData?.user.phone,
      gender: sessionData?.user.gender,
      date_birth: sessionData?.user.date_birth,
      client_id: sessionData?.user.client_id,
      signature: sessionData?.user.signature,
      access: sessionData?.user?.access?.map(access => ({
        profile_id: access.profile.id,
        platforms: access.platforms.map(platform => {
          if (platform.offices.length) {
            return {
              platform_id: platform.id,
              user_has_platform_and_modules: {
                access_only_to_offices: platform.access_only_to_offices
              },
              offices: platform.offices.map(offices => ({
                id: offices.id
              }))
            }
          }
          return {
            platform_id: platform.id,
            user_has_platform_and_modules: {
              access_only_to_offices: platform.access_only_to_offices
            }
          }
        }),
        default_platform_id: access.platforms.some(p => p.id === platformsOrOffice?.defaultPlatformId) ? platformsOrOffice?.defaultPlatformId : undefined,
        default_office_id: access.platforms.some(p => p.offices.some(o => o.id === platformsOrOffice?.defaultOfficeId)) ? platformsOrOffice?.defaultOfficeId : undefined
      })),
      health_professional_data: sessionData?.user?.health_professional_data?.class_codes && sessionData?.user?.health_professional_data?.class_codes?.length > 0 ? {
        class_codes: [
          {
            type_id: sessionData?.user.health_professional_data.class_codes[0].classification_id,
            origin: sessionData?.user.health_professional_data.class_codes[0].origin,
            code: sessionData?.user.health_professional_data.class_codes[0].code
          }
        ]
      } : undefined
    }
  }
}

export const getUserProfileName = (user: User | null | undefined): string => {
  try {
    if (!user || user === null) {
      return 'Erro ao carregar perfil'
    }
    if (user?.health_professional_data?.class_codes && user?.health_professional_data?.class_codes?.length > 0) {
      return 'Profissional de Saúde'
    } else if (user?.access.some(a => a?.profile?.description?.match(/gestor/gi))) {
      return 'Gestor'
    } else if (user?.access.some(a => a?.profile?.description?.match(/secretária/gi))) {
      return 'Secretaria'
    } else {
      return user?.access[0]?.profile?.description ?? 'Profissional de Saúde'
    }
  } catch (error) {
    if (user?.access && user?.access?.length > 0) {
      return user.access[0].profile?.description
    } else {
      return 'Erro ao carregar perfil'
    }
  }
}
