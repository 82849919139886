import React, { useEffect, useState } from 'react'

import vidaas from '../../../../assets/icons/svg/vidaas.svg'

import {
  ButtonBack,
  ButtonValidated,
  CheckboxCert,
  Container,
  ContentCert,
  Error,
  LabelSelect,
  LoadingText,
  LogoCert,
  SelectArea
} from '../styles'
import { Checkbox, IconExporter, Select } from '@cockpit/zera'
import { PlatformsCertification } from '../../../../modules/login/AuthenticateResponse'
import {
  authenticationData,
  selectUserSession,
  setAuthenticationLoading,
  setCertification
} from '../../../../modules/login/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SessionData } from '../../../../modules/login/Session'

import EventSourcePolyfill from 'eventsource'
import ENV from '../../../../modules/env'
import LocalStorageManager from '../../../../helpers/localStorage'

interface Props {
  platform?: PlatformsCertification
  onBack: () => void
  onClose: () => void
  setLoadingRequest: (value: boolean) => void
  isMobile: boolean
  setIsStandart: (id: number | undefined, defaultValue: boolean) => void
}

interface Cert {
  access_token: string
  expires_in: string
  provider: 'vidaas'
}

const Vidaas: React.FC<Props> = ({ platform, onBack, onClose, isMobile, setIsStandart, setLoadingRequest }) => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const sessionData: SessionData | null = useSelector(selectUserSession)

  const handleSubmit = (trys: number, code?: string) => {
    setLoading(true)

    const url = new URL(`${ENV.API_VERIFICA}/certificate/sessions`)

    url.searchParams.append('provider', 'vidaas')
    url.searchParams.append('lifetime', selected)
    url.searchParams.append('username', sessionData?.user.personal_document.identifier ?? '')

    if (code) {
      url.searchParams.append('code', code)
    }

    const events = new EventSourcePolyfill(url.toString(), {
      headers: {
        Authorization: `Bearer ${sessionData?.token}`
      }
    })

    events.addEventListener('connected', () => {
      setLoading(true)
      setErrorMessage('')
    })

    events.addEventListener('authorized', event => {
      try {
        const parsedData = JSON.parse(event.data) as Cert

        dispatch(setCertification(parsedData.access_token))

        let expirationDate = new Date()
        expirationDate = new Date(expirationDate.getTime() + 1000 * Number(parsedData.expires_in))

        LocalStorageManager.WriteEncryptedData<string>('verifica_token-encrypted', parsedData.access_token)
        LocalStorageManager.WriteEncryptedData<string>('verifica_token_expires_in-encrypted', parsedData.expires_in)
        LocalStorageManager.WriteEncryptedData<number>(
          'verifica_token_expiration_date-encrypted',
          expirationDate.getTime()
        )
        LocalStorageManager.WriteEncryptedData<string>('verifica_token_type-encrypted', 'VIDaaS')

        dispatch(authenticationData({ access_token: parsedData.access_token }))

        LocalStorageManager.WriteEncryptedData<SessionData>('session-encrypted', sessionData)

        setLoading(false)
        setErrorMessage('')
        dispatch(setAuthenticationLoading('success'))

        events.close()
        onClose()
      } catch (err) {
        setLoading(false)
        setErrorMessage('Authentication failed')

        events.close()
      }
    })

    events.addEventListener('error', event => {
      try {
        const parsedData = JSON.parse(event.data)

        if (parsedData.error?.code && trys < 2) {
          events.close()
          handleSubmit(trys + 1, parsedData.error.code)
          return
        }

        if (typeof parsedData.error === 'string') {
          setErrorMessage(parsedData.error)
        } else if (parsedData.error?.message) {
          setErrorMessage(parsedData.error?.message)
        } else {
          setErrorMessage('Authentication failed')
        }

        setLoading(false)
        events.close()
      } catch (err) {
        setLoading(false)
        events.close()
      }
    })
  }

  const handleBack = () => {
    if (loading) {
      setLoading(false)
      setErrorMessage('')
      return
    }

    setLoading(false)
    setErrorMessage('')
    onBack()
  }

  const pressEnter = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      handleSubmit(1)
    }
  }

  const getErrors = (str: string) => {
    switch (str) {
      case 'Health Professional did not respond within the timeout window':
        return 'Profissional de saúde não respondeu dentro do tempo limite.'
      case 'Health professional does not have access to vidaas':
        return 'Profissional de saúde não tem acesso ao certificado VIDaaS.'
      default:
        return 'Falha na autenticação'
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', pressEnter)
    return () => {
      window.removeEventListener('keydown', pressEnter)
    }
  }, [selected])

  useEffect(() => {
    setLoadingRequest(loading)
  }, [loading])

  useEffect(() => {
    if (platform && !selected) {
      setSelected(platform.lifetime[0].time)
    }
  }, [platform, selected])

  return (
    <Container>
      {!isMobile && !loading && (
        <ButtonBack type="button" onClick={handleBack}>
          <IconExporter name="arrow_left" iconsize={12} />
          Alterar certificadora
        </ButtonBack>
      )}
      <ContentCert>
        <LogoCert mb>
          <img src={platform?.logo_url ?? vidaas} alt="vidaas" width={105} height={22} />
        </LogoCert>
        {!loading ? (
          <>
            {errorMessage && <Error>{getErrors(errorMessage)}</Error>}
            <LabelSelect>Expirar certificado em</LabelSelect>
            <SelectArea>
              <Select
                name="period"
                value={selected}
                onChange={e => setSelected(e.target.value.toString())}
                options={
                  platform
                    ? platform.lifetime.map(item => ({
                        value: item.time,
                        label: item.label
                      }))
                    : []
                }
              />
            </SelectArea>
            <ButtonValidated width="100%" onClick={() => handleSubmit(1)} disabled={loading || !selected}>
              Validar
            </ButtonValidated>
            <CheckboxCert>
              <Checkbox
                name="isStandart"
                label="Usar como certificadora padrão"
                onClick={() => setIsStandart(platform?.id, !platform?.default)}
                checked={platform?.default}
              />
            </CheckboxCert>
          </>
        ) : (
          <>
            <LoadingText>Acesse o aplicativo VIDaaS em seu celular e efetue a autenticação.</LoadingText>
            <IconExporter name="load" iconsize={80} />
          </>
        )}
      </ContentCert>
    </Container>
  )
}

export default Vidaas
