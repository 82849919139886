import React, { ReactElement } from 'react'
import { LoginSection, Mask, Container } from './ForgotPassword.styles'
import { ForgotPasswordModal } from '../../components/ForgotPassword/ForgotPasswordModal'
import Footer from '../../components/Footer'

export const ForgotPassword: React.FC = (): ReactElement => {
  return (
    <Container>
      <LoginSection>
        <ForgotPasswordModal />
        <Mask />
      </LoginSection>
      <Footer isFocused={false} />
    </ Container>
  )
}
