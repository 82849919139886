import { ACCESS_MANAGER_PLATFORM_TAG } from '../../enums'
import { SessionData, SessionError } from '../login/Session'

export const isAuthorizedUser = (user: SessionData): Boolean => {
  let authorizedPlatforms = false
  for (const access of user?.user?.access) {
    authorizedPlatforms = access.platforms.some(platform => platform.tag === ACCESS_MANAGER_PLATFORM_TAG)
    if (authorizedPlatforms) return authorizedPlatforms
  }
  return authorizedPlatforms
}

export const userHasAccessToPlatformsOrOffices = (user: SessionData): Boolean => {
  const platforms = user?.user?.allPlatforms
  const authorizedPlatforms = platforms.filter(platform => platform.tag === ACCESS_MANAGER_PLATFORM_TAG)
  // TODO:
  // Adicionar authorizedPlatforms.some(platform => !platform.access_only_to_offices) após deploy
  const userHasAccessToPlatforms = true
  const userHasAccessToOffices = authorizedPlatforms.some(platform => platform.offices.length > 0)

  return userHasAccessToPlatforms || userHasAccessToOffices
}

export const incorrectPasswordCounter = (sessionError: SessionError): Boolean => {
  return !!sessionError?.info?.missing_attempts
}

export const userIsBlocked = (sessionError: SessionError): Boolean => {
  return !!sessionError?.info?.email
}

export const serverError = (requestError: any): Boolean => {
  return (
    requestError?.status >= 500 ||
    requestError?.headers?.['content-type'] === 'text/html' || // Proxy errors (CloudFront, etc)
    requestError === 'Network Error'
  )
}
