import React, { useEffect, useMemo } from 'react'
import UserManual from '../UserManual'
import ENV from '../../modules/env'
import { selectUserSession } from '../../modules/login/sessionSlice'
import { useSelector } from 'react-redux'
import { SessionData } from '../../modules/login/Session'
import { useLocation } from 'react-router-dom'
import { logout } from '../../modules/login/login.service'

const FormFramework: React.FC = () => {
  const userSession: SessionData | null = useSelector(selectUserSession)
  const { search } = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const validTokenExpired = () => {
    if(userSession) {
      const today = new Date()
      const parts = userSession.refresh_token.split('.')
      const decodedPayload = JSON.parse(atob(parts[1]))
      const decodedFormattingDate = new Date(decodedPayload.exp * 1000)
      const isExpired = decodedFormattingDate < today

      if(isExpired) {
        logout()
        return window.location.replace('/')
      }

      return window.location.replace(rootUrl)
    }
  }

  const rootUrl: string = useMemo(() => {
    const episodeNumber = queryParams.get('episodeNumber')
    const resident = queryParams.get('resident')

    const formFrameworkLoginUrl = new URL(
      `${ENV.FORM_FRAMEWORK_LOGIN_HOST}?refreshToken=${userSession?.refresh_token}`
    )

    if (resident) {
      formFrameworkLoginUrl.searchParams.append(
        'resident', resident
      )
    }

    if (episodeNumber) {
      formFrameworkLoginUrl.searchParams.append(
        'episodeNumber', episodeNumber
      )
    }

    if (userSession?.active_directory_refresh_token) {
      formFrameworkLoginUrl.searchParams.append(
        'activeDirectoryRefreshToken', userSession.active_directory_refresh_token
      )
    }

    return formFrameworkLoginUrl.toString()
  }, [userSession])

  useEffect(() => {
    if(userSession) {
      validTokenExpired()
    }
  },[])

  return <UserManual title={''} url={rootUrl as string} />
}

export default FormFramework
