import styled, { css } from 'styled-components'

interface IButtonPlat {
  checked: boolean
}

export const Container = styled.div`
  padding-top: 40px;
  * {
    font-family: Inter, sans-serif;
  }
`

export const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  strong {
    font-weight: 500;
  }
`

export const CheckboxDefault = styled.div`
  margin-bottom: 20px;
  @media (min-width: 440px) {
    margin-bottom: 40px;
  }
  * {
    text-transform: initial;
    font-size: 10px;
    @media (min-width: 415px) {
      font-size: 14px;
    }
  }
`

export const ListPlatforms = styled.div`
  margin: 20px 0;
  max-height: 200px;
  overflow-y: scroll;
  transition: all 0.2s ease-in-out;
  padding-right: 5px;
  @media (min-width: 415px) {
    padding-right: 30px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    transition: all .2s linear;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.5);
  }
`

export const SelectPlatformOrOffice = styled.button<IButtonPlat>`
  display: block;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 5px;
  padding: 15px;
  background: transparent;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #555555;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  > div {
    margin-right: 15px;
    background-color: rgba(0, 0, 0, 0.18);
    padding: 6px;
    border-radius: 100px;
    transition: all 0.2s linear;
    svg, svg * {
      fill: #ffffff;
    }
  }
  &:hover {
    border: 1px solid #257CE5;
    box-shadow: inset 0 0 0 1px #257CE5;
  }
  ${({ checked }) => checked && css`
    border: 1px solid #257CE5;
    box-shadow: inset 0 0 0 1px #257CE5;
    background: rgba(37, 124, 229, 0.15);
    > div {
      background-color: #257CE5;
    }
  `}
`

export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  * {
    font-weight: 200;
  }
  button {
    @media (max-width: 415px) {
      width: 100%;
    }
  }
`
